import axios from "axios";
//import { newRedirect } from "./redirect";
import { showWarning } from "./notification";
import CONFIG from "../config";

const { baseUrl } = CONFIG;

const ALL_SUBTYPES_RECEIVED = "ALL_SUBTYPES_RECEIVED";
const ALL_GROUPS_RECEIVED = "ALL_GROUPS_RECEIVED";
const ALL_DEVICETYPES_RECEIVED = "ALL_DEVICETYPES_RECEIVED";
const LIST_VIEW_DATA_RECEIVED = "LIST_VIEW_DATA_RECEIVED";
const LIST_DEVICE_INFO_DATA_RECEIVED = "LIST_DEVICE_INFO_DATA_RECEIVED";
const LIST_DEVICE_EVENT_TYPE_DATA_RECEIVED =
  "LIST_DEVICE_EVENT_TYPE_DATA_RECEIVED";
const LIST_SETTINGS_CONNECTION_DATA_RECEIVED =
  "LIST_SETTINGS_CONNECTION_DATA_RECEIVED";
const LIST_TEMPLATE_DATA_RECEIVED = "LIST_TEMPLATE_DATA_RECEIVED";
const LIST_CONFIRM_TEMPLATE_DATA_RECEIVED =
  "LIST_CONFIRM_TEMPLATE_DATA_RECEIVED";
const LIST_CONFIRM_WIFI_SET_DATA_RECEIVED =
  "LIST_CONFIRM_WIFI_SET_DATA_RECEIVED";
const LIST_SETTINGS_SELFTEST_DATA_RECEIVED =
  "LIST_SETTINGS_SELFTEST_DATA_RECEIVED";
const LIST_SETTINGS_REBOOT_DATA_RECEIVED = "LIST_SETTINGS_REBOOT_DATA_RECEIVED";
const LIST_DEVICE_EVENT_DATA_RECEIVED = "LIST_DEVICE_EVENT_DATA_RECEIVED";
const LIST_MOD_VIEW_DATA_RECEIVED = "LIST_MOD_VIEW_DATA_RECEIVED";
const LIST_BLUETOOTH_VIEW_DATA_RECEIVED = "LIST_BLUETOOTH_VIEW_DATA_RECEIVED";
const CLEAR_LIST_VIEW_DATA = "CLEAR_LIST_VIEW_DATA";
const LIST_DEVICE_DATA_RECEIVED = "LIST_DEVICE_DATA_RECEIVED";
const CLEAR_LIST_DEVICE_DATA = "CLEAR_LIST_DEVICE_DATA";
const LIST_CONFIG_VIEW_DATA_RECEIVED = "LIST_CONFIG_VIEW_DATA_RECEIVED";
const CLEAR_LIST_CONFIG_DATA = "CLEAR_LIST_CONFIG_DATA";
const CONFIRM_WIFI_OPTION = "CONFIRM_WIFI_OPTION";
const LIST_FAIL_WIFI_SET_DATA_RECEIVED = "LIST_FAIL_WIFI_SET_DATA_RECEIVED";
const LIST_FAIL_TEMPLATE_SET_DATA_RECEIVED =
  "LIST_FAIL_TEMPLATE_SET_DATA_RECEIVED";
const LIST_FAIL_CONNECTION_SET_DATA_RECEIVED =
  "LIST_FAIL_CONNECTION_SET_DATA_RECEIVED";
const LIST_FAIL_SELFTEST_SET_DATA_RECEIVED =
  "LIST_FAIL_SELFTEST_SET_DATA_RECEIVED";
const CONFIRM_CELLULAR_VALUE = "CONFIRM_CELLULAR_VALUE";
const CLEAR_SETTINGS_LIST_DEVICE_DATA = "CLEAR_SETTINGS_LIST_DEVICE_DATA";
const CLEAR_DELETE_CONFIG_VALUE = "CLEAR_DELETE_CONFIG_VALUE";
const CLEAR_CREATE_CONFIG_VALUE = "CLEAR_CREATE_CONFIG_VALUE";
const CLEAR_UPDATE_CONFIG_VALUE = "CLEAR_UPDATE_CONFIG_VALUE";
const LIST_FAIL_REBOOT_SET_DATA_RECEIVED = "LIST_FAIL_REBOOT_SET_DATA_RECEIVED";
const LIST_DELETE_CONFIG_BY_ID = "LIST_DELETE_CONFIG_BY_ID";
const LIST_CREATE_CONFIG_BY_ID = "LIST_CREATE_CONFIG_BY_ID";
const LIST_UPDATE_CONFIG_BY_ID = "LIST_UPDATE_CONFIG_BY_ID";
const PROJECT_DATA_ALL = "PROJECT_DATA_ALL";
const PROJECT_DATA_IS_CONNECTED_ALL = "PROJECT_DATA_IS_CONNECTED_ALL";
const PROJECT_DATA_IS_DISCONNECTED_ALL = "PROJECT_DATA_IS_DISCONNECTED_ALL";
const PROJECT_NAME_CHANGED = "PROJECT_NAME_CHANGED";
const PROJECT_NAME_DELETE_NOT_POSSIBLE = "PROJECT_NAME_DELETE_NOT_POSSIBLE";
const PROJECT_NAME_DELETE_POSSIBLE = "PROJECT_NAME_DELETE_POSSIBLE";
const PROJECT_NAME_CREATED = "PROJECT_NAME_CREATED";
const PROJECT_DATA_DETAILS_ALL = "PROJECT_DATA_DETAILS_ALL";
const CLEAR_PROJECT_DELETE_DEVICE_DATA = "CLEAR_PROJECT_DELETE_DEVICE_DATA";
const CLEAR_PROJECT_DELETED = "CLEAR_PROJECT_DELETED";
const PROJECT_DATA_WITHOUT_CURRENT_PROJECT =
  "PROJECT_DATA_WITHOUT_CURRENT_PROJECT";
const GATEWAY_DATA_WITH_CURRENT_PROJECT = "GATEWAY_DATA_WITH_CURRENT_PROJECT";
const MOVED_GATEWAY_TO_PROJECT = "MOVED_GATEWAY_TO_PROJECT";
const FAIL_MOVING_GATEWAY_TO_PROJECT = "FAIL_MOVING_GATEWAY_TO_PROJECT";
const CLEAR_GATEWAY_TO_MOVED_PROJECT = "CLEAR_GATEWAY_TO_MOVED_PROJECT";
const CLEAR_CURRENT_GATEWAY_TARGET_PROJECT =
  "CLEAR_CURRENT_GATEWAY_TARGET_PROJECT";

const PROJECT_MEMBERS_DETAILS_VIEW = "PROJECT_MEMBERS_DETAILS_VIEW";
const ADD_MEMBER_CREATED = "ADD_MEMBER_CREATED";
const CLEAR_ADD_MEMBER_DATA = "CLEAR_ADD_MEMBER_DATA";
const ADD_MEMBER_FAILED = "ADD_MEMBER_FAILED";
const DELETE_PROJECT_MEMBER = "DELETE_PROJECT_MEMBER";
const CLEAR_MEMBER_VIEW_DATA = "CLEAR_MEMBER_VIEW_DATA";
const CHANGE_ACCESS_TYPE_MEMBER = "CHANGE_ACCESS_TYPE_MEMBER";

const getAllProjectSuccess = (data) => ({
  type: PROJECT_DATA_ALL,
  data,
});

const getProjectWithoutCurrentProjectSuccess = (data) => ({
  type: PROJECT_DATA_WITHOUT_CURRENT_PROJECT,
  data,
});

const getGatewaysWithCurrentProjectSuccess = (data) => ({
  type: GATEWAY_DATA_WITH_CURRENT_PROJECT,
  data,
});

const getMovingGatewatToProjectSccuess = (data) => ({
  type: MOVED_GATEWAY_TO_PROJECT,
  data,
});

const getMovingGatewayFail = (data) => ({
  type: FAIL_MOVING_GATEWAY_TO_PROJECT,
  data,
});

const getAllProjectDetailsSuccess = (data) => ({
  type: PROJECT_DATA_DETAILS_ALL,
  data,
});

const getAllProjectIsConnectedSuccess = (data) => ({
  type: PROJECT_DATA_IS_CONNECTED_ALL,
  data,
});

const getAllProjectIsDisconnectedSuccess = (data) => ({
  type: PROJECT_DATA_IS_DISCONNECTED_ALL,
  data,
});

const getDeleteProjectNameNotSuccess = (data) => ({
  type: PROJECT_NAME_DELETE_NOT_POSSIBLE,
  data,
});

const getDeleteProjectNameSuccess = (data) => ({
  type: PROJECT_NAME_DELETE_POSSIBLE,
  data,
});

const getMemberDetailsForProjectSuccess = (data) => ({
  type: PROJECT_MEMBERS_DETAILS_VIEW,
  data,
});

const getRenameProjectNameSuccess = (data) => ({
  type: PROJECT_NAME_CHANGED,
  data,
});

const getCreateProjectNameSuccess = (data) => ({
  type: PROJECT_NAME_CREATED,
  data,
});

const addMemberSuccess = (data) => ({
  type: ADD_MEMBER_CREATED,
  data,
});

const changeAccessTypeForMemberSuccess = (data) => ({
  type: CHANGE_ACCESS_TYPE_MEMBER,
  data,
});

const addMemberFailed = (data) => ({
  type: ADD_MEMBER_FAILED,
  data,
});

const getDeleteProjectMemberSuccess = (data) => ({
  type: DELETE_PROJECT_MEMBER,
  data,
});

const getClearMemberViewSuccess = (data) => ({
  type: CLEAR_MEMBER_VIEW_DATA,
  data,
});

const getAllSubtypesSuccess = (data) => ({
  type: ALL_SUBTYPES_RECEIVED,
  data,
});

const getAllGroupsSuccess = (data) => ({
  type: ALL_GROUPS_RECEIVED,
  data,
});

const getDeviceTypesSuccess = (data) => ({
  type: ALL_DEVICETYPES_RECEIVED,
  data,
});
const getListViewDataSuccess = (data) => ({
  type: LIST_VIEW_DATA_RECEIVED,
  data,
});

const getDeviceViewDataSuccess = (data) => ({
  type: LIST_DEVICE_INFO_DATA_RECEIVED,
  data,
});

const getDeviceEventTypeDataSuccess = (data) => ({
  type: LIST_DEVICE_EVENT_TYPE_DATA_RECEIVED,
  data,
});
const getDeviceEventViewDataSuccess = (data) => ({
  type: LIST_DEVICE_EVENT_DATA_RECEIVED,
  data,
});

const getSettingsConnectionsSuccess = (data) => ({
  type: LIST_SETTINGS_CONNECTION_DATA_RECEIVED,
  data,
});

const getTemplateListSuccess = (data) => ({
  type: LIST_TEMPLATE_DATA_RECEIVED,
  data,
});

const getConfirmTemplateSuccess = (data) => ({
  type: LIST_CONFIRM_TEMPLATE_DATA_RECEIVED,
  data,
});
const getWifiSettingsSuccess = (data) => ({
  type: LIST_CONFIRM_WIFI_SET_DATA_RECEIVED,
  data,
});

const getWifiSettingsFail = (data) => ({
  type: LIST_FAIL_WIFI_SET_DATA_RECEIVED,
  data,
});
const getTemplateSettingsFail = (data) => ({
  type: LIST_FAIL_TEMPLATE_SET_DATA_RECEIVED,
  data,
});

const getConnectionSettingsFail = (data) => ({
  type: LIST_FAIL_CONNECTION_SET_DATA_RECEIVED,
  data,
});

const getConfigDelete = (data) => ({
  type: LIST_DELETE_CONFIG_BY_ID,
  data,
});

const getCreateConfigDetail = (data) => ({
  type: LIST_CREATE_CONFIG_BY_ID,
  data,
});
const getUpdateConfigDetail = (data) => ({
  type: LIST_UPDATE_CONFIG_BY_ID,
  data,
});

const getSelfTestSettingsFail = (data) => ({
  type: LIST_FAIL_SELFTEST_SET_DATA_RECEIVED,
  data,
});

const getRebootSettingsFail = (data) => ({
  type: LIST_FAIL_REBOOT_SET_DATA_RECEIVED,
  data,
});

const getSettingsSelfTestsSuccess = (data) => ({
  type: LIST_SETTINGS_SELFTEST_DATA_RECEIVED,
  data,
});

const getConfirmCellularValueSuccess = (data) => ({
  type: CONFIRM_CELLULAR_VALUE,
  data,
});

const getSettingsRebootSuccess = (data) => ({
  type: LIST_SETTINGS_REBOOT_DATA_RECEIVED,
  data,
});

const getModListViewDataSuccess = (data) => ({
  type: LIST_MOD_VIEW_DATA_RECEIVED,
  data,
});

const getBluetoothListViewDataSuccess = (data) => ({
  type: LIST_BLUETOOTH_VIEW_DATA_RECEIVED,
  data,
});

const getDeviceDataSuccess = (data) => ({
  type: LIST_DEVICE_DATA_RECEIVED,
  data,
});

const clearDeviceDataSuccess = () => ({
  type: CLEAR_LIST_DEVICE_DATA,
});

const clearDeletePrjectNotPossibleSuccess = () => ({
  type: CLEAR_PROJECT_DELETE_DEVICE_DATA,
});

const clearGatewayMovedToProjectSuccess = () => ({
  type: CLEAR_GATEWAY_TO_MOVED_PROJECT,
});

const clearCurrentGatewayTargetProjectSuccess = () => ({
  type: CLEAR_CURRENT_GATEWAY_TARGET_PROJECT,
});

const clearDeletePrjectPossibleSuccess = () => ({
  type: CLEAR_PROJECT_DELETED,
});

const clearSettingDataSuccess = () => ({
  type: CLEAR_SETTINGS_LIST_DEVICE_DATA,
});

const clearMemberSuccesDataSuccess = () => ({
  type: CLEAR_ADD_MEMBER_DATA,
});

const clearDeleteConfigSuccess = () => ({
  type: CLEAR_DELETE_CONFIG_VALUE,
});

const clearCreateConfigSuccess = () => ({
  type: CLEAR_CREATE_CONFIG_VALUE,
});

const clearUpdateConfigSuccess = () => ({
  type: CLEAR_UPDATE_CONFIG_VALUE,
});

const getConnectWifiOption = () => ({
  type: CONFIRM_WIFI_OPTION,
});

const getConfigListViewDataSuccess = (data) => ({
  type: LIST_CONFIG_VIEW_DATA_RECEIVED,
  data,
});

const clearListViewDataSuccess = () => ({
  type: CLEAR_LIST_VIEW_DATA,
});
const clearConfigListViewDataSuccess = () => ({
  type: CLEAR_LIST_CONFIG_DATA,
});

export const getAllProject = () => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios
    .get(
      `${baseUrl}/group?filter[type][eq]=70000&filter[deleted_at]=NULL&sort=label&expand=countConnectedGateways,countDisconnectedGateways&per-page=50`
    )
    .then((res) => {
      dispatch(getAllProjectSuccess(res.data));
    })
    .catch((err) => {
      dispatch(
        showWarning({ body: err?.response?.data?.message || err.message })
      );
    });
};

export const getAllProjectWithoutCurrentProject =
  (projectId) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios
      .get(
        `${baseUrl}/group?filter[type][eq]=70000&filter[deleted_at]=NULL&filter[id_project][neq]=${projectId}`
      )
      .then((res) => {
        dispatch(getProjectWithoutCurrentProjectSuccess(res.data));
      })
      .catch((err) => {
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const getAllGatewaysForCurrentProject =
  (projectId) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios
      .get(
        `${baseUrl}/physical-device?type=gateway&filter[id_project]=${projectId}`
      )
      .then((res) => {
        dispatch(getGatewaysWithCurrentProjectSuccess(res.data));
      })
      .catch((err) => {
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const confirmMovingGatewatToProject =
  (currentGroupId, currentProjectId, targetGroupId, targetProjectId) =>
  (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios
      .delete(`${baseUrl}/group/${currentProjectId}/link/${currentGroupId}`)
      .then((res) => {
        if (res.status === 200) {
          axios({
            method: "POST",
            url: `${baseUrl}/group/${targetProjectId}/link/${currentGroupId}`,
            cache: "no-cache",
            headers: {
              // "Content-Type": "application/json",
              Authorization: `Bearer ${token.token}`,
            },
            //  referrerPolicy: "no-referrer",
            data: {},
          })
            .then((list) => {
              dispatch(getMovingGatewatToProjectSccuess(list));
            })
            .catch((err) => {
              dispatch(getMovingGatewayFail("failed"));
            });
        }
      })
      .catch((err) => {
        dispatch(getMovingGatewayFail("failed"));
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const clearGatewayMovedToProject = () => (dispatch) => {
  dispatch(clearGatewayMovedToProjectSuccess());
};

export const clearCurrentGatewayTargetProject = () => (dispatch) => {
  dispatch(clearCurrentGatewayTargetProjectSuccess());
};
export const getAllProjectIsConnectedDetails =
  (projectId) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(
        `${baseUrl}/physical-device?type=gateway&filter[is_connected]=0&filter[id_project]=${projectId}`
      )
      .then((res) => {
        dispatch(getAllProjectIsConnectedSuccess(res.data));
      })
      .catch((err) => {
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const getAllProjectIsDisConnectedDetails =
  (projectId) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios
      .get(
        `${baseUrl}/physical-device?type=gateway&filter[is_connected]=1&filter[id_project]=${projectId}`
      )
      .then((res) => {
        dispatch(getAllProjectIsDisconnectedSuccess(res.data));
      })
      .catch((err) => {
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const renameProjectName =
  (projectName, projectId) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios({
      method: "PUT",
      url: `${baseUrl}/group/${projectId}`,
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      referrerPolicy: "no-referrer",
      data: {
        label: projectName,
      },
    })
      .then((res) => {
        dispatch(getRenameProjectNameSuccess(res));
      })
      .catch((err) => {
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const createProjectName = (projectName) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios({
    method: "POST",
    url: `${baseUrl}/group`,
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.token}`,
    },
    referrerPolicy: "no-referrer",
    data: {
      label: projectName,
      type: 70000,
    },
  })
    .then((res) => {
      dispatch(getCreateProjectNameSuccess(res));
    })
    .catch((err) => {
      dispatch(
        showWarning({ body: err?.response?.data?.message || err.message })
      );
    });
};

export const getAllSubtypes = () => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios
    .get(`${baseUrl}/group/subtypes`)
    .then((res) => {
      dispatch(getAllSubtypesSuccess(res.data));
    })
    .catch((err) => {
      dispatch(showWarning(err.message));
    });
};

export const getAllGroups = () => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(`${baseUrl}/device-type`)
    .then((res) => {
      dispatch(getAllGroupsSuccess(res.data));
    })
    .catch((err) => {
      dispatch(showWarning(err.message));
    });
};

export const getAllDeviceType = () => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(`${baseUrl}/device-type`)
    .then((res) => {
      dispatch(getDeviceTypesSuccess(res.data));
    })
    .catch((err) => {
      dispatch(showWarning(err.message));
    });
};

export const getListViewData =
  (pageNr, sortColumn, sortDirection, filterParams) =>
  (dispatch, getState, props) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(
        `${baseUrl}/physical-device?type=gateway&expand=subDevices,iotDevice,location${
          sortColumn && sortDirection
            ? `&sort=${sortDirection === "DESC" ? "-" : ""}${sortColumn}`
            : ""
        }${pageNr ? `&page=${pageNr}` : ""}&${
          filterParams ?? ""
        }&sort=-last_data_at`
      )

      .then((res) => {
        dispatch(getListViewDataSuccess(res.data));
      })
      .catch((err) => {
        dispatch(showWarning(err.message));
      });
  };

export const getModbusListViewData =
  (subtype, pageNr, sortColumn, sortDirection) =>
  (dispatch, getState, props) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(
        `${baseUrl}/physical-device?type=asset&filter[id_device_type][like]=modbus${
          sortColumn && sortDirection
            ? `&sort=${sortDirection === "DESC" ? "-" : ""}${sortColumn}`
            : ""
        }${pageNr ? `&page=${pageNr}` : ""}`
      )
      .then((res) => {
        dispatch(getModListViewDataSuccess(res.data));
      })
      .catch((err) => {
        dispatch(showWarning(err.message));
      });
  };

export const getBluetoothListViewData =
  (subtype, pageNr, sortColumn, sortDirection) =>
  (dispatch, getState, props) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    // Change back to 20 per page
    axios
      .get(
        `${baseUrl}/group?${
          subtype === "ble-gateways"
            ? `expand=physicalDevices,country`
            : `expand=physicalDevices,country,latestData`
        }&per-page=20&filter[subtype]=${subtype}${
          sortColumn && sortDirection
            ? `&sort=${sortDirection === "DESC" ? "-" : ""}${sortColumn}`
            : ""
        }${pageNr ? `&page=${pageNr}` : ""}`
      )
      .then((res) => {
        dispatch(getBluetoothListViewDataSuccess(res.data));
      })
      .catch((err) => {
        dispatch(showWarning(err.message));
      });
  };
//getways config data
export const getConfigListViewData =
  (subtype, pageNr, sortColumn, sortDirection, filterParams) =>
  (dispatch, getState, props) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(
        `${baseUrl}/config-template/index?${
          sortColumn && sortDirection
            ? `&sort=${sortDirection === "DESC" ? "-" : ""}${sortColumn}`
            : ""
        }${pageNr ? `&page=${pageNr}` : ""}&sort=-updated_at&${
          filterParams ?? ""
        }`
      )
      .then((res) => {
        dispatch(getConfigListViewDataSuccess(res));
      })

      .catch((err) => {
        dispatch(showWarning(err.message));
      });
  };

export const clearListViewData = () => (dispatch) => {
  dispatch(clearListViewDataSuccess());
};

export const getDeviceData = (id) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios
    .get(
      `${baseUrl}/group/${id}?expand=physicalDevices,country,latestData,created_at`
    )
    .then((res) => {
      dispatch(getDeviceDataSuccess(res.data));
    })
    .catch((err) => {
      dispatch(showWarning(err.message));
    });
};

export const getDeviceInfoData = (id, projectId) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(
      `${baseUrl}/physical-device/${id}?expand=group.latestData,subDevices,subDevices.modbusDevice,currentConfig,deviceInfo,iotDevice&filter[id_project]=${projectId}`
    )
    .then((res) => {
      dispatch(getDeviceViewDataSuccess(res.data));
    })
    .catch((err) => {
      dispatch(showWarning(err.message));
    });
};

export const getEventTypeData =
  (query, pageNr, sortColumn, sortDirection, filterParams) =>
  (dispatch, getState, props) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(`${baseUrl}/event-type?filter[default_label][like]=${query}`)
      .then((res) => {
        dispatch(getDeviceEventTypeDataSuccess(res.data.items));
      })
      .catch((err) => {
        dispatch(showWarning(err.message));
      });
  };

export const getDeviceEventInfoData =
  (id, pageNr, filterParams) => (dispatch, getState, props) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(
        `${baseUrl}/event?filter[source_device]=${id}${
          pageNr ? `&page=${pageNr}` : ""
        }&${filterParams ?? ""}&sort=-occurred_at`
      )
      .then((list) => {
        dispatch(getDeviceEventViewDataSuccess(list));
      })
      .catch((err) => {
        dispatch(showWarning(err.message));
      });
  };
export const createConfigTemplate =
  (configTemplate) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios({
      method: "POST",
      url: `${baseUrl}/config-template`,
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      referrerPolicy: "no-referrer",
      data: {
        label: configTemplate.name,
        id_device_type: configTemplate.type,
        id_project: configTemplate.projectId,
        deviceConfigPresets: [
          {
            id_device_config_preset: null,
            id_device_config_key: null,
            id_config_template: null,
            desired_value: `${configTemplate.cellular * 1000}`,
            description: null,
            config_key:
              "properties.reported.settings.pushToCloudWithCellularEveryXms",
          },
          {
            id_device_config_preset: null,
            id_device_config_key: null,
            id_config_template: null,
            desired_value: `${configTemplate.wifi * 1000}`,
            description: null,
            config_key:
              "properties.reported.settings.pushToCloudWithWiFiEveryXms",
          },
          {
            id_device_config_preset: null,
            id_device_config_key: null,
            id_config_template: null,
            desired_value: `${configTemplate.sample * 1000}`,
            description: null,
            config_key: "properties.reported.settings.sampleEveryXms",
          },
          {
            id_device_config_preset: null,
            id_device_config_key: null,
            id_config_template: null,
            desired_value: parseInt(configTemplate.location, 10),
            description: null,
            config_key: "properties.reported.settings.locationConsentGiven",
          },
        ],
      },
    })
      .then((res) => {
        const filterParams = `filter[id_project]=${configTemplate.projectId}`;
        dispatch(getConfigListViewData(res, "", "", "", filterParams));
      })
      .then((res) => {
        dispatch(getCreateConfigDetail("pass"));
      })
      .catch((err) => {
        dispatch(showWarning(err.message));
        dispatch(getCreateConfigDetail("failed"));
      });
  };

export const addEmailMember =
  (projectId, Email, Access) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios({
      method: "POST",
      url: `${baseUrl}/group/${projectId}/user-invite`,
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      referrerPolicy: "no-referrer",
      data: {
        email: Email,
        type: Access,
      },
    })
      .then((res) => {
        dispatch(addMemberSuccess(res));
      })
      .catch((err) => {
        if (
          (err?.response?.data?.message.includes("422"),
          err.message.includes("422"))
        ) {
          dispatch(addMemberFailed("422"));
        }

        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const changeAccessTypeMember =
  (projectId, Email, Access) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios({
      method: "PUT",
      url: `${baseUrl}/group/${projectId}/user-invite`,
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      referrerPolicy: "no-referrer",
      data: {
        email: Email,
        type: Access,
      },
    })
      .then((res) => {
        dispatch(changeAccessTypeForMemberSuccess(res));
      })
      .catch((err) => {
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const DeleteProjectMember =
  (projectId, Email, Access) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios
      .delete(`${baseUrl}/group/${projectId}/user-invite`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.token}`,
        },
        referrerPolicy: "no-referrer",
        data: {
          email: Email,
          type: Access,
        },
      })
      .then((res) => {
        dispatch(getDeleteProjectMemberSuccess(res.status));
      })
      .catch((err) => {
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const updateConfigTemplate =
  (id, configTemplate, projectId) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios({
      method: "PUT",
      url: `${baseUrl}/config-template/${id}`,
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      referrerPolicy: "no-referrer",
      data: {
        label: configTemplate.name,
        id_device_type: configTemplate.type,
        id_project: projectId,
        deviceConfigPresets: [
          {
            id_device_config_preset: configTemplate.dataCellular,
            desired_value: `${configTemplate.cellular * 1000}`,
            config_key:
              "properties.reported.settings.pushToCloudWithCellularEveryXms",
          },
          {
            id_device_config_preset: configTemplate.dataWifi,
            desired_value: `${configTemplate.wifi * 1000}`,
            config_key:
              "properties.reported.settings.pushToCloudWithWiFiEveryXms",
          },
          {
            id_device_config_preset: configTemplate.dataSample,
            desired_value: `${configTemplate.sample * 1000}`,
            config_key: "properties.reported.settings.sampleEveryXms",
          },
          {
            id_device_config_preset: configTemplate.dataLocation,
            desired_value: parseInt(configTemplate.location, 10),
            config_key: "properties.reported.settings.locationConsentGiven",
          },
        ],
      },
    })
      .then((res) => {
        const filterParams = `filter[id_project]=${projectId}`;
        dispatch(getConfigListViewData(res, "", "", "", filterParams));
      })
      .then((res) => {
        dispatch(getUpdateConfigDetail("pass"));
      })
      .catch((err) => {
        dispatch(showWarning(err.message));
        dispatch(getUpdateConfigDetail("failed"));
      });
  };

export const deleteConfigTemplate = (id, projectId) => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .delete(`${baseUrl}/config-template/${id}`)
    .then((res) => {
      const filterParams = `filter[id_project]=${projectId}`;
      dispatch(getConfigListViewData(res, "", "", "", filterParams));
    })
    .then((res) => {
      dispatch(getConfigDelete("pass"));
    })
    .catch((err) => {
      dispatch(showWarning(err.message));
      dispatch(getConfigDelete("failed"));
    });
};

export const getSettingsConnections =
  (id, projectId) => (dispatch, getState, props) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(
        `${baseUrl}/physical-device/${id}/ping?filter[id_project]=${projectId}`
      )
      .then((list) => {
        dispatch(getSettingsConnectionsSuccess(list.data.payload.result));
        setTimeout(() => {
          getSettingsConnectionsSuccess("fail");
        }, 200);
      })
      .catch((err) => {
        dispatch(getConnectionSettingsFail("failed"));
        dispatch(showWarning(err.message));
      });
  };

export const getTemplateList =
  (pageNr, projectId) => (dispatch, getState, props) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(
        `${baseUrl}/config-template/index?filter[id_project]=${projectId}&${
          pageNr ? `page=${pageNr}` : "page=1"
        }`
      )
      .then((list) => {
        dispatch(getTemplateListSuccess(list.data));
      })
      .catch((err) => {
        dispatch(showWarning(err.message));
      });
  };

export const getConfirmTemplate =
  (templateView) => (dispatch, getState, props) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios({
      method: "POST",
      url: `${baseUrl}/physical-device/${templateView.physicalDeviceId}/assign/${templateView.setTemplate}`,
      cache: "no-cache",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      //  referrerPolicy: "no-referrer",
      data: {},
    })
      .then((list) => {
        axios.get(
          `${baseUrl}/physical-device/${templateView.physicalDeviceId}?expand=subDevices,subDevices.modbusDevice,currentConfig,deviceInfo,iotDevice`
        );
        dispatch(getConfirmTemplateSuccess(list));
      })
      .catch((err) => {
        dispatch(getTemplateSettingsFail("failed"));
      });
  };

export const connectWifiOption = (wifiDetails) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios({
    method: "PUT",
    url: `${baseUrl}/physical-device/${wifiDetails.physicalDeviceId}/wifi`,
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.token}`,
    },
    referrerPolicy: "no-referrer",
    data: {
      ssid: wifiDetails?.wifiSsid,
      password: wifiDetails?.password,
    },
  })
    .then((res) => {
      dispatch(getConnectWifiOption(res.data));
    })
    .catch((err) => {
      dispatch(showWarning(err.message));
    });
};

export const confirmCellularValue =
  (cellularValue, valueName) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    const updateCellularData = cellularValue.setCellulardata * 1000;
    const updatesetSampleData = cellularValue.setSampleData * 1000;
    const updatesetWifiData = cellularValue.setWifiData * 1000;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios({
      method: "PATCH",
      url: `${baseUrl}/device-config/${cellularValue.setDeviceConfigId}`,
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      referrerPolicy: "no-referrer",
      data: {
        id_device_config: cellularValue.setDeviceConfigId,
        id_physical_device: cellularValue.setPhysicalId,
        config_key: cellularValue.setConfigKey,
        current_value: null,
        current_timestamp: null,
        pending_value:
          valueName === "Wifi"
            ? updatesetWifiData.toString()
            : valueName === "Sample"
            ? updatesetSampleData.toString()
            : updateCellularData.toString(),
        pending_timestamp: Date.now(),
      },
    })
      .then((res) => {
        dispatch(getConfirmCellularValueSuccess(res));
      })
      .catch((err) => {
        dispatch(showWarning(err.message));
      });
  };

export const getWifiSettings =
  (physicalDeviceId) => (dispatch, getState, props) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios
      .get(`${baseUrl}/physical-device/${physicalDeviceId}/wifi`)
      .then((list) => {
        dispatch(getWifiSettingsSuccess(list.data));
      })
      .catch((err) => {
        dispatch(getWifiSettingsFail("failed"));
      });
  };

export const getSettingsPerformSelfTest =
  (id, projectId) => (dispatch, getState, props) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(
        `${baseUrl}/physical-device/${id}/self-test?filter[id_project]=${projectId}`
      )
      .then((list) => {
        dispatch(getSettingsSelfTestsSuccess(list));
      })
      .catch((err) => {
        dispatch(getSelfTestSettingsFail("failed"));
        // dispatch(showWarning(err.message));
      });
  };

export const getSettingsReboot =
  (id, projectId) => (dispatch, getState, props) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    // axios
    //   .get(`${baseUrl}/physical-device/${id}/restart`)
    //   .then((list) => {
    axios({
      method: "POST",
      url: `${baseUrl}/physical-device/${id}/restart?filter[id_project]=${projectId}`,
      cache: "no-cache",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      //  referrerPolicy: "no-referrer",
      data: {},
    })
      .then((list) => {
        dispatch(getSettingsRebootSuccess(list.data));
      })
      .catch((err) => {
        dispatch(getRebootSettingsFail("failed"));
        // dispatch(showWarning(err.message));
      });
  };

export const deleteWholeProject = (projectId) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios
    .get(
      `${baseUrl}/physical-device?type=gateway&filter[id_project]=${projectId}
        `
    )
    .then((res) => {
      if (res.data?._meta?.totalCount > 0) {
        dispatch(getDeleteProjectNameNotSuccess(true));
      } else if (res.data?._meta?.totalCount === 0) {
        axios
          .delete(
            `${baseUrl}/group/${projectId}
        `
          )
          .then((res) => {
            dispatch(getDeleteProjectNameSuccess(res.status));
          });
      }
    })
    .catch((err) => {
      dispatch(
        showWarning({ body: err?.response?.data?.message || err.message })
      );
    });
};

export const getMemberDetailsForProject =
  (projectId) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios
      .get(`${baseUrl}/group/${projectId}?expand=access`)
      .then((res) => {
        dispatch(getMemberDetailsForProjectSuccess(res));
      })
      .catch((err) => {
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const clearDeleteProjectNotPossible = () => (dispatch) => {
  dispatch(clearDeletePrjectNotPossibleSuccess());
};
export const clearDeleteProjectPossible = () => (dispatch) => {
  dispatch(clearDeletePrjectPossibleSuccess());
};

export const clearDeviceData = () => (dispatch) => {
  dispatch(clearDeviceDataSuccess());
};

export const clearMemberViewData = () => (dispatch) => {
  dispatch(getClearMemberViewSuccess());
};

export const clearSettingData = () => (dispatch) => {
  dispatch(clearSettingDataSuccess());
};

export const clearMemberSuccesData = () => (dispatch) => {
  dispatch(clearMemberSuccesDataSuccess());
};

export const clearDeleteConfigData = () => (dispatch) => {
  dispatch(clearDeleteConfigSuccess());
};

export const clearCreateConfigData = () => (dispatch) => {
  dispatch(clearCreateConfigSuccess());
};

export const clearUpdateConfigData = () => (dispatch) => {
  dispatch(clearUpdateConfigSuccess());
};

export const clearConfigData = () => (dispatch) => {
  dispatch(clearConfigListViewDataSuccess());
};
