import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./main.css";
import { Fragment } from "react";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { getConfirmTemplate } from "../../actions/list-view";
import LoaderRoller from "../loader/LoaderRoller";
import Pagination from "@material-ui/lab/Pagination";
import { getTemplateList } from "../../actions/list-view";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import { faTimes,faCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import CreateTemplatePopup from "../../components/popups/CreateTemplatePopup";
import StatusPopUpDelete from "../../components/popups/StatusPopUpDelete";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";

class ApplyNewTemplatePopup extends Component {
  state = {
    formInput: {
      setTemplate: "",
      physicalDeviceId: "",
      confirmTemplate: false,
      connectionCheck: false,
      setApplyConfirm: false,
      setCreateTemplate: false,
      applyPopUpClose: false,
      
    },
  };
  handleToCreate = () => {
    const formInput = { ...this.state.formInput };
    formInput.open = true;
    formInput.popup = true;

    formInput.setCreateTemplate = true;

    formInput.setTemplate = false;
    this.setState({ formInput });

    this.props.handleToOpenCreateTemplate();
 
  };
  closeApplyPopUp = () => {
    this.props.handleToClose();
  };
  handleToTemplateConfirm = () => {
    const formInput = { ...this.state.formInput };
    formInput.physicalDeviceId = this.props.physicalDeviceId;
    formInput.setApplyConfirm = true;
    this.setState({ formInput });
    this.props.getConfirmTemplate(formInput);
  };

  handlePageChange = (event, value) => {
    this.props.getTemplateList(value, this.props.projectId);
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  handleChangeTemplate = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.setTemplate = event.target.id;
    this.setState({ formInput });
  };

  handleToConnectionClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.connectionstatus = false;
    formInput.setApplyWifi = false;
    formInput.setApplyConfirm = false;
    formInput.selftestConnect = false;
    formInput.allpyNewErrorLog = false;
    this.setState({ formInput });
  };
  
  

  render() {
    const { formInput } = this.state;
    console.log(
      this.props.settingslistConfirmTemplateOption?.status,
      formInput.setApplyConfirm,
      formInput.setCreateTemplate,
      this.props.failTemplateOption
    );
    return (
      <div>
        
        {this.props.settingslistConfirmTemplateOption?.status === 200 &&
        formInput.setApplyConfirm ? (
          <div>
           <Dialog open={true} onClose={this.handleToConnectionClose} PaperProps={{
            style: {maxWidth: '325px',border: "2px solid var(--Ocean, #006)"},}} >
              <div onClick={this.props.handleToCancelTemplate}>
                <div style={{ justifyItems: "right", display: "grid" }}>
                  <FontAwesomeIcon
                    style={{
                      fontSize: "25px",
                      marginTop: "10px",
                      marginRight: "16px",
                    }}
                    className="wifiView wifiFailVie"
                    data-toggle="tooltip"
                    data-placement="top"
                    icon={faTimes}
                    onClick={this.props.handleToClose}
                  />
                </div>
              </div>
              <DialogTitle>
                <div style={{ marginRight: "48px" }}>
                  {" "}
                  <FontAwesomeIcon
                    className="  online"
                     style={{
                      left: "50%",
                      position: "relative",
                      fontSize:"35px"

                     }}
                    
                    icon={faCircleCheck}
                  />
                </div>

                <div
                  className="font-weight-bold mr-5 ml-5" style={{textAlign:"center"}}
                  
                >
                  Applying complete!
                </div>
              </DialogTitle>
            </Dialog> 
           
          </div>
    //       <StatusPopUpDelete
    //        closePopup={this.handleToClose}
    //        message="Removing Complete!"
    //       className="online"
    //       icon={faCircleCheck}
    // />
        ) : this.props.failTemplateOption === "failed" ||
          (this.props.settingslistConfirmTemplateOption?.status !== 200 &&
            this.props.settingslistConfirmTemplateOption?.status !==
              undefined) ? (
          <div>
            <Dialog open={true} onClose={this.handleToConnectionClose}  PaperProps={{
            style: {maxWidth: '325px',border: "2px solid var(--Ocean, #006)"},}}>
              <div onClick={this.props.handleToCancelTemplate}>
                <div style={{ justifyItems: "right", display: "grid" }}>
                  <FontAwesomeIcon
                    style={{
                      fontSize: "30px",
                      marginTop: "10px",
                      marginRight: "16px",
                    }}
                    className="wifiView wifiFailVie"
                    data-toggle="tooltip"
                    data-placement="top"
                    icon={faTimes}
                    onClick={this.props.handleToClose}
                  />
                </div>
              </div>
              <DialogTitle>
                <div>
                  {" "}
                  <FontAwesomeIcon
                    className=" offline"
                    style={{
                      left: "46%",
                      position: "relative",
                      fontSize:"35px"
                    }}
                    icon={faCircleXmark}
                  />
                </div>
                <div
                  className="font-weight-bold mr-5 ml-5" style={{textAlign:"center"}}>
                  Applied template failed, please try again!
                </div>
              </DialogTitle>
            </Dialog>
          </div>
        ) : (
          <div className="template-popup templateSecondLayerApplyTemplate">
            <div
              className="template-popup-container"
              style={{
                border: "2px solid var(--Ocean, #006)",
                boxShadow:
                  "0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
                borderRadius: "8px",
                background: "var(--Light, #FFF)",
                minWidth: "498px"
                
              }}
            >
              <div style={{ justifyItems: "right", display: "grid" }}>
                  <FontAwesomeIcon
                    style={{
                      fontSize: "30px",
                      marginTop: "10px",
                      marginRight: "16px",
                    }}
                    className="wifiView wifiFailVie"
                    data-toggle="tooltip"
                    data-placement="top"
                    icon={faTimes}
                    onClick={this.props.handleToClose}
                  />
                </div>
              <h4
                style={{ color: "black", fontSize: "24px", marginLeft: "55px" }}
              >
                Apply Configuration Template
              </h4>
              <div className="templateListDiv">
                <div className="bacecard-modbus-template mx-3 my-3 py-3 templateDiv">
                  <div>
                  
                    <div className="templateBlock">
                      {this.props.templateListOptions !== undefined ? (
                        this.props.templateListOptions?.items?.map(
                          (m, index) => (
                            <div style={{ display: "flex", width: "493px" }}>
                              {" "}
                              <input
                                type="radio"
                                id={m.id_config_template}
                                name="headerNameDevice"
                                defaultValue="0"
                                className="radioOption"
                                onChange={this.handleChangeTemplate.bind(this)}
                                defaultChecked={index === 0 ?? true}
                                style={{
                                  accentColor: "rgb(0, 0, 0)",
                                  width: "33px",
                                }}
                              />
                              <div
                                className="templateLabel"
                                style={{ width: "606px" }}
                              >
                                {m.label}
                              </div>{" "}
                              <div
                                className="tooltips"
                                style={{ width: "34%"}}
                              ><FontAwesomeIcon
                              style={{
                                marginRight: "4px",
                                marginLeft: "10px",
                                width: "40%",
                                alignSelf: "stretch",
                                textAlign: "center",
                              }}
                              className="fa-lg"
                              icon={faCircleInfo}
                            />
                                <span className="tooltipstext tooltipsTemplate" >
                                  <div>BACE high frequency</div>
                                  <table>
                                    <tr>
                                      <td style={{ width: "10%" }}></td>
                                      <td style={{ float: "left" }}>
                                        Interval cellular :{" "}
                                      </td>
                                      <td>
                                        {m.deviceConfigPresets
                                          .filter(
                                            (m) =>
                                              m.config_key ===
                                              "properties.reported.settings.pushToCloudWithCellularEveryXms"
                                          )
                                          .map((k) => k.desired_value) *
                                          0.001}{" "}
                                        sec
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "10%" }}></td>
                                      <td style={{ float: "left" }}>
                                        Interval Wifi/Ethernet :
                                      </td>
                                      <td>
                                        {m.deviceConfigPresets
                                          .filter(
                                            (m) =>
                                              m.config_key ===
                                              "properties.reported.settings.pushToCloudWithWiFiEveryXms"
                                          )
                                          .map((k) => k.desired_value) *
                                          0.001}{" "}
                                        sec
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "10%" }}></td>
                                      <td style={{ float: "left" }}>
                                        Location :{" "}
                                      </td>
                                      <td>
                                        {m.deviceConfigPresets
                                          .filter(
                                            (m) =>
                                              m.config_key ===
                                              "properties.reported.settings.locationConsentGiven"
                                          )
                                          .map((k) => k.desired_value?(k.desired_value):("0"))}{" "}
                                          
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "10%" }}></td>
                                      <td style={{ float: "left" }}>
                                        Sample rate :{" "}
                                      </td>
                                      <td>
                                        {m.deviceConfigPresets
                                          .filter(
                                            (m) =>
                                              m.config_key ===
                                              "properties.reported.settings.sampleEveryXms"
                                          )
                                          .map((k) => k.desired_value) *
                                          0.001}{" "}
                                        sec
                                      </td>
                                    </tr>
                                  </table>
                                </span>
                                
                              </div>
                            </div>
                          )
                        )
                      ) : (
                        <LoaderRoller />
                      )}
                    </div>
                    
                    <div className="pageStyle" style={{marginTop: "12px"}}>
                      <Pagination
                        count={
                          this.props.settingslistTemplateOption?._meta
                            ?.pageCount
                        }
                        page={
                          this.props.settingslistTemplateOption?._meta
                            ?.currentPage
                        }
                        onChange={this.handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col pb-3" style={{ textAlign: "center" }}>
                <button
                  className={
                    formInput.setApplyConfirm
                      ? "btn-cancel mx-1 cancelTemplate"
                      : "btn-cancel"
                  }
                  onClick={this.props.handleToClose}
                  disabled={formInput.setApplyConfirm ? true : false}
                  style={{ textTransform: "capitalize" }}
                >
                  Cancel
                </button>{" "}
                <button
                  onClick={() => {
                    this.handleToCreate();
                  }}
                  className="applyTemplate"
                >
                  <div
                    style={{ display: "flex", marginRight: "30px" }}
                    class="applyTemplateBorder"
                  >
                    {" "}
                    Create New Template{" "}
                  </div>
                </button>
                <button
                  className={
                    formInput.setApplyConfirm
                      ? "btn-cancel submitDisableButton"
                      : "btn-cancel submitButton"
                  }
                  onClick={() => this.handleToTemplateConfirm()}
                  disabled={formInput.setApplyConfirm ? true : false}
                  style={{ textTransform: "capitalize" }}
                >
                  <Fragment>Apply</Fragment>
                  {/* )} */}
                </button>
              </div>{" "}
            </div>
            {formInput.setApplyConfirm && (
              <div style={{ textAlign: "centre", marginTop: "250px" }}>
                {" "}
                <LoaderRoller />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    settingslistConfirmTemplateOption:
      state.listViewReducer.settingslistConfirmTemplateOption,
    settingslistTemplateOption:
      state.listViewReducer.settingslistTemplateOption,
    failTemplateOption: state.listViewReducer.failTemplateOption,
  };
}

export default withRouter(
  connect(mapStateToProps, { getConfirmTemplate, getTemplateList })(
    ApplyNewTemplatePopup
  )
);
