import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import SessionsSubmenu from "../../components/menus/sub-menu/sessions/SessionsSubmenu";
import SessionListCard from "../../components/session-cards/SessionListCard";
//import { getListViewData, clearListViewData } from "../../actions/list-view";
import {
  getSessionListViewData,
  clearSessionListViewData,
} from "../../actions/session-view";
import LoaderRoller from "../../components/loader/LoaderRoller";

class SessionListView extends Component {
  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    if (!currentPageNr) {
      this.props.history.push("/sessions/list-view/1");
    }
    this.props.getSessionListViewData(currentPageNr, "start", "DESC");
  };

  componentDidUpdate = (prevProps) => {
    const { currentPageNr } = this.props.match.params;

    if (this.props.location !== prevProps.location) {
      const sortColumn = new URLSearchParams(this.props.location.search).get(
        "sortColumn"
      );
      const sortDirection = new URLSearchParams(this.props.location.search).get(
        "sortDirection"
      );

      this.props.getSessionListViewData(
        currentPageNr,
        sortColumn,
        sortDirection
      );
    }
  };

  componentWillUnmount = () => {
    if (!window.location.pathname.startsWith("/sessions")) {
      //console.log("Clearing session listview data");
      this.props.clearSessionListViewData();
    }
  };

  render() {
    if (this.props.sessionListViewData) {
      //console.log(this.props.sessionListViewData);
      return (
        <Fragment>
          <SessionsSubmenu
            nrResults={this.props.sessionListViewData?._meta?.totalCount}
          />
          <div className="bacecontainer-lg pl-2">
            <SessionListCard
              subtype={"sessions"}
              subpage={"measurement-data"}
            />
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <SessionsSubmenu nrResults={0} />
          <div className="bacecontainer pl-2">
            <LoaderRoller />
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    sessionListViewData: state.sessionReducer.sessionListViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getSessionListViewData,
    clearSessionListViewData,
  })(SessionListView)
);
