import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import OneBaceEpSubmenu from "../../components/menus/sub-menu/subtypes/OneBaceEpSubmenu";
import BaceEpInfoCard from "../../components/device-cards/BaceEpInfoCard";
import { getListViewData, clearListViewData } from "../../actions/list-view";
import {
  getDeviceData,
  clearDeviceData,
  getDeviceInfoData,
  getDeviceEventInfoData,
} from "../../actions/list-view";
import LoaderRoller from "../../components/loader/LoaderRoller";

class DeviceInfoView extends Component {
  componentDidMount = () => {
    const { id, projectId } = this.props.match.params;
    console.log(this.props.match, "url");
    if (!id) {
      return this.props.history.push("/");
    }
    this.props.clearListViewData();
    this.props.getDeviceInfoData(id, projectId);
    this.props.getDeviceEventInfoData(id, 1);
  };

  componentWillUnmount = () => {
    this.props.clearListViewData();
  };

  render() {
    if (this.props.deviceInfoViewData) {
      return (
        <Fragment>
          <OneBaceEpSubmenu deviceHeaderInfo={this.props.deviceInfoViewData} />
          <div>
            <div className="row mt-3">
              <BaceEpInfoCard
                deviceListInfo={this.props.deviceInfoViewData}
                deviceEventList={this.props.deviceEventData}
              />
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <OneBaceEpSubmenu deviceHeaderInfo={this.props.deviceInfoViewData} />
          <div className="bacecontainer">
            <div className="row mt-3">
              <div className="bacecontainer-lg">
                <LoaderRoller />
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    deviceViewData: state.listViewReducer.deviceViewData,
    deviceInfoViewData: state.listViewReducer.deviceInfoViewData,
    deviceEventData: state.listViewReducer.deviceEventData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getListViewData,
    clearListViewData,
    getDeviceInfoData,
    getDeviceEventInfoData,
    getDeviceData,
    clearDeviceData,
  })(DeviceInfoView)
);
