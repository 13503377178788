import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Moment from "react-moment";
import { Fragment } from "react";

class DeviceInfoCard extends Component {
  componentDidMount = () => {};

  render() {
    // console.log(this.props.deviceViewData);
    return (
      <Fragment>
        {this.props.deviceViewData.physicalDevices.length > 0 ? (
          this.props.deviceViewData.physicalDevices
            .filter((physicalDevice) => physicalDevice.id_parent === null)
            .map((physicalDevice, i) => (
              <div
                key={i}
                className="col-12 col-xl-5 bacecard-device-info p-4 m-3"
              >
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="specs-key">Device Name</td>
                      <th className="specs-value">
                        {this.props.deviceViewData.label}
                      </th>
                    </tr>
                    <tr>
                      <td className="specs-key">Device Id</td>
                      <th className="specs-value">{physicalDevice.label}</th>
                    </tr>
                    <tr>
                      <td className="specs-key">Serial number</td>
                      <th className="specs-value">
                        {physicalDevice.serial_hardware}
                      </th>
                    </tr>
                    <tr>
                      <td className="specs-key">Country</td>
                      <th className="specs-value">
                        {this.props.deviceViewData.country.name}
                      </th>
                    </tr>
                    <tr>
                      <td className="specs-key">Last seen</td>
                      <th className="specs-value">
                        {" "}
                        <Moment format="DD MMM YYYY hh:mm">
                          {physicalDevice.last_data_at * 1000}
                        </Moment>
                      </th>
                    </tr>
                    <tr>
                      <td className="specs-key">First seen</td>
                      <th className="specs-value">
                        {" "}
                        <Moment format="DD MMM YYYY hh:mm">
                          {this.props.deviceViewData.created_at * 1000}
                        </Moment>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))
        ) : (
          <div></div>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    deviceViewData: state.listViewReducer.deviceViewData,
  };
}

export default withRouter(connect(mapStateToProps, {})(DeviceInfoCard));
