import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import "./styles.css";
import BACELogoLightWhite from "../../assets/logo/BACE_Logo_Word_white.png";
import {
  faFolderGear,
  faHardDrive,
  faCircleExclamation,
  faPlusCircle,
  faServer,
} from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import TopMenu from "../../components/menus/top-menu/TopMenu";
import CONFIG from "../../config";
import axios from "axios";
import ProjectView from "./projectView";
import BACELogo from "../../assets/logo/BACE-logo-blue.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import {
  renameProjectName,
  getAllProject,
  createProjectName,
} from "../../actions/list-view";
import ProjectMoreIcon from "../../assets/Icons/secondary.png";
import Chart from "react-apexcharts";
import LoaderRoller from "../../components/loader/LoaderRoller";
import BACELogoProject from "../../assets/Icons/bace-logo-project.png";

const { baseUrl } = CONFIG;
const initialState = {
  modbus: [],
  rename: false,
};
class Projects extends Component {
  state = {
    formInput: {
      changeName: false,
      projectNameConfirm: false,
      projectNameCreate: false,
      projectName: "",
      projectId: "",
      showOption: false,
      createName: false,
      projectNewName: "",
      options: [],
      series: [],
      dataLabels: [],
    },
  };
  componentDidMount = () => {
    const formInput = { ...this.state.formInput };
    let { id } = this.props.match.params;
    if (!id) {
      return this.props.history.push("/");
    }
    const { currentPageNr } = this.props.match.params;
    if (!currentPageNr) {
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { formInput } = this.state;
    if (window.location.pathname.startsWith("/dashboard/home/")) {
      const projectId = window.location.pathname.slice(16);
      this.props.onSelectProject(projectId);
    }
  };

  handleStatusChange = (id, labelName) => {
    const formInput = { ...this.state.formInput };
    formInput.changeName = true;
    formInput.projectName = labelName;
    formInput.projectId = id;
    this.setState({ formInput });
  };

  handleToConnectionClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.changeName = false;
    formInput.createName = false;
    this.setState({ formInput });
  };

  handleChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput[input.name] = input.value;
    formInput.projectName = input.value;
    this.setState({ formInput });
    // this.handleFilterChange();
  };

  handleCreateChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput[input.name] = input.value;
    formInput.projectNewName = input.value;
    this.setState({ formInput });
    // this.handleFilterChange();
  };
  handleToChangeProjectName = () => {
    const formInput = { ...this.state.formInput };
    formInput.changeName = false;
    formInput.showOption = false;
    formInput.projectNameConfirm = true;
    this.setState({ formInput });
    this.props.renameProjectName(formInput.projectName, formInput.projectId);
  };

  handleToCreateProjectName = () => {
    const formInput = { ...this.state.formInput };
    formInput.createName = false;
    formInput.projectNameCreate = true;
    this.setState({ formInput });
    this.props.createProjectName(formInput.projectNewName);
  };

  handleToOptions = (id) => {
    const formInput = { ...this.state.formInput };
    formInput.projectId = id;
    formInput.showOption = formInput.showOption === true ? false : true;
    this.setState({ formInput });
  };

  handleToCloseOptions = (id) => {
    const formInput = { ...this.state.formInput };
    formInput.projectId = id;
    formInput.showOption = formInput.showOption === true ? false : true;
    this.setState({ formInput });
  };
  handleToCreateProject = () => {
    const formInput = { ...this.state.formInput };
    formInput.createName = true;
    this.setState({ formInput });
  };

  handleToProjectNameUpdate = () => {
    if (
      this.props.projectNameChanged?.status === 200 ||
      this.props.projectNameCreated?.status === 200
    ) {
      this.props.getAllProject();
    }
    const formInput = { ...this.state.formInput };
    formInput.changeName = false;
    formInput.projectNameConfirm = false;
    formInput.createName = false;
    formInput.projectNameCreate = false;
    this.setState({ formInput });
  };

  render() {
    const formInput = { ...this.state.formInput };
    if (this.props.projectAllData?.items) {
      //  const {} = this.state;

      const projectId = window.location.pathname.slice(16);
      // this.state = {
      formInput.options = {
        chart: {
          type: "pie",
        },
        dataLabels: {
          enabled: false,
        },

        colors: ["#2C42B8", "#CAD0ED"],
        labels: ["online", "offline"],
        plugins: {
          datalabels: false,
        },
      };

      formInput.series = [4, 5];
      formInput.dataLabels = {
        dropShadow: {
          blur: 3,
          opacity: 0.8,
        },
      };
      // };
      //   const formInput = { ...this.state.formInput };

      return (
        <Fragment>
          {formInput.projectNameConfirm && (
            <Dialog
              open={formInput.projectNameConfirm === true ? true : false}
              onClose={this.handleToProjectNameUpdate}
            >
              <div onClick={this.handleToProjectNameUpdate}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToProjectNameUpdate}
                />
              </div>
              <DialogTitle>
                {this.props.projectNameChanged?.status === 200 ? (
                  <p className="wififailedMessage" style={{ display: "flex" }}>
                    <p className="projectName">{formInput.projectName} </p>{" "}
                    changed sucessfully !
                  </p>
                ) : (
                  <LoaderRoller />
                )}
              </DialogTitle>
            </Dialog>
          )}

          {formInput.projectNameCreate && (
            <Dialog
              open={formInput.projectNameCreate === true ? true : false}
              onClose={this.handleToProjectNameUpdate}
            >
              <div onClick={this.handleToProjectNameUpdate}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToProjectNameUpdate}
                />
              </div>
              <DialogTitle>
                {this.props.projectNameCreated?.status === 200 ? (
                  <p className="wififailedMessage" style={{ display: "flex" }}>
                    <p className="projectName"> {formInput.projectNewName} </p>{" "}
                    created sucessfully !
                  </p>
                ) : (
                  <LoaderRoller />
                )}
              </DialogTitle>
            </Dialog>
          )}
          {formInput.changeName && (
            <Dialog
              open={formInput.changeName === true ? true : false}
              onClose={this.handleToConnectionClose}
            >
              <div onClick={this.handleToConnectionClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToConnectionClose}
                />
              </div>
              <DialogTitle>
                <p className="wififailedMessage">Change project name</p>
                <input
                  className="form-control-start formInput inputProjectBox"
                  defaultValue={formInput.projectName}
                  name="label"
                  type="text"
                  placeholder="Project name"
                  onChange={this.handleChange}
                />
                <div className="col pb-3">
                  <div className="d-flex justify-content-end">
                    <button
                      className={
                        formInput.setApplyConfirm
                          ? "btn-cancel mx-1 cancelTemplate"
                          : "btn-cancel mx-1"
                      }
                      onClick={this.handleToConnectionClose}
                      disabled={formInput.setApplyConfirm ? true : false}
                    >
                      Cancel
                    </button>{" "}
                    <button
                      className={
                        formInput.setApplyConfirm
                          ? "btn-cancel submitDisableButton"
                          : "btn-cancel submitButton"
                      }
                      onClick={() => this.handleToChangeProjectName()}
                      disabled={formInput.projectName === "" ? true : false}
                    >
                      <Fragment>Confirm</Fragment>
                      {/* )} */}
                    </button>
                  </div>
                </div>
              </DialogTitle>
            </Dialog>
          )}

          {formInput.createName && (
            <Dialog
              open={formInput.createName === true ? true : false}
              onClose={this.handleToConnectionClose}
            >
              <div onClick={this.handleToConnectionClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToConnectionClose}
                />
              </div>
              <DialogTitle>
                <p className="wififailedMessage">Create New project</p>
                <input
                  className="form-control-start formInput inputProjectBox"
                  defaultValue={formInput.projectNewName}
                  name="label"
                  type="text"
                  placeholder="Project name"
                  onChange={this.handleCreateChange}
                />
                <div className="col pb-3">
                  <div className="d-flex justify-content-end">
                    <button
                      className={
                        formInput.setApplyConfirm
                          ? "btn-cancel mx-1 cancelTemplate"
                          : "btn-cancel mx-1"
                      }
                      onClick={this.handleToConnectionClose}
                    >
                      Cancel
                    </button>
                    <button
                      className={
                        // formInput.projectNewName === ""
                        //   ? "btn-cancel submitDisableButton"
                        //   :
                        "btn-cancel submitButton"
                      }
                      onClick={() => this.handleToCreateProjectName()}
                      //disabled={formInput.projectNewName === "" ? true : false}
                    >
                      <Fragment>Confirm</Fragment>
                      {/* )} */}
                    </button>
                  </div>
                </div>
              </DialogTitle>
            </Dialog>
          )}

          <div className="pl-2" style={{ height: "600px" }}>
            <div className="col pl-4">
              <div
                style={{
                  display: "flex",
                  height: "80px",
                  backgroundColor: "#2C42B8",
                }}
              >
                <img
                  src={BACELogoLightWhite}
                  alt="logo"
                  title="BACE iot logo"
                />

                <h3 className="projectTitle"> Projects</h3>
                <TopMenu />
              </div>
              <ul class="auto-grid mt-4">
                {this.props.projectAllData?.items &&
                  this.props.projectAllData?.items.map((m, index) => (
                    <div className="divmodbus card-container">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                          marginTop: "3px",
                        }}
                      >
                        <button
                          onClick={() => {
                            this.handleToOptions(m.id_project);
                          }}
                          className="optionsValue"
                        >
                          <img
                            alt="morebutton"
                            src={ProjectMoreIcon}
                            title="project Switch"
                            className="projectValue"
                          />
                        </button>
                        <div className="overflow-content">
                          {formInput.showOption &&
                            m.id_project === formInput.projectId && (
                              <ul className="listViewDataOption">
                                <li>
                                  <button
                                    onClick={() =>
                                      this.handleStatusChange(
                                        m.id_project,
                                        m.label
                                      )
                                    }
                                    className="renameButton listOption"
                                  >
                                    Rename
                                  </button>
                                </li>
                              </ul>
                            )}
                        </div>
                      </div>
                      <Link
                        to={`/dashboard/home/${m.id_project}`}
                        key={m.id_project}
                        className="text-decoration-none"
                      >
                        <div style={{ padding: "1rem 1.2rem" }}>
                          <h5
                            class="card-title"
                            style={{
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            {m.label}
                          </h5>
                          <div class="card-text">
                            {m?.countConnectedGateways === 0 &&
                            m?.countDisconnectedGateways === 0 ? (
                              <div
                                className="d-flex justify-content-center"
                                style={{ padding: "10px" }}
                              >
                                <div className="circle"></div>
                                <div className="mt-4">
                                  <div className="connectLine">
                                    <div
                                      className="project-online"
                                      style={{ marginLeft: "25px" }}
                                    >
                                      0 Gateways
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="d-flex justify-content-center"
                                style={{ padding: "10px" }}
                              >
                                <Chart
                                  options={formInput.options}
                                  series={[
                                    m?.countConnectedGateways,
                                    m?.countDisconnectedGateways,
                                  ]}
                                  //  label={this.state.label}
                                  type="pie"
                                  width="150"
                                />
                                <div className="mt-2">
                                  <div className="connectLine">
                                    <div className="project-online">
                                      {m?.countConnectedGateways} online
                                    </div>
                                  </div>
                                  <div className="connectLine">
                                    <div className="project-offline">
                                      {m?.countDisconnectedGateways} offline
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>{" "}
                      </Link>
                    </div>
                  ))}
              </ul>
            </div>
          </div>
          {this.props.projectAllData?.items.length === 0 && (
            <div className="noProject">
              You are not a member of any projects yet. Please ask for an
              invitation or create your first project.
            </div>
          )}
          <div class="fixedButton card-container justify-content-start">
            <div
              className={
                this.props.projectAllData?.items.length === 0
                  ? "noButtonProject"
                  : "buttonProject"
              }
            >
              <button
                onClick={this.handleToCreateProject}
                className="buttonCreateView"
              >
                <FontAwesomeIcon
                  className="plusIcon text-white"
                  icon={faPlusCircle}
                />
                <p className="plusCreate text-white"> Create Project</p>
              </button>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="pl-2" style={{ height: "600px" }}>
            <div className="col pl-4">
              <div
                style={{
                  display: "flex",
                  height: "80px",
                  backgroundColor: "#2C42B8",
                }}
              >
                <img
                  alt="logo"
                  src={BACELogoLightWhite}
                  title="BACE iot logo"
                />

                <h3 className="projectTitle"> Projects</h3>
                <TopMenu />
              </div>
              <div
                className="bacecontainer-lg"
                style={{ width: "90%", margin: "auto" }}
              >
                <LoaderRoller />
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    projectNameChanged: state.listViewReducer?.projectNameChanged,
    projectNameCreated: state.listViewReducer?.projectNameCreated,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    renameProjectName,
    getAllProject,
    createProjectName,
  })(Projects)
);
