import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import HumanMovementScienceSubmenu from "../../components/menus/sub-menu/subtypes/HumanMovementScienceSubmenu";
import DeviceListCard from "../../components/device-cards/DeviceListCard";
import { getListViewData, clearListViewData } from "../../actions/list-view";

class DeviceListView extends Component {
  componentDidMount = () => {
    this.props.getListViewData("human-movement-science");
  };

  componentWillUnmount = () => {
    this.props.clearListViewData();
  };

  render() {
    if (this.props.listViewData) {
      // console.log(this.props.listViewData);
      return (
        <Fragment>
          <HumanMovementScienceSubmenu
            nrResults={this.props.listViewData?._meta?.totalCount}
          />
          <div className="bacecontainer pl-2">
            <DeviceListCard
              subtype={"human-movement-science"}
              subpage={"measurement-data"}
            />
          </div>
        </Fragment>
      );
    } else {
      return <div></div>;
    }
  }
}

function mapStateToProps(state) {
  return { listViewData: state.listViewReducer.listViewData };
}

export default withRouter(
  connect(mapStateToProps, { getListViewData, clearListViewData })(
    DeviceListView
  )
);
