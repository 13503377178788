import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import OneBaceEpSubmenu from "../../components/menus/sub-menu/subtypes/OneBaceEpSubmenu";
import BaceEpSettingsCard from "../../components/device-cards/BaceEpSettingsCard";
import { getListViewData, clearListViewData } from "../../actions/list-view";
import {
  getDeviceData,
  clearDeviceData,
  getDeviceInfoData,
  getDeviceEventInfoData,
} from "../../actions/list-view";

import LoaderRoller from "../../components/loader/LoaderRoller";

const initialState = {
  projectId: "",
};
class DeviceSettingsView extends Component {
  componentDidMount = () => {
    const { id, projectId: projectId } = this.props.match.params;
    if (!id) {
      return this.props.history.push("/");
    }
    this.setState({
      projectId,
    });
    this.props.getDeviceInfoData(id, this.props.match.params.projectId);
    this.props.getDeviceEventInfoData(id, 1);
  };

  componentWillUnmount = () => {
    this.props.clearListViewData();
  };

  render() {
    if (this.props.deviceInfoViewData) {
      return (
        <Fragment>
          <OneBaceEpSubmenu deviceHeaderInfo={this.props.deviceInfoViewData} />
          <div>
            <div className="row" style={{marginLeft:"55px"}}>
              <BaceEpSettingsCard
                deviceListInfo={this.props.deviceInfoViewData}
                deviceEventList={this.props.deviceEventData}
                projectId={this.state?.projectId}
              />
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <OneBaceEpSubmenu deviceHeaderInfo={this.props.deviceInfoViewData} />
          <div className="bacecontainer">
            <div className="row mt-3">
              <div className="bacecontainer-lg">
                <LoaderRoller />
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    deviceViewData: state.listViewReducer.deviceViewData,
    deviceInfoViewData: state.listViewReducer.deviceInfoViewData,
    deviceEventData: state.listViewReducer.deviceEventData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getListViewData,
    clearListViewData,
    getDeviceInfoData,
    getDeviceEventInfoData,
    getDeviceData,
    clearDeviceData,
  })(DeviceSettingsView)
);
