import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Moment from "react-moment";
import PaginationNew from "../../components/global/paginate/PaginationNew";

import {
  getSessionListViewData,
  clearSessionListViewData,
} from "../../actions/session-view";

import globalFunctions from "../../helpers/functions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import DownIcon from "@material-ui/icons/ArrowDropDown";
import UpIcon from "@material-ui/icons/ArrowDropUp";

import "./sensor.css";

const { uniqueKey, Td } = globalFunctions;

class SessionListCard extends Component {
  state = {
    formInput: {
      deviceName: "",
      deviceId: "",
      deviceType: "",
      sessionId: "",
    },
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let filter = this.getFilters();

    this.props.getSessionListViewData(
      1,
      "start",
      "DESC",
      filter.key,
      filter.value
    );
  };

  handleChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput[input.name] = input.value;
    this.setState({ formInput });
  };

  getFilters = () => {
    let filterKey;
    let filterValue;
    const { formInput } = this.state;
    if (formInput.deviceName) {
      filterKey = "group.label";
      filterValue = formInput.deviceName;
    } else if (formInput.deviceId) {
      filterKey = "fromDevice.label";
      filterValue = formInput.deviceId;
    } else if (formInput.deviceType) {
      filterKey = "group.subtype";
      filterValue = formInput.deviceType;
    } else if (formInput.sessionId) {
      filterKey = "label";
      filterValue = formInput.sessionId;
    }

    return { key: filterKey, value: filterValue };
  };

  render() {
    const { formInput } = this.state;
    const { subtype, subpage } = this.props;
    if (
      this.props.sessionListViewData &&
      this.props.sessionListViewData?._meta?.totalCount > 0
    ) {
      console.log("session list view data: ", this.props.sessionListViewData);
      const { pathname } = this.props.location;
      const { currentPageNr } = this.props.match.params;
      const baseRoute = currentPageNr
        ? pathname.substring(0, pathname.lastIndexOf("/"))
        : pathname;

      const sortColumn = new URLSearchParams(this.props.location.search).get(
        "sortColumn"
      );
      const sortDirection = new URLSearchParams(this.props.location.search).get(
        "sortDirection"
      );
      const newSortDirection = sortDirection
        ? sortDirection === "ASC"
          ? "DESC"
          : "ASC"
        : "DESC";
      return (
        <div className="bacecard-lg p-4 mt-4">
          <form onSubmit={this.handleSubmit}>
            <button className="d-none">SUBMIT</button>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="no-border">
                    <Link
                      className="specs-key-low clickable"
                      scope="col"
                      to={`${baseRoute}/1?sortColumn=group.label&sortDirection=${newSortDirection}`}
                    >
                      Device name
                    </Link>
                    {sortColumn === "group.label" ? (
                      sortDirection === "ASC" ? (
                        <DownIcon />
                      ) : (
                        <UpIcon />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="specs-key-low no-border" scope="col">
                    <Link
                      className="specs-key-low clickable  no-border"
                      scope="col"
                      to={`${baseRoute}/1?sortColumn=fromDevice.label&sortDirection=${newSortDirection}`}
                    >
                      Device id
                    </Link>
                    {sortColumn === "fromDevice.label" ? (
                      sortDirection === "ASC" ? (
                        <DownIcon />
                      ) : (
                        <UpIcon />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="specs-key-low no-border" scope="col">
                    <Link
                      className="specs-key-low clickable  no-border"
                      scope="col"
                      to={`${baseRoute}/1?sortColumn=group.subtype&sortDirection=${newSortDirection}`}
                    >
                      Device type
                    </Link>
                    {sortColumn === "group.subtype" ? (
                      sortDirection === "ASC" ? (
                        <DownIcon />
                      ) : (
                        <UpIcon />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="specs-key-low no-border" scope="col">
                    <Link
                      className="specs-key-low clickable  no-border"
                      scope="col"
                      to={`${baseRoute}/1?sortColumn=label&sortDirection=${newSortDirection}`}
                    >
                      Session id
                    </Link>
                    {sortColumn === "label" ? (
                      sortDirection === "ASC" ? (
                        <DownIcon />
                      ) : (
                        <UpIcon />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="no-border">
                    <Link
                      className="specs-key-low clickable  no-border"
                      scope="col"
                      to={`${baseRoute}/1?sortColumn=start&sortDirection=${newSortDirection}`}
                    >
                      Start
                    </Link>
                    {sortColumn === "start" ? (
                      sortDirection === "ASC" ? (
                        <DownIcon />
                      ) : (
                        <UpIcon />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="specs-key-low  no-border" scope="col">
                    <Link
                      className="specs-key-low clickable  no-border"
                      scope="col"
                      to={`${baseRoute}/1?sortColumn=end&sortDirection=${newSortDirection}`}
                    >
                      End
                    </Link>
                    {sortColumn === "end" ? (
                      sortDirection === "ASC" ? (
                        <DownIcon />
                      ) : (
                        <UpIcon />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="specs-key-low  no-border" scope="col">
                    <Link
                      className="specs-key-low clickable  no-border"
                      scope="col"
                      to={`${baseRoute}/1?sortColumn=datapoint_count&sortDirection=${newSortDirection}`}
                    >
                      Data points
                    </Link>
                    {sortColumn === "datapoint_count" ? (
                      sortDirection === "ASC" ? (
                        <DownIcon />
                      ) : (
                        <UpIcon />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                </tr>
                <tr className="form-group">
                  {/* <form onSubmit={this.handleSubmit}> */}
                  <th className="no-border" scope="col">
                    <input
                      className="form-control"
                      value={formInput.deviceName}
                      name="deviceName"
                      type="text"
                      onChange={this.handleChange}
                    />
                  </th>
                  <th className="no-border" scope="col">
                    <input
                      className="form-control"
                      value={formInput.deviceId}
                      name="deviceId"
                      type="text"
                      onChange={this.handleChange}
                    />
                  </th>
                  <th className="no-border" scope="col">
                    <input
                      className="form-control"
                      value={formInput.deviceType}
                      name="deviceType"
                      type="text"
                      onChange={this.handleChange}
                    />
                  </th>
                  <th className="no-border" scope="col">
                    <input
                      className="form-control"
                      value={formInput.sessionId}
                      name="sessionId"
                      type="text"
                      onChange={this.handleChange}
                    />
                  </th>
                  <th className="no-border" scope="col">
                    {/* <input
                      className="form-control"
                      value={formInput.start}
                      name="start"
                      type="date"
                      onChange={this.handleChange}
                    /> */}
                  </th>
                  <th className="no-border" scope="col">
                    {/* <input
                      className="form-control"
                      value={formInput.end}
                      name="end"
                      type="date"
                      onChange={this.handleChange}
                    /> */}
                  </th>
                  <th className="no-border" scope="col">
                    {/* <input
                      className="form-control"
                      value={formInput.dataPoints}
                      name="dataPoints"
                      type="text"
                      onChange={this.handleChange}
                    /> */}
                  </th>
                  {/* </form> */}
                </tr>
              </thead>
              <tbody>
                {this.props.sessionListViewData.items.map((data) => (
                  <tr key={uniqueKey()}>
                    <Td
                      to={`/${subtype}/${data.id_group}/${subpage}/${data.id_data_session}`}
                      className="specs-value"
                    >
                      {data.group.label}
                    </Td>
                    <Td
                      to={`/${subtype}/${data.id_group}/${subpage}/${data.id_data_session}`}
                      className="specs-value"
                    >
                      {data.fromDevice?.label}
                    </Td>
                    <Td
                      to={`/${subtype}/${data.id_group}/${subpage}/${data.id_data_session}`}
                      className="specs-value"
                    >
                      {data.group.subtype}
                    </Td>
                    <Td
                      to={`/${subtype}/${data.id_group}/${subpage}/${data.id_data_session}`}
                      className="specs-value"
                    >
                      {data.label}
                    </Td>
                    <Td
                      to={`/${subtype}/${data.id_group}/${subpage}/${data.id_data_session}`}
                      className="specs-value"
                    >
                      <Moment format="DD MMM YYYY hh:mm A">
                        {data.start * 1000}
                      </Moment>
                    </Td>
                    <Td
                      to={`/${subtype}/${data.id_group}/${subpage}/${data.id_data_session}`}
                      className="specs-value"
                    >
                      {data.end ? (
                        <Moment format="DD MMM YYYY hh:mm A">
                          {data.end * 1000}
                        </Moment>
                      ) : (
                        <div>
                          <FontAwesomeIcon
                            style={{ marginRight: "4px", color: "blue" }}
                            icon={faBolt}
                          />{" "}
                          active session
                        </div>
                      )}
                    </Td>
                    <Td
                      to={`/${subtype}/${data.id_group}/${subpage}/${data.id_data_session}`}
                      className="specs-value"
                    >
                      {data.datapoint_count}
                    </Td>
                  </tr>
                ))}
              </tbody>
            </table>
          </form>
          <PaginationNew />
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    sessionListViewData: state.sessionReducer.sessionListViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getSessionListViewData,
    clearSessionListViewData,
  })(SessionListCard)
);
