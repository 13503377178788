import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import DownloadIcon from "../icons/DownloadIcon";
import Moment from "react-moment";
import {
  closeSessionExport,
  exportFiles,
} from "../../actions/screen-activities";
import { showWarning, showInfo } from "../../actions/notification";
import {
  getSessionDeviceData,
  clearSessionDeviceData,
} from "../../actions/session-view";

import "./main.css";

import CONFIG from "../../config";
import { Fragment } from "react";
const { baseUrl } = CONFIG;

const initialState = {
  sessionId: null,
  downloadingFile: false,
};

class ExportSessionPopup extends Component {
  state = initialState;

  componentDidMount = () => {
    const sessionId = this.props.sessionId;
    this.props.getSessionDeviceData(sessionId);
    this.setState({
      ...this.state,
      sessionId: sessionId,
    });
  };

  // componentWillUnmount = () => {
  //   this.props.clearSessionDeviceData();
  // };

  readStream = (sessionId) => {
    //const sessionId = sessionId,

    this.props.showInfo({
      body: "Please wait, preparing raw data file.",
    });
    this.setState({
      ...this.state,
      downloadingFile: true,
    });
    // Get raw data from Azure Data Lake
    fetch(`${baseUrl}/data-session/${this.props.sessionId}/download-csv`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userToken}`,
      },
      // redirect: 'follow',
      // referrerPolicy: "no-referrer",
      // body: JSON.stringify({
      //   session_id: sessionId,
      // }),
    })
      .then((resp) => {
        if (resp.status === 200) {
          let filename = "";
          const dispositionHeader = resp.headers.get("Content-Disposition");
          if (dispositionHeader) {
            const parts = dispositionHeader.split(";");
            filename = decodeURI(
              parts[1]
                .trim()
                .slice(decodeURI(parts[1].lastIndexOf("=") + 1), -1)
            );
          } else {
            filename = "raw-data.csv";
          }
          resp
            .blob()
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
              this.setState({
                ...this.state,
                downloadingFile: false,
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                ...this.state,
                downloadingFile: false,
              });
            });
        } else {
          this.props.showWarning({
            body: "Something went wrong with raw data download. Please try again.",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div className="export-popup">
        <div className="export-popup-container">
          <h4 className="mt-3 ms-3" style={{ color: "black" }}>
            Export
          </h4>
          <div className="bacecard mx-3 my-3 p-3" style={{ color: "black" }}>
            <div className="col">
              <div>
                <span className="specs-key">Session id: </span>
                <span>{this.props.sessionLabel}</span>
              </div>
              <div>
                <span className="specs-key">Data points: </span>
                <span>{this.props.dataPoints}</span>
              </div>
              <div>
                <span className="specs-key">Start: </span>
                <span>
                  <Moment format="DD MMM YYYY hh:mm A">
                    {this.props.start * 1000}
                  </Moment>
                </span>
              </div>
              <div>
                <span className="specs-key">End: </span>
                <span>
                  <Moment format="DD MMM YYYY hh:mm A">
                    {this.props.end * 1000}
                  </Moment>
                </span>
              </div>

              <h6 className="mt-3">Format</h6>
              <div className="form-check">CSV</div>
            </div>
          </div>
          <div className="col pb-3">
            <div className="d-flex justify-content-end">
              <button
                className="btn-cancel mx-1"
                onClick={this.props.closeSessionExport}
              >
                Cancel
              </button>
              <button
                disabled={this.state.downloadingFile ? true : false}
                className="btn-export me-3 ms-1"
                onClick={() => this.readStream(this.state.sessionId)}
              >
                {this.state.downloadingFile ? (
                  <Fragment>
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    Downloading
                  </Fragment>
                ) : (
                  <Fragment>
                    <DownloadIcon color="#fff" /> Export
                  </Fragment>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.userReducer?.token?.token,
    sessionDeviceViewData: state.listViewReducer?.sessionDeviceViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    closeSessionExport,
    exportFiles,
    showWarning,
    showInfo,
    getSessionDeviceData,
    clearSessionDeviceData,
  })(ExportSessionPopup)
);
