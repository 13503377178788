import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faTimes } from "@fortawesome/pro-regular-svg-icons";

import {
  createConfigTemplate,
  getAllDeviceType,
} from "../../actions/list-view";
import "./main.css";
import { Fragment } from "react";

const initialState = {
  formInput: {
    valueCellular: "seconds",
    valueSample: "seconds",
    valueWifi: "seconds",
    name: "",
    cellular: "",
    disabled: false,
    sample: "",
    wifi: "",
    location: false,
    type: "",
    errorName: "",
    errorSample: "",
    errorCellular: "",
    errorWIFI: "",
    errorType: "",
    setDeleteButton: false,
    setCreateButton: false,
    projectId: "",
  },
};

class CreateTemplatePopup extends Component {
  state = initialState;

  componentDidMount = () => {
    const { currentPageNr, projectId } = this.props.match.params;
    console.log(this.props.match, "this.props.match created");
    const formInput = { ...this.state.formInput };
    formInput.projectId = projectId;
    this.setState({ formInput });
    this.props.getAllDeviceType();
  };
  handleNameChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.name = event.target.value;
    this.setState({ formInput });
  };
  handleDeviceTypeChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.type = event.target.value;
    this.setState({ formInput });
  };
  handleCellularSelectOption = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.valueCellular = event.target.value;
    formInput.disabled = true;
    this.setState({ formInput });
  };
  handleCellularChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.cellular = event.target.value;
    this.setState({ formInput });
  };
  handleSampleSelectOption = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.valueSample = event.target.value;
    this.setState({ formInput });
  };
  handleSampleChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.sample = event.target.value;
    this.setState({ formInput });
  };
  handleWifiSelectOption = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.valueWifi = event.target.value;
    this.setState({ formInput });
  };
  handleWifiChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.wifi = event.target.value;
    this.setState({ formInput });
  };
  handleCellularOnInput = (event) => {
    if (!event.target.validity.valid) {
      event.target.value = "";
    }
  };
  rangeValidator = (inputValue, min, max, formInput) => {
    if (formInput.valueCellular === inputValue) {
      if (formInput.cellular === "") {
        formInput.errorCellular = "This field is required";
      } else if (formInput.cellular <= min || formInput.cellular > max) {
        formInput.errorCellular = `Please enter ${inputValue} between 1 and ${max}`;
      } else {
        formInput.errorCellular = "";
      }
    }
  };
  sampleRangeValidator = (inputValue, min, max, formInput) => {
    if (formInput.valueSample === inputValue) {
      if (formInput.sample === "") {
        formInput.errorSample = "This field is required";
      } else if (formInput.sample <= min || formInput.sample > max) {
        formInput.errorSample = `Please enter ${inputValue} between 1 and ${max}`;
      } else {
        formInput.errorSample = "";
      }
    }
  };
  wifiRangeValidator = (inputValue, min, max, formInput) => {
    if (formInput.valueWifi === inputValue) {
      if (formInput.wifi === "") {
        formInput.errorWIFI = "This field is required";
      } else if (formInput.wifi <= min || formInput.wifi > max) {
        formInput.errorWIFI = `Please enter ${inputValue} between 1 and ${max}`;
      } else {
        formInput.errorWIFI = "";
      }
    }
  };
  handleLocationChange = () => {
    const formInput = { ...this.state.formInput };
    formInput.location = !formInput.location;
    this.setState({ formInput });
  };
  handleSubmit(event) {
    const formInput = { ...this.state.formInput };
    event.preventDefault();

    formInput.type = formInput.type;
    if (formInput.name === "") {
      formInput.errorName = "This field is required";
    }
    if (formInput.type === "") {
      formInput.errorType = "This field is required";
    }
    this.rangeValidator("seconds", 0, 86400, formInput);
    // this.rangeValidator("hours", 0, 24, formInput);

    this.sampleRangeValidator("seconds", 0, 86400, formInput);
    this.wifiRangeValidator("seconds", 0, 86400, formInput);
    // this.sampleRangeValidator("minutes", 0, 1400, formInput);
    // this.sampleRangeValidator("hours", 0, 24, formInput);

    // this.wifiRangeValidator("minutes", 0, 1400, formInput);
    // this.wifiRangeValidator("hours", 0, 24, formInput);

    // //convert Cellular value to Miliseconds
    // if (formInput.valueCellular === "minutes") {
    //   formInput.cellular = formInput.cellular * 60000;
    // } else if (formInput.valueCellular === "hours") {
    //   formInput.cellular = formInput.cellular * 3600000;
    // }
    // //convert Wifi value to Miliseconds
    // if (formInput.valueWifi === "minutes") {
    //   formInput.wifi = formInput.wifi * 60000;
    // } else if (formInput.valueWifi === "hours") {
    //   formInput.wifi = formInput.wifi * 3600000;
    // }
    // //convert Sample value to Miliseconds
    // if (formInput.valueSample === "seconds") {
    //   formInput.sample = formInput.sample * 1000;
    // } else if (formInput.valueSample === "minutes") {
    //   formInput.sample = formInput.sample * 60000;
    // } else if (formInput.valueSample === "hours") {
    //   formInput.sample = formInput.sample * 3600000;
    // }
    // convert location value to 0 & 1
    if (formInput.location) {
      formInput.location = 1;
    } else {
      formInput.location = 0;
    }

    if (
      formInput.errorName === "" &&
      formInput.errorCellular === "" &&
      formInput.errorSample === "" &&
      formInput.errorType === "" &&
      formInput.errorWIFI === ""
    ) {
      this.props.createConfigTemplate(formInput);
      formInput.setCreateButton = true;
      this.props.popupScreen();
    }
    this.setState(formInput);
  }
  render() {
    const formInput = this.state;
    const { location } = this.state.formInput;
    return (
      <div className="main-popup">
         
        {/* {this.props.createdConfigById === "pass" ? (
          <div>
            <div onClick={this.handleToConnectionClose}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.props.popupScreen()}
              />
            </div>
            <div className="popup-container">Created Sucessfully</div>
          </div>
        ) : ( */}
        <div className="popup-container createTemplatePopup">
        <div style={{justifyItems: "right",display:"grid"}}>
        <FontAwesomeIcon
                style={{fontSize:"30px",marginTop:"10px",marginRight:"16px"}}
                className=" wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.props.handleToClose}
              />
              </div>
      
          <h4 className="text-center createTemplate paddingRight" style={{paddingTop:"40px"}}>Create a New Template</h4>
          
          <div className="mb-3 mt-5 paddingRight">
            <div className="d-flex" style={{ columnGap: "20px" }}>
              {/*<div className="d-flex" style={{ columnGap: "20px" }}>
                <label className="col font-weight-bold">Template name:</label>{" "}
                <input
                  type="text"
                  style={{ width: "9rem" }}
                  placeholder="Bace default"
                  onChange={this.handleNameChange.bind(this)}
                />
      </div> 
      <div className="d-flex form-group" style={{ columnGap: "20px" }}>
                <label className="col font-weight-bold ">Device Type:</label>
                <select
                  id="1"
                  classNamePrefix="mySelect "
                  style={{ width: "10rem" }}
                  className="custom-select form-select mr-sm-4"
                  onChange={this.handleDeviceTypeChange.bind(this)}
                  defaultValue={formInput.type}
                >
                  <option defaultValue="Select" selected>
                    choose...
                  </option>
                  {this.props.allDeviceTypes?.items?.map((option, index) => (
                    <option
                      className="text-capitalize"
                      key={index}
                      defaultValue={option.id_device_type}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
      */}
              
                <div className="row">
                  <div class="col-md-6">
                  <div className="form-group">
                  <label className="col font-weight-bold">Template Name</label>{" "}
               <input type="text" className="form-controlNew templateNameBorder margin-top" style={{height:"40px"}} placeholder="BACE default" onChange={this.handleNameChange.bind(this)}/>
              {/*<small  className="form-text text-muted">Form helper text.</small>*/}
              </div>
                    </div>
                  <div class="col-md-6">
                  <div className="form-group">
              <label className="col font-weight-bold">Device Type</label>{" "}
              <select id="1"
                   classNamePrefix="mySelect"
                   className="custom-select mr-sm-4 form-controlNew selectBorder dropDownDeviceName margin-top"
                   onChange={this.handleDeviceTypeChange.bind(this)}
                   defaultValue={formInput.type}
                 >
                   <option defaultValue="Select" selected>
                     Select
                   </option>
                   {this.props.allDeviceTypes?.items?.map((option, index) => (
                     <option
                       className="text-capitalize"
                       key={index}
                       defaultValue={option.id_device_type}
                     >
                       {option.label}
                     </option>
                   ))}
                 </select>
            </div>

                  </div>
                </div>
              
             
              
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <p className="text-danger">{formInput.errorName}</p>
              <p className="text-danger" style={{ marginRight: "160px" }}>
                {formInput.errorType}
              </p>
            </div>
            <div className="mb-3"></div>
            <div className="parent">
             <div className="form-group">
                <h6 className="mb-3  font-weight-bold">
                  Connection Setting
                </h6>
                <div className="form-group">
                  
                  <label>Interval Cellular</label>
                  <div className="row">
                    <div className="col-md-6">
                  <input
                    id="1"
                    className="form-controlSec margin-top"
                    type="number"
                    placeholder="60"
                    onChange={this.handleCellularChange.bind(this)}
                    min="0"
                    // onInput="validity.valid||(value='');"
                    onInput={this.handleInput}
                    pattern="[^0-9]"
                    // disabled={formInput.disabled}
                  />{" "}
                  </div>
                  <div className="col-md-4">
                  <div className="intervalRange">Seconds</div>
                  </div>
                  <div className="col-md-2"></div>
                  </div>
                  
                </div> 

                <p className="text-danger">{formInput.errorCellular}</p>
              </div> 
              <div>
                <h6 className="mb-3 font-weight-bold">
                  <span >Consent</span>
                  <div className="tooltipSetting" style={{top:"3px"}}>
                    <FontAwesomeIcon
                      className="fa-1x questionIcon"
                      icon={faCircleQuestion}
                    />
                    <span className="tooltipsSettingText tooltipsTemplate">
                      <div>
                        Enabling location will allow device to sends GPS
                        coordinates to you as a measurement.
                      </div>
                    </span>
                  </div>
                </h6>
                <div className="mt-1 form-group d-flex  col-md-2x" >
                  <div >Location &nbsp;</div>
                  <div >: &nbsp;&nbsp;</div>
                  <div className="form-check form-switch" >
                  <label
                    
                    className="form-check-label"
                    htmlFor="flexSwitchCheckChecked"
                  >
                    {location ? "On" : "Off"}
                  </label>
                  <input
                   
                   className="form-check-input"
                   type="checkbox"
                   onChange={this.handleLocationChange}
                 />
                   
                    
                  </div>
                </div>
              </div>

              <div>
                <h6 className="mb-3 font-weight-bold">
                  Sample Rate
                </h6>
                <div className="form-group">
                  

                <label >Sample Rate</label>
                <div className="row">
                <div className="col-md-6">
                  <input
                    id="2"
                    className="form-controlSec margin-top"
                    type="number"
                    min="0"
                    placeholder="60"
                    onChange={this.handleSampleChange.bind(this)}
                  />
                </div>
                <div className="col-md-2">
                  
                  
                  <div className="intervalRange">Seconds</div>
                  </div>
                  <div className="col-md-4"></div>
                  </div>
                </div>
                <p className="text-danger">{formInput.errorSample}</p>
              </div>
              <div style={{marginTop:"10px"}}>
                <div className="form-group">
                  <label>Interval WIFI/Ethernet</label>
                  <div className="row">
                    <div className="col-md-6">
                    
                  
                  <input
                    id="3"
                    className="form-controlSec margin-top"
                    type="number"
                    min="0"
                    placeholder="60"
                    onChange={this.handleWifiChange.bind(this)}
                  />
                  </div>
                  <div className="col-md-4">
                  <div className="intervalRange">Seconds</div>
                  </div>
                  <div className="col-md-2"></div>
                </div>
                </div>
                <p className="text-danger">{formInput.errorWIFI}</p>
              </div>
            </div>
            {/* </table> */}
          </div>
          <div className="col paddingRight">
            <div className="row">
              <div className="col-md-4">
              <button
                className="cancelbuttonPopupCreate mx-1"
                onClick={this.props.handleToClose}
              >
                Cancel
              </button>
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-4">
              <div className="d-flex justify-content-end">
             
             <button style={{padding:"5px 10px",marginTop:"10px"}}
               type="submit"
               onClick={this.handleSubmit.bind(this)}
               className={
                 formInput.setCreateButton
                   ? "cancelbuttonPopupCreate submitDisableButton"
                   : "cancelbuttonPopupCreate submitButton"
               }
               //  className="btn-export me-3 ms-1"
             >
               <Fragment>Create</Fragment>
             </button>
           </div>
              </div>
            </div>
            
            
            
            
          </div>
        </div>
        {/* )} */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listConfigViewData: state.listViewReducer.listConfigViewData,
    allDeviceTypes: state.listViewReducer.allDeviceTypes,
    sessionDeviceViewData: state.listViewReducer?.sessionDeviceViewData,
    createdConfigById: state.listViewReducer?.createdConfigById,
  };
}

export default withRouter(
  connect(mapStateToProps, { createConfigTemplate, getAllDeviceType })(
    CreateTemplatePopup
  )
);
