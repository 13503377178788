import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faServer,
  faHardDrive,
  faGear,
  faFolderGear,
  faRectangleHistory
} from "@fortawesome/pro-regular-svg-icons";
import ModbusIcon from "../../../assets/Icons/layout-icon-modbus.svg";
import ModbusIconWhite from "../../../assets/Icons/modbusIconLeftMenu.png";
import "../style.css";
import { Link } from "react-router-dom";
import ProjectMoveIcon from "../../../assets/Icons/projectImg.png";

import IntegrationIcon from "../../../assets/Icons/integration_small.png";

class MenuItems extends Component {
  state = {
    formInput: {
      projectId: "",
    },
  };
  handleToChangeProject = () => {
    this.props.clearProject();
    return this.props.history.push("/");
  };
  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.projectId;
    // if (this.props.subtypes) {
    return (
      <Fragment>
        <div className="menu-items-container ">
          <div className="project-container-frame">
            <div className="project-container-name">
          <button
            onClick={() => this.handleToChangeProject()}
            className="projectButtonSwitch projectShadow"
          >
            {" "}
            <span className="projectShadowTextColor">{this.props.projectLabel}</span>
            <span class="projectIcon">
            <FontAwesomeIcon icon={faRectangleHistory}  class="projectIcon" />
            </span>
          </button>
          </div>
          </div>
          {/* BACE EP */}
          {/* {this.props.subtypes.includes("bacedashboard") && ( */}
          <div className="leftMenucontainer">
          
            <span
              className={
                this.props.routeStart.startsWith("dashboard")
                  ? "active-left-menu-link-icon-shadow"
                  : ""
              }
            >
              <span>
                <Link
                  to={`/dashboard/home/${formInput.projectId}`}
                  className={`
                        ${
                          this.props.routeStart.startsWith("dashboard")
                            ? "no-underscore left-menu-link-active"
                            : "no-underscore inactive-left-menu-link-icon-shadow"
                        }
                          `}
                >
                  <span class="menuleftcolor">
                  <FontAwesomeIcon
                    style={{ marginRight: "8px" }}
                    className="fa-lg"
                    icon={faHouse}
                  />
                  Home
                  </span>
                </Link>
              </span>
            </span>
          
          {/* )} */}

          {/* {this.props.subtypes.includes("bacedashboard") && ( */}
          <div className="">
            <span
              className={
                this.props.routeStart.startsWith("bace-ep")
                  ? "active-left-menu-link-icon-shadow"
                  : ""
              }
            >
              <span>
                <Link
                  to={`/bace-ep/card-view/1/${formInput.projectId}`}
                  //    to="/bace-ep/card-view/${this.props.projectId}"
                  className={
                    this.props.routeStart.startsWith("bace-ep")
                      ? "no-underscore left-menu-link-active"
                      : "no-underscore inactive-left-menu-link-icon-shadow"
                  }
                >
                  <span class="menuleftcolor">
                  <FontAwesomeIcon
                    style={{ marginRight: "8px" }}
                    className="fa-lg"
                    icon={faHardDrive}
                  />
                  Gateways
                  </span>
                </Link>
              </span>
            </span>
          </div>
          {/* )} */}
          {/* modbus gateways */}
          {/* {this.props.subtypes.includes("bacedashboard") && ( */}
          <div className="">
            <span
              className={
                this.props.routeStart.startsWith("modbus-gateways")
                  ? "active-left-menu-link-icon-shadow"
                  : ""
              }
            >
              
              <Link style={{color:"white"}}
                to={`/modbus-gateways/card-view/1/${formInput.projectId}`}
                className={
                  this.props.routeStart.startsWith("modbus-gateways")
                    ? "no-underscore left-menu-link-active"
                    : "no-underscore inactive-left-menu-link-icon-shadow"
                }
              >
                <span class="menuleftcolor">
                <img
                  src={
                    this.props.routeStart.startsWith("modbus-gateways")
                      ? ModbusIconWhite
                      : ModbusIconWhite
                  }
                  height={26}
                  width={26}
                  style={{
                    marginRight: "8px",
                    marginLeft: "-4px",
                    color: "ccean",
                  }}
                />
                Modbus Devices
                </span>
              </Link>
            </span>
          </div>
        

        <div className="">
          <span
            className={
              this.props.routeStart.startsWith("settings")
                ? "active-left-menu-link-icon-shadow"
                : ""
            }
          >
            <Link
              to={`/settings/info-view/1/${formInput.projectId}`}
              className={
                this.props.routeStart.startsWith("settings")
                  ? "no-underscore left-menu-link-active"
                  : "no-underscore inactive-left-menu-link-icon-shadow"
              }
            >
              <span class="menuleftcolor">
              <FontAwesomeIcon
                style={{ marginRight: "8px" }}
                className="fa-lg"
                icon={faGear}
              />
              Settings
              </span>
            </Link>
          </span>
        </div>

        <div className="">
          <span
            className={
              this.props.routeStart.startsWith("integration") ||
              this.props.routeStart.startsWith("webhook")
                ? "active-left-menu-link-icon-shadow"
                : ""
            }
          >
            <Link
              to={`/integration/1/${formInput.projectId}`}
              className={
                this.props.routeStart.startsWith("integration") ||
                this.props.routeStart.startsWith("webhook")
                  ? "no-underscore left-menu-link-active"
                  : "no-underscore inactive-left-menu-link-icon-shadow"
              }
            >
              <span class="menuleftcolor">
              <FontAwesomeIcon
                style={{ marginRight: "8px" }}
                className="fa-lg"
                icon={faFolderGear}
              />
              Integration
              </span>
            </Link>
          </span>
        </div>
        </div>
        </div>    
      </Fragment>
      
    );
    // } else {
    //   return <div></div>;
    // }
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer,
    routeStart: state.screenActivitiesReducer?.routeStart,
    subtypes: state.listViewReducer?.subtypes,
  };
}

export default withRouter(connect(mapStateToProps)(MenuItems));
