import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck, faXmark } from "@fortawesome/pro-light-svg-icons";
import { updateConfigTemplate } from "../../actions/list-view";
import "./main.css";
import { Fragment } from "react";
import circlecheck from "./../../assets/Icons/circle-check.png";
import StatusPopup from "../../components/popups/StatusPopup";
import StatusPopupEdit from "./StatusPopUpDelete";


const initialState = {
  formInput: {
    valueCellular: "seconds",
    valueSample: "seconds",
    valueWifi: "seconds",
    name: "",
    default: "",
    cellular: "",
    disabled: false,
    sample: "",
    type: "",
    wifi: "",
    location: false,
    errorName: "",
    errorSample: "",
    errorCellular: "",
    errorWIFI: "",
    errorType: "",
    dataCellular: "",
    dataWifi: "",
    dataSample: "",
    editWifi: false,
    editSample: false,
    editCellular: false,
    projectId: "",
  },
};

class EditTemplatePopup extends Component {
  state = initialState;

  handleNameChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.name = event.target.value;
    this.setState({ formInput });
  };
  handleDeviceTypeChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.type = event.target.value;
    this.setState({ formInput });
    console.log(formInput);
  };
  handleCellularSelectOption = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.valueCellular = event.target.value;
    formInput.disabled = true;
    this.setState({ formInput });
  };
  handleCellularChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.cellular = event.target.value;
    formInput.editCellular = true;
    this.setState({ formInput });
  };
  handleSampleSelectOption = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.valueSample = event.target.value;
    this.setState({ formInput });
  };
  handleSampleChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.sample = event.target.value;
    formInput.editSample = true;
    this.setState({ formInput });
  };
  handleWifiSelectOption = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.valueWifi = event.target.value;
    this.setState({ formInput });
  };
  handleWifiChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.editWifi = true;
    formInput.wifi = event.target.value;
    this.setState({ formInput });
  };

  rangeValidator = (inputValue, min, max, formInput) => {
    if (formInput.valueCellular === inputValue) {
      if (formInput.cellular === "") {
        formInput.errorCellular = "This field is required";
      } else if (formInput.cellular <= min || formInput.cellular > max) {
        formInput.errorCellular = `Please enter ${inputValue} between 1 and ${max}`;
      } else {
        formInput.errorCellular = "";
      }
    }
  };
  sampleRangeValidator = (inputValue, min, max, formInput) => {
    if (formInput.valueSample === inputValue) {
      if (formInput.sample === "") {
        formInput.errorSample = "This field is required";
      } else if (formInput.sample <= min || formInput.sample > max) {
        formInput.errorSample = `Please enter ${inputValue} between 1 and ${max}`;
      } else {
        formInput.errorSample = "";
      }
    }
  };
  wifiRangeValidator = (inputValue, min, max, formInput) => {
    if (formInput.valueWifi === inputValue) {
      if (formInput.wifi === "") {
        formInput.errorWIFI = "This field is required";
      } else if (formInput.wifi <= min || formInput.wifi > max) {
        formInput.errorWIFI = `Please enter ${inputValue} between 1 and ${max}`;
      } else {
        formInput.errorWIFI = "";
      }
    }
  };
  handleLocationChange = () => {
    const formInput = { ...this.state.formInput };
    formInput.location = !formInput.location;
    this.setState({ formInput });
  };
  handleSubmit(event) {
    const formInput = { ...this.state.formInput };
    event.preventDefault();
    formInput.type = formInput.type;
    if (formInput.name === "") {
      formInput.errorName = "This field is required";
    }
    {/* if (formInput.type === "") {
      formInput.errorType = "This field is required";
    } */}
    formInput.type=this.props.editViewDetails?.deviceConfigPresets[0]?.id_device_type;
    formInput.default = this.props.label;
    formInput.dataCellular =
      this.props.editViewDetails?.deviceConfigPresets[0]?.id_device_config_preset;
    formInput.dataWifi =
      this.props.editViewDetails?.deviceConfigPresets[1]?.id_device_config_preset;
    formInput.dataSample =
      this.props.editViewDetails?.deviceConfigPresets[2]?.id_device_config_preset;
    formInput.dataLocation =
      this.props.editViewDetails?.deviceConfigPresets[3]?.id_device_config_preset;
    formInput.name = formInput.name !== "" ? formInput.name : this.props.label;
    
    if (formInput.name === null) {
      formInput.errorName = "This field is required";
    }

    //formInput.type =
      //this.props.editViewDetails?.deviceConfigPresets[0]?.id_device_type;

    // formInput.cellular =
    //   formInput.cellular === ""
    //     ? (this.props.editViewDetails?.deviceConfigPresets[0]?.desired_value *
    //         0.001) /
    //       60
    //     : formInput.cellular;

    // formInput.sample =
    //   formInput.sample === ""
    //     ? (this.props.editViewDetails?.deviceConfigPresets[2]?.desired_value *
    //         0.001) /
    //       60
    //     : formInput.sample;

    // formInput.wifi =
    //   formInput.wifi === ""
    //     ? (this.props.editViewDetails?.deviceConfigPresets[2].desired_value *
    //         0.001) /
    //       60
    //     : formInput.wifi;

    this.rangeValidator("seconds", 0, 86400, formInput);
    this.sampleRangeValidator("seconds", 0, 86400, formInput);
    this.wifiRangeValidator("seconds", 0, 86400, formInput);
    // this.sampleRangeValidator("minutes", 0, 1400, formInput);
    // this.sampleRangeValidator("hours", 0, 24, formInput);

    // this.wifiRangeValidator("hours", 0, 24, formInput);
    // this.rangeValidator("hours", 0, 24, formInput);

    //convert Cellular value to Miliseconds
    // if (formInput.valueCellular === "minutes") {
    //   formInput.cellular = formInput.cellular * 60000;
    // } else if (formInput.valueCellular === "hours") {
    //   formInput.cellular = formInput.cellular * 3600000;
    // }
    // //convert Wifi value to Miliseconds
    // if (formInput.valueWifi === "minutes") {
    //   formInput.wifi = formInput.wifi * 60000;
    // } else if (formInput.valueWifi === "hours") {
    //   formInput.wifi = formInput.wifi * 3600000;
    // }
    // //convert Sample value to Miliseconds
    // if (formInput.valueSample === "seconds") {
    //   formInput.sample = formInput.sample * 1000;
    // } else if (formInput.valueSample === "minutes") {
    //   formInput.sample = formInput.sample * 60000;
    // } else if (formInput.valueSample === "hours") {
    //   formInput.sample = formInput.sample * 3600000;
    // }
    // convert location value to 0 & 1
    if (formInput.location) {
      formInput.location = 1;
    } else {
      formInput.location = 0;
    }

    if (formInput.editWifi === false) {
      formInput.wifi =
        this.props.editViewDetails?.deviceConfigPresets[1]?.desired_value;
    }
    if (formInput.editSample === false) {
      formInput.sample =
        this.props.editViewDetails?.deviceConfigPresets[2]?.desired_value;
    }
    if (formInput.editCellular === false) {
      formInput.cellular =
        this.props.editViewDetails?.deviceConfigPresets[0]?.desired_value;
    }
    if (
      formInput.errorName === "" &&
      formInput.errorCellular === "" &&
      formInput.errorSample === "" &&
      formInput.errorWIFI === ""
    ) {
      this.props.updateConfigTemplate(
        this.props.templateId,
        formInput,
        this.props.projectId
      );
      formInput.setCreateButton = true;
    }
    this.setState(formInput);
  }
  render() {
    const formInput = this.state;
    const { location } = this.state.formInput;
    return (
      <div  >
        {this.props.updatedConfigById === "pass" ? (
          <div>
            {" "}
            {/*<div onClick={this.handleToConnectionClose}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.props.closeEditPopup}
              />
        </div>*/}
            {/* <div className="popup-container editTemplatePopUp" >
            <div style={{justifyItems: "right",display:"grid"}}>
        <FontAwesomeIcon
                style={{fontSize:"30px",marginTop:"10px",marginRight:"16px"}}
                className="wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.props.closeEditPopup}
              />
              </div>
              <div className="justify-content-center d-flex paddingRight">
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="fa-settingsHeaderIcon"
              style={{ width: "35px", height: "35px",color:"green" }}
            ></FontAwesomeIcon>
          </div>
              <h5 class="font-weight-bold mb-3 mt-3 mr-5 ml-5 paddingRight">
      Editing complete! </h5></div>  */}
      <StatusPopupEdit
      closePopup={this.props.closeEditPopup}
      message="Editing complete! "
      className="online"
      icon={faCircleCheck}
        
      />
    
          </div>
        ) : (
          <div className="main-popup-edit">
          <div className="popup-container editTemplatePopUp" >
            <div style={{justifyItems: "right",display:"grid"}}>
        <FontAwesomeIcon
                style={{fontSize:"30px",marginTop:"10px",marginRight:"16px"}}
                className="wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.props.closeEditPopup}
              />
              
              </div>
              
            
            <h5 className="text-center  paddingRight" style={{paddingTop:"40px"}}>
              Edit{"  "}
              <span style={{ fontWeight: "700" }}>{this.props.label}</span>{" "}
              Configuration Template
            </h5>
            <div className="mb-3 mt-5 paddingRight">
              <div  >
                <div >
                {/*<div className="form-group">
                  <label className="col font-weight-bold">Template name:</label>{" "}
                  <input
                    type="text"
                    className="form-control"
                    style={{ width: "9rem" }} 
                    placeholder="Bace default"
                    defaultValue={this.props.label}
                    onChange={this.handleNameChange.bind(this)}
                  />
                  </div> */}


                <div className="row">
                  <div class="col-md-6" >
                  <div className="form-group">
                  <label className="col font-weight-bold" >Template Name</label>{" "}
               <input type="text" className="form-controlNew templateNameBorder margin-top"  
               placeholder="BACE default" defaultValue={this.props.label} onChange={this.handleNameChange.bind(this)}/>
              {/*<small  className="form-text text-muted">Form helper text.</small>*/}
              </div>
                    </div>
                    <div class="col-md-6" style={{textAlign:"center"}}>
                  <div className="form-group">
              <label className="col font-weight-bold" >Device Type</label>{" "}
              <div className="margin-top">   {this.props.editViewDetails?.deviceConfigPresets[0]?.id_device_type}
              </div>{" "}
            </div>
           </div>
             </div>
                </div>
              </div>
              <div className="mt-2 d-flex justify-content-between">
                <p className="text-danger">{formInput.errorName}</p>
                 <p className="text-danger" style={{ marginRight: "160px" }}>
                  {formInput.errorType} </p> 
              </div>
              <div className="mb-3"></div>
              <div className="parent">
                <div className="form-group">
                  <h6 className="mb-3  font-weight-bold">
                    Connection Setting
                  </h6>

                  <div className="form-group">
                    <label >Interval Cellular</label>
                    <div className="row">
                      <div className="col-md-6">
                      <input
                      id="1"
                      className="form-controlSec margin-top"
                      type="number"
                      placeholder="60"
                      onChange={this.handleCellularChange.bind(this)}
                      min="0"
                      defaultValue={
                        this.props.editViewDetails?.deviceConfigPresets ===
                        undefined
                          ? undefined
                          : this.props.editViewDetails?.deviceConfigPresets[0]
                              ?.desired_value * 0.001
                      }
                      // disabled={formInput.disabled}
                    />
                      </div>
                      <div className="col-md-4">
                      <div className="intervalRange">Seconds</div>
                      </div>
                      <div className="col-md-2"></div>
                      </div>
                    
                    
                  </div>
                  <p className="text-danger">{formInput.errorCellular}</p>
                </div>
                <div>
                  <h6 className="mb-3  font-weight-bold">
                    <span>Consent</span>
                    {/* <FontAwesomeIcon
                    icon={faCircleQuestion}
                    className="svg-icons-sm fa-sm"
                  /> */}
                    <div className="tooltipSetting" style={{top:"3px"}}>
                      <FontAwesomeIcon
                        className="fa-1x questionIcon"
                        icon={faCircleQuestion}
                      />
                      <span className="tooltipsSettingText tooltipsTemplate">
                        <div>
                          Enabling location will allow device to sends GPS
                          coordinates to you as a measurement.
                        </div>
                      </span>
                    </div>
                  </h6>
                  <div className="mt-1 form-group d-flex  col-md-2x">
                    <div >Location &nbsp;</div>
                    <div >: &nbsp;&nbsp;</div>
                    <div className="form-check form-switch" >
                      <label 
                        className="form-check-label"
                        htmlFor="flexSwitchCheckChecked"
                      >
                        {location ? "On" : "Off"}
                      </label>
                      <input
                        className="form-check-input margin-top"
                        type="checkbox"
                        onChange={this.handleLocationChange}
                        defaultChecked={
                          this.props.editViewDetails?.deviceConfigPresets ===
                          undefined
                            ? undefined
                            : this.props.editViewDetails?.deviceConfigPresets[3]
                                ?.desired_value === "1"
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <h6 className="mb-3  font-weight-bold">
                    Sample Rate
                  </h6>
                  <div className="form-group">
                    <label >Sample Rate</label>
                    <div className="row" >
                      <div class="col-md-6">
                      <input
                      id="2"
                      className="form-controlSec margin-top"
                      type="number"
                      min="0"
                      placeholder="60"
                      //  value={formInput.dataSample}
                      defaultValue={
                        this.props.editViewDetails?.deviceConfigPresets ===
                        undefined
                          ? undefined
                          : this.props.editViewDetails?.deviceConfigPresets[2]
                              ?.desired_value * 0.001
                      }
                      onChange={this.handleSampleChange.bind(this)}
                    />
                      </div>
                      <div class="col-md-4">
                      <div className="intervalRange">Seconds</div>
                      </div>
                      <div class="col-md-2"></div>
                    </div>
                    
                    
                  </div>
                  <p className="text-danger">{formInput.errorSample}</p>
                </div>
                <div>
                  <div className="form-group" >
                    <label >Interval WIFI/Ethernet</label>
                    <div class="row">
                      <div class="col-md-6">
                      <input
                      id="3"
                      className="form-controlSec margin-top"
                      type="number"
                      min="0"
                      placeholder="60"
                      onChange={this.handleWifiChange.bind(this)}
                      //value={formInput.datawifi}
                      defaultValue={
                        this.props.editViewDetails?.deviceConfigPresets ===
                        undefined
                          ? undefined
                          : this.props.editViewDetails?.deviceConfigPresets[1]
                              ?.desired_value * 0.001
                      }
                    />
                      </div>
                      <div class="col-md-4">

                      <div className="intervalRange">Seconds</div>
                      </div>
                      <div class="col-md-2"></div>
                    </div>
                   
                    
                  </div>
                  <p className="text-danger">{formInput.errorWIFI}</p>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="row">
                <div className="col-md-3">
                <button
                  className="cancelbutton mx-1"
                  onClick={this.props.closeEditPopup}
                >
                  Cancel
                </button>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                <button style={{padding:"10px 10px",marginTop:"5px"}}
                  type="submit"
                  onClick={this.handleSubmit.bind(this)}
                  className={
                    formInput.setCreateButton
                      ? "cancelbutton submitDisableButton"
                      : "cancelbutton submitButton"
                  }
                >
                  <Fragment>Update</Fragment>
                </button>
                </div>
               
                
              </div>
            </div>
          </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.userReducer?.token?.token,
    allDeviceTypes: state.listViewReducer.allDeviceTypes, 
    sessionDeviceViewData: state.listViewReducer?.sessionDeviceViewData,
    updatedConfigById: state.listViewReducer?.updatedConfigById,
  };
}
export default withRouter(
  connect(mapStateToProps, { updateConfigTemplate })(EditTemplatePopup)
);
