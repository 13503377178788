import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import { openSessionExport } from "../../../../actions/screen-activities";

import { faBolt } from "@fortawesome/free-solid-svg-icons";
import DownloadIcon from "../../../icons/DownloadIcon";
import ExportSessionPopup from "../../../popups/ExportSessionPopup";

const initialState = {
  sessionId: null,
  id: null,
};

class OneSessionSubmenu extends Component {
  state = initialState;
  componentDidMount = () => {
    const { id, sessionId } = this.props.match.params;
    if (!sessionId) {
      this.props.history.push("/");
    }

    this.setState({
      id,
      sessionId,
    });
  };

  render() {
    //console.log("session data: ", this.props.sessionDeviceViewData);
    if (this.props.sessionDeviceViewData) {
      return (
        <div className="row sticky-submenu">
          {this.props.exportSessionPopup ? (
            <ExportSessionPopup
              sessionId={this.state.sessionId}
              sessionLabel={this.props.sessionDeviceViewData.label}
              dataPoints={this.props.sessionDeviceViewData.datapoint_count}
              start={this.props.sessionDeviceViewData.start}
              end={this.props.sessionDeviceViewData.end}
            />
          ) : (
            ""
          )}
          <div className="col pl-4 ">
            <h1 className="page-title">
              <Link to="/sessions/list-view/1" className="link-unstyled">
                Sessions
              </Link>
              {this.props.sessionDeviceViewData ? (
                <span className="page-title-bold">
                  {" "}
                  | {this.props.sessionDeviceViewData.group.label}
                </span>
              ) : (
                <div></div>
              )}
            </h1>
            <div>
              <Link
                className={` ${
                  this.props.location.pathname ===
                  `/sessions/${this.state.id}/measurement-data/${this.state.sessionId}`
                    ? "bacelink-active"
                    : "bacelink"
                }`}
                to={`/sessions/${this.state.id}/measurement-data/${this.state.sessionId}`}
              >
                <FontAwesomeIcon
                  style={{ cursor: "pointer", marginRight: "4px" }}
                  icon={faChartLine}
                />
                Measurement data
              </Link>
              {/* <div className="switch-btn-line"></div> */}
              <div style={{ backgroundColor: "#DCDCDC" }}>
                <div className="row pt-3 px-3">
                  <div className="col-auto">
                    <p className="submenu-specs-key">Device Name</p>
                    <p className="submenu-specs-value">
                      {this.props.sessionDeviceViewData?.fromDevice?.label}
                    </p>
                  </div>
                  <div className="col-auto">
                    <p className="submenu-specs-key">Start</p>
                    <p className="submenu-specs-value">
                      <Moment format="DD MMM YYYY hh:mm">
                        {this.props.sessionDeviceViewData.start * 1000}
                      </Moment>
                    </p>
                  </div>
                  <div className="col-auto">
                    <p className="submenu-specs-key">End</p>
                    <p className="submenu-specs-value">
                      {this.props.sessionDeviceViewData.end ? (
                        <Moment format="DD MMM YYYY hh:mm">
                          {this.props.sessionDeviceViewData.end * 1000}
                        </Moment>
                      ) : (
                        <div>
                          <FontAwesomeIcon
                            style={{ marginRight: "4px", color: "blue" }}
                            icon={faBolt}
                          />{" "}
                          active session
                        </div>
                      )}
                    </p>
                  </div>
                  <div className="col-auto">
                    <p className="submenu-specs-key">Data points</p>
                    <p className="submenu-specs-value">
                      {this.props.sessionDeviceViewData.datapoint_count}
                    </p>
                  </div>
                  <div className="col-auto">
                    <p className="submenu-specs-key">Session id</p>
                    <p className="submenu-specs-value">
                      {this.props.sessionDeviceViewData.label}
                    </p>
                  </div>

                  <div className="col-auto ms-auto align-items-center">
                    <button
                      className={true ? "d-block bacelink" : "d-none"}
                      onClick={this.props.openSessionExport}
                    >
                      <DownloadIcon /> Export
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    //deviceViewData: state.listViewReducer.deviceViewData,
    sessionDeviceViewData: state.sessionReducer.sessionDeviceViewData,
    exportSessionPopup: state.screenActivitiesReducer?.exportSessionPopup,
  };
}

export default withRouter(
  connect(mapStateToProps, { openSessionExport })(OneSessionSubmenu)
);
