import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Moment from "react-moment";
import LoaderRoller from "../../../components/loader/LoaderRoller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBrowser,
  faListUl,
  faSliders,
  faChartLine,
  faRefresh,
} from "@fortawesome/pro-regular-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  getModbusDeviceInfo,
  clearModbusDeviceData,
} from "../../../actions/modbus-view";
import OneModbusGatewaySubmenu from "../../menus/sub-menu/subtypes/OneModbusGatewaySubmenu";

class ModbusDeviceCardInfo extends Component {
  state = {
    formInput: {
      cardRegister: true,
      listRegister: false,
      refresh: false,
    },
  };
  componentDidMount = () => {};

  componentWillUnmount = () => {
    //if (!window.location.pathname.startsWith("/modbus-gateways/card-view")) {
    this.props.clearModbusDeviceData();
    // }
  };

  cardViewRegister = () => {
    const formInput = { ...this.state.formInput };
    formInput.cardRegister = true;
    formInput.listRegister = false;
    this.setState({ formInput });
  };

  listViewRegister = () => {
    const formInput = { ...this.state.formInput };
    formInput.cardRegister = false;
    formInput.listRegister = true;
    this.setState({ formInput });
  };

  handleToRefresh = () => {
    const { id } = this.props.match.params;
    if (!id) {
      return this.props.history.push("/");
    }
    const physicalDeviceId = this.props.match.params;
    this.props.getModbusDeviceInfo(physicalDeviceId);
    const formInput = { ...this.state.formInput };
    formInput.refresh = true;
    this.setState({ formInput });
    setTimeout(() => {
      const formInput = { ...this.state.formInput };
      formInput.refresh = false;
      this.setState({ formInput });
    }, 1000);
    console.log("5555", { ...this.state.formInput });
  };

  render() {
    const today = new Date().toISOString().split("T")[0] + "T00:00";
    const templateRegisterDataList = this.props.allModbusDevices?.items.filter(
      (i) => i.id_physical_device === this.props.allModbusDeviceInfo?.id_parent
    )[0]?.currentModbusTemplate?.registers;
    const numberRegister = this.props.allModbusDeviceInfo?.latestData.map(
      (item, i) =>
        templateRegisterDataList?.find(
          (elem) => elem.value_id === item.datatype
        ) !== undefined
    );
    const formInput = { ...this.state.formInput };
    console.log("ghhhhh", { ...this.state.formInput });
    return (
      <div className="row">
        {console.log(this.props.allModbusDeviceInfoData, "00000000")}
        {formInput.refresh && this.props.allModbusDeviceInfoData ? (
          <Fragment>
            <div className="baceconainer">
              <div className="row mt-3">
                <LoaderRoller />
              </div>
            </div>
          </Fragment>
        ) : (
          <div>
            {" "}
            <div className="col-lg-5">
              <div style={{ height: "18rem", marginLeft: "15px" }}>
                <table className="tableModbusView">
                  <tbody>
                    <tr>
                      <td style={{ width: "1%" }}></td>
                      <td style={{ width: "26%" }}>
                        <div style={{ height: "500px" }}>
                          <table
                            className={
                              this.props.allModbusDeviceInfo?.is_connected === 1
                                ? `cardInfo bacecard ${"connected-device-info"}`
                                : `cardDisconnectedInfo bacecard ${"connected-device-info"}`
                            }
                          >
                            <tr
                              className={
                                this.props.allModbusDeviceInfo?.is_connected ===
                                1
                                  ? "headRow"
                                  : "headDisconnectedRow"
                              }
                            >
                              <th>
                                <div className="headRowView d-flex align-items-center text-white">
                                  <span
                                    style={{ fontWeight: "500" }}
                                    className="card-key"
                                  >
                                    Status
                                  </span>{" "}
                                  {this.props.allModbusDeviceInfo
                                    ?.is_connected === 1 ? (
                                    <Fragment>
                                      <div
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "500",
                                          marginLeft: "50px ",
                                        }}
                                      >
                                        Online
                                      </div>
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      <div
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "500",
                                          marginLeft: "50px ",
                                        }}
                                      >
                                        Offline
                                      </div>
                                    </Fragment>
                                  )}
                                  <div className="refreshButton">
                                    <FontAwesomeIcon
                                      className="fa-lg"
                                      icon={faRefresh}
                                      onClick={this.handleToRefresh}
                                    />
                                  </div>
                                </div>
                              </th>
                            </tr>
                            <tr style={{ height: "15px" }}></tr>
                            <tr style={{ display: "flex" }}>
                              <td style={{ width: "50%" }}>
                                <p className="card-value rowStart">Label</p>
                              </td>
                              <td style={{ width: "50%" }}>
                                <p className="card-value rowStart">Last seen</p>
                              </td>
                            </tr>
                            <tr>
                              {" "}
                              <td style={{ width: "50%" }}>
                                <p className="card-key  text-capitalize text-wrap rowStart">
                                  {this.props.allModbusDeviceInfo?.label ===
                                    null &&
                                  this.props.allModbusDeviceInfo?.label ===
                                    undefined
                                    ? this.props.allModbusDeviceInfo
                                        ?.id_iot_device === undefined
                                      ? "-"
                                      : this.props.allModbusDeviceInfo
                                          ?.id_iot_device
                                    : this.props.allModbusDeviceInfo?.label}
                                </p>
                              </td>
                              <td style={{ width: "50%" }}>
                                <p className="card-key  text-capitalize text-wrap rowStart">
                                  {this.props.allModbusDeviceInfo
                                    ?.last_data_at !== null ? (
                                    <Moment format="DD MMM YYYY HH:mm">
                                      {this.props.allModbusDeviceInfo
                                        ?.last_data_at * 1000}
                                    </Moment>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </td>
                            </tr>
                            <tr
                              style={{ display: "flex", height: "10px" }}
                            ></tr>
                            <tr style={{ display: "flex" }}>
                              <td style={{ width: "50%" }}>
                                <p className="card-value rowStart">
                                  {this.props.allModbusDeviceInfo?.modbusDevice
                                    ?.server_ip === null
                                    ? "Slave ID"
                                    : "IP Address"}
                                </p>
                              </td>
                              {/* {console.log(
                            this.props.allModbusDevices.items
                            //  .map((y) => y.id_physical_device)
                              .filter(
                                (p) =>
                                  p.id_physical_device ===
                                  this.props.allModbusDeviceInfo.id_parent
                              ),
                            this.props.allModbusDeviceInfo
                          )} */}
                              <td style={{ width: "50%" }}>
                                <p className="card-value rowStart">
                                  Active device alerts
                                </p>
                              </td>
                            </tr>
                            <tr>
                              {" "}
                              <td style={{ width: "50%" }}>
                                <p className="card-key  text-capitalize text-wrap rowStart">
                                  {this.props.allModbusDeviceInfo?.modbusDevice
                                    ?.server_ip === null
                                    ? this.props.allModbusDeviceInfo
                                        ?.modbusDevice?.server_addr
                                    : this.props.allModbusDeviceInfo
                                        ?.modbusDevice?.server_ip}
                                </p>
                              </td>
                              <td style={{ width: "50%" }}>
                                <p className="card-key  text-capitalize text-wrap rowStart"></p>
                              </td>
                            </tr>
                            <tr
                              style={{ display: "flex", height: "10px" }}
                            ></tr>
                            <tr style={{ display: "flex" }}>
                              <td style={{ width: "50%" }}>
                                <p className="card-value rowStart">
                                  Connected gateway
                                </p>
                              </td>
                              <td style={{ width: "50%" }}>
                                <p className="card-value rowStart">
                                  Number of registers
                                </p>
                              </td>
                            </tr>
                            <tr>
                              {" "}
                              <td style={{ width: "50%" }}>
                                <Link
                                  to={`/bace-ep/${this.props.match.params?.projectId}/${this.props.allModbusDeviceInfo?.parentDevice.id_physical_device}/device-info`}
                                  className="col-md-auto"
                                  style={{
                                    textDecoration: "none",
                                    marginTop: "12px",
                                  }}
                                >
                                  {" "}
                                  <p className="card-key  text-capitalize text-wrap rowStart">
                                    {
                                      this.props.allModbusDeviceInfo
                                        ?.parentDevice?.label
                                    }
                                  </p>
                                </Link>
                              </td>
                              <td style={{ width: "50%" }}>
                                <p
                                  className="card-key  text-capitalize text-wrap rowStart"
                                  style={{ textAlign: "center" }}
                                >
                                  {
                                    numberRegister.filter((v) => v === true)
                                      .length
                                  }
                                </p>
                              </td>
                            </tr>
                            <tr
                              style={{ display: "flex", height: "10px" }}
                            ></tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>{" "}
            <div className="col-lg-1"></div>
            <div className="col-lg-4"></div>
            <div style={{ height: "25px" }}></div>
            <div className="divViewModal">
              <div
                onClick={this.cardViewRegister}
                // to=""
                className={` ${
                  formInput.cardRegister
                    ? "bacelink-active"
                    : "bacelink-inactive"
                }`}
              >
                <FontAwesomeIcon className="fa-lg" icon={faBrowser} />
              </div>

              <div
                onClick={this.listViewRegister}
                //  to=""
                className={` ${
                  formInput.listRegister
                    ? "bacelink-active"
                    : "bacelink-inactive"
                }`}
              >
                <FontAwesomeIcon className="fa-lg" icon={faListUl} />
              </div>
            </div>
            {formInput.cardRegister ? (
              <table
                style={{
                  marginLeft: "40px",
                  textAlign: "center",
                }}
              >
                <div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto auto",
                      marginBottom: "30px",
                    }}
                  >
                    {this.props.allModbusDeviceInfo?.latestData.map(
                      (item, i) =>
                        templateRegisterDataList?.find(
                          (elem) => elem.value_id === item.datatype
                        ) !== undefined && (
                          <div
                            key={i}
                            style={{
                              margin: "0px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              alignItems: "center",
                              border: "groove",
                              height: "70px",
                              border: "#000066",
                              borderStyle: "solid",
                            }}
                          >
                            <table>
                              <tr>
                                <th style={{ width: "35%" }}>
                                  {" "}
                                  <Moment format="DD MMM YYYY HH:mm">
                                    {item.timestamp}
                                  </Moment>
                                </th>
                                <th>
                                  <div
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "20px",
                                    }}
                                  >
                                    <div style={{ display: "inline-flex" }}>
                                      {" "}
                                      <p className="unitValue">
                                        {" "}
                                        {(Math.floor(item.value * 100) / 100)
                                          .toFixed(2)
                                          .includes(0.0)
                                          ? Math.round(item.value)
                                          : (
                                              Math.floor(item.value * 100) / 100
                                            ).toFixed(2)}
                                      </p>
                                      <p className="unitValueData">
                                        {" "}
                                        {item.unit}
                                      </p>
                                    </div>

                                    <div
                                      style={{
                                        fontWeight: 500,
                                        wordBreak: "break-word",
                                        fontSize: "16px",
                                      }}
                                      title={item.label}
                                    >
                                      {" "}
                                      {item.label}
                                    </div>
                                  </div>
                                </th>
                                <th>
                                  <FontAwesomeIcon
                                    className="fa-lg"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Chart"
                                    icon={faChartLine}
                                  />
                                </th>
                              </tr>
                            </table>
                          </div>
                          // );
                        )
                    )}
                    {/* })} */}
                  </div>
                </div>
              </table>
            ) : (
              ""
            )}
            {formInput.listRegister ? (
              <table
                style={{
                  marginLeft: "40px",
                  border: "#000066",
                  borderStyle: "solid",
                  textAlign: "center",
                }}
              >
                <tr style={{ border: "#000066", borderStyle: "solid" }}>
                  <th>Register name</th>
                  <th>Value</th>
                  <th>Time</th>
                  <th>Graph</th>
                </tr>

                {this.props.allModbusDeviceInfo?.latestData.map(
                  (k, i) =>
                    templateRegisterDataList?.find(
                      (elem) => elem.value_id === k.datatype
                    ) !== undefined && (
                      <tr key={i}>
                        <td>{k.label}</td>
                        <td>
                          <div style={{ display: "inline-flex" }}>
                            {" "}
                            <p className="unitValue">
                              {" "}
                              {(Math.floor(k.value * 100) / 100)
                                .toFixed(2)
                                .includes(0.0)
                                ? Math.round(k.value)
                                : (Math.floor(k.value * 100) / 100).toFixed(2)}
                            </p>
                            <p className="unitValueData"> {k.unit}</p>
                          </div>
                        </td>
                        <td>
                          {/* <Moment format="DD MMM YYYY hh:mm">{k.timestamp}</Moment> */}
                          <Moment format="DD MMM YYYY HH:mm">
                            {k.timestamp}
                          </Moment>
                        </td>
                        <td>
                          {" "}
                          <FontAwesomeIcon
                            className="fa-md"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Chart"
                            icon={faChartLine}
                          />
                        </td>
                      </tr>
                    )
                )}
              </table>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allModbusDeviceInfoData: state.modbusViewReducer.allModbusDeviceInfo,
    // allModbusDeviceInfo: state.modbusViewReducer.allModbusDeviceInfo,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getModbusDeviceInfo,
    clearModbusDeviceData,
  })(ModbusDeviceCardInfo)
);
