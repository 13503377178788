import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import OneBaceEpSubmenu from "../../components/menus/sub-menu/subtypes/OneBaceEpSubmenu";
import MeasurementDataChart from "../../components/device-cards/MeasurementDataChart";
import ExportComponent from "../../components/global/ExportComponent";

import {
  getGroupData,
  clearGroupData,
  getGroupDatatypes,
} from "../../actions/chart-view";
import { getDeviceData, clearDeviceData } from "../../actions/list-view";

import LoaderRoller from "../../components/loader/LoaderRoller";
import NoDataCard from "../../components/device-cards/NoDataCard";

import CONFIG from "../../config";
class DeviceChartView extends Component {
  componentDidMount = () => {
    const { id } = this.props.match.params;
    if (!id) {
      return this.props.history.push("/");
    }
    // this.props.getGroupDatatypes(id);
    // this.props.getGroupData(id);
    // this.props.getDeviceData(id);
  };

  componentWillUnmount = () => {
    this.props.clearGroupData();
    this.props.clearDeviceData();
  };

  render() {
    const {
      SIGNAL_STRENGTH,
      TEMPERATURE,
      HUMIDITY,
      CONDUCTIVITY_IN_VOLTS,
      PRESSURE_IN_VOLTS,
      ENERGY_STORAGE_IN_VOLTS,
    } = CONFIG.datatypesGuardian;
    // console.log(this.props.datatypes);
    if (this.props.datatypes && this.props.datatypes.length === 0) {
      return (
        <Fragment>
          <OneBaceEpSubmenu />
          <NoDataCard />
        </Fragment>
      );
    } else if (this.props.datatypes && this.props.datatypes.length > 0) {
      return (
        <Fragment>
          <OneBaceEpSubmenu />
          <div className="bacecontainer">
            <div className="d-flex justify-content-end mt-3">
              {this.props.datatypes && this.props.datatypes.length ? (
                <ExportComponent />
              ) : (
                <div></div>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-lg-auto">
                <Fragment key={1}>
                  <MeasurementDataChart datatype={TEMPERATURE} />
                  <br />

                  <MeasurementDataChart datatype={HUMIDITY} />
                  <br />

                  <MeasurementDataChart datatype={SIGNAL_STRENGTH} />
                  <br />

                  <MeasurementDataChart datatype={CONDUCTIVITY_IN_VOLTS} />
                  <br />

                  <MeasurementDataChart datatype={PRESSURE_IN_VOLTS} />
                  <br />

                  <MeasurementDataChart datatype={ENERGY_STORAGE_IN_VOLTS} />
                  <br />
                </Fragment>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <OneBaceEpSubmenu />
          <div className="bacecontainer">
            <div className="row mt-3">
              <LoaderRoller />
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    datatypes: state.chartViewReducer?.groupDatatypes,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getGroupData,
    clearGroupData,
    getGroupDatatypes,
    getDeviceData,
    clearDeviceData,
  })(DeviceChartView)
);
