import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PaginationNew from "../../components/global/paginate/PaginationNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoaderRoller from "../loader/LoaderRoller";
import ModbusIconBlue from "./../../assets/Icons/modbussetting.png";

import { Fragment } from "react";
import {
  getSettingsConnections,
  getWifiSettings,
  getTemplateList,
  getSettingsPerformSelfTest,
  getSettingsReboot,
  confirmCellularValue,
  clearDeviceData,
  clearSettingData,
} from "../../actions/list-view";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {
  faCloud,
  faHardDrive,
  faFolderGear,
  faServer,
  faListUl,
  faCircleCheck,
  faEdit,
  faCircleQuestion,
  faTriangleExclamation,
  faQuestionCircle,
  faCheck,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import {} from "@fortawesome/pro-regular-svg-icons";
import globalFunctions from "../../helpers/functions";
import SetWifiPopup from "../popups/SetWifiPopup";
import ApplyNewTemplatePopup from "../popups/ApplyNewTemplatePopup";
import "./sensor.css";
import CreateTemplatePopup from "../../components/popups/CreateTemplatePopup";

const { uniqueKey, Td } = globalFunctions;

class BaceEpSettingsCard extends Component {
  state = {
    formInput: {
      connectionCheck: true,
      connectionstatus: false,
      selftestConnect: false,
      setReboot: false,
      applyNewTemplate: false,
      applyNewTemplateNew: false,
      setWifi: false,
      setApplyWifi: false,
      allpyNewErrorLog: false,
      setCellularValue: false,
      setWifiValue: false,
      setHeartRate: false,
      setCellulardata: "",
      setWifiData: "",
      setSampleData: "",
      setDeviceConfigId: "",
      setConfigKey: "",
      setPhysicalId: "",
      setSettingValue: false,
      connectionDeviceCheck: "",
      changeLocation: false,
      NotValidCellularValue: false,
      NotValidWifiValue: false,
      NotValidSampleValue: false,
      createTemplateOpen: false,
      popupScreen: 0
    },
  };
  popupScreen = () => {
    const formInput = { ...this.state.formInput };
    formInput.popupScreen = 1;
    this.setState({ formInput });
  };

  handleConnectionChange = () => {
    const formInput = { ...this.state.formInput };
    formInput.connectionstatus = true;
    this.setState({ formInput });
    this.handleConnectionstatus();
  };
  handleEditCellular = () => {
    const formInput = { ...this.state.formInput };
    formInput.setCellularValue = true;
    this.setState({ formInput });
  };

  handleCancelCellaurValue = () => {
    const formInput = { ...this.state.formInput };
    formInput.setCellularValue = false;
    formInput.setCellulardata = "";
    this.setState({ formInput });
  };

  handleEditWifi = () => {
    const formInput = { ...this.state.formInput };
    formInput.setWifiValue = true;
    this.setState({ formInput });
  };

  handleCancelWifiValue = () => {
    const formInput = { ...this.state.formInput };
    formInput.setWifiValue = false;
    formInput.setWifiData = "";
    this.setState({ formInput });
  };

  handleEditHeartRate = () => {
    const formInput = { ...this.state.formInput };
    formInput.setHeartRate = true;
    this.setState({ formInput });
  };

  handleCancelHeartRateValue = () => {
    const formInput = { ...this.state.formInput };
    formInput.setHeartRate = false;
    formInput.setSampleData = "";
    this.setState({ formInput });
  };

  handleCellularValue = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput.setCellulardata = input.value;
    this.setState({ formInput });
  };
  handleWifiValue = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput.setWifiData = input.value;
    this.setState({ formInput });
  };

  handleSampleValue = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput.setSampleData = input.value;
    this.setState({ formInput });
  };

  handleConfirmCellaurValue = () => {
    const configDeviceId =
      this.props.deviceListInfo.currentConfig[
        "properties.reported.settings.pushToCloudWithCellularEveryXms"
      ]?.id_device_config;
    const physicalId = this.props.deviceListInfo.id_physical_device;
    const configKey =
      "properties.reported.settings.pushToCloudWithCellularEveryXms";
    const formInput = { ...this.state.formInput };
    if (formInput.setCellulardata < 86400 && formInput.setCellulardata > 1) {
      formInput.setDeviceConfigId = configDeviceId;
      formInput.setPhysicalId = physicalId;
      formInput.setConfigKey = configKey;
      formInput.setSettingValue = true;
      this.setState({ formInput });
      this.props.confirmCellularValue(formInput, "Cellular");
    } else {
      formInput.NotValidCellularValue = true;
      this.setState({ formInput });
    }
    console.log(formInput);
  };
  handleConfirmWifiValue = () => {
    const configDeviceId =
      this.props.deviceListInfo.currentConfig[
        "properties.reported.settings.pushToCloudWithWiFiEveryXms"
      ]?.id_device_config;
    const physicalId = this.props.deviceListInfo.id_physical_device;
    const configKey =
      "properties.reported.settings.pushToCloudWithWiFiEveryXms";
    const formInput = { ...this.state.formInput };

    if (formInput.setWifiData < 86400 && formInput.setWifiData > 1) {
      formInput.setDeviceConfigId = configDeviceId;
      formInput.setPhysicalId = physicalId;
      formInput.setConfigKey = configKey;
      formInput.setSettingValue = true;
      this.setState({ formInput });
      this.props.confirmCellularValue(formInput, "Wifi");
    } else {
      formInput.NotValidWifiValue = true;
      this.setState({ formInput });
    }
  };

  handleConfirmHeartRateValue = () => {
    const configDeviceId =
      this.props.deviceListInfo.currentConfig[
        "properties.reported.settings.sampleEveryXms"
      ]?.id_device_config;
    const physicalId = this.props.deviceListInfo.id_physical_device;
    const configKey = "properties.reported.settings.sampleEveryXms";
    const formInput = { ...this.state.formInput };
    if (formInput.setSampleData < 86400 && formInput.setSampleData > 1) {
      formInput.setDeviceConfigId = configDeviceId;
      formInput.setPhysicalId = physicalId;
      formInput.setConfigKey = configKey;
      formInput.setSettingValue = true;
      this.setState({ formInput });
      this.props.confirmCellularValue(formInput, "Sample");
    } else {
      formInput.NotValidSampleValue = true;
      this.setState({ formInput });
    }
  };
  handleToChangeLocation = () => {
    const configDeviceId =
      this.props.deviceListInfo.currentConfig[
        "properties.reported.settings.locationConsentGiven"
      ]?.id_device_config;
    const physicalId = this.props.deviceListInfo.id_physical_device;
    const configKey = "properties.reported.settings.locationConsentGiven";
    const formInput = { ...this.state.formInput };
    formInput.setDeviceConfigId = configDeviceId;
    formInput.setPhysicalId = physicalId;
    formInput.setConfigKey = configKey;
    formInput.setSettingValue = true;
    this.setState({ formInput });
    this.props.confirmCellularValue(formInput, "location");
  };
  handleChangeLocation = () => {
    const formInput = { ...this.state.formInput };
    formInput.changeLocation = true;
    this.setState({ formInput });
  };

  handleSetWifi = () => {
    const physicalId = this.props.deviceListInfo.id_physical_device;

    const formInput = { ...this.state.formInput };
    formInput.setApplyWifi = true;
    this.setState({ formInput });
    this.props.getWifiSettings(physicalId);
    // if (this.props.WifiListSetting?.status === 200) {
    //   formInput.setWifi = true;
    //   this.setState({ formInput });
    //   //  this.props.getTemplateList();
    // } else {
    //   formInput.allpyNewErrorLog = true;
    //   this.setState({ formInput });
    //   // this.props.getTemplateList();
    // }
  };
  handleTemplateChange = () => {
    const formInput = { ...this.state.formInput };
    formInput.applyNewTemplate = true;
    this.setState({ formInput });
    this.props.getTemplateList("", this.props.projectId);
  };
  handleConnectionstatus = () => {
    const idGroup = this.props.deviceListInfo.id_physical_device;
    this.props.clearSettingData();
    this.props.getSettingsConnections(idGroup, this.props.projectId);
  };
  handleSelfTestChange = () => {
    const formInput = { ...this.state.formInput };
    formInput.selftestConnect = true;
    this.setState({ formInput });
    const idGroup = this.props.deviceListInfo.id_physical_device;
    this.props.getSettingsPerformSelfTest(idGroup, this.props.projectId);
  };

  handleRebootChange = () => {
    const formInput = { ...this.state.formInput };
    formInput.setReboot = true;
    this.setState({ formInput });
    const idGroup = this.props.deviceListInfo.id_physical_device;
    this.props.getSettingsReboot(idGroup, this.props.projectId);
  };

  handleToCancel = () => {
    const formInput = { ...this.state.formInput };
    formInput.applyNewTemplate = false;
    formInput.setWifi = false;
    this.setState({ formInput });
  };
  createPopUpApply = () => {
    const formInput = { ...this.state.formInput };
    formInput.applyNewTemplateNew = false;
    this.setState({ formInput });
  };

  handleToOpenCreateTemplate = () => {
    const formInput = { ...this.state.formInput };
    formInput.createTemplateOpen = true;
    formInput.applyNewTemplate = false;
    this.setState({ formInput });
  };

  handleToCloseCreateTemplate = () => {
    const formInput = { ...this.state.formInput };
    formInput.createTemplateOpen = false;
    formInput.applyNewTemplate = true;
    this.setState({ formInput });
  };
  handleToCancelTemplate = () => {
    this.props.clearSettingData();
    const formInput = { ...this.state.formInput };
    formInput.applyNewTemplate = false;
    this.setState({ formInput });
  };
  handleToConnectionClose = () => {
    this.props.clearSettingData();
    const formInput = { ...this.state.formInput };
    formInput.setCellulardata = "";
    formInput.setWifiData = "";
    formInput.setSampleData = "";
    formInput.connectionstatus = false;
    formInput.setApplyWifi = false;
    formInput.selftestConnect = false;
    formInput.setCellularValue = false;
    formInput.setWifiValue = false;
    formInput.setSettingValue = false;
    formInput.setHeartRate = false;
    formInput.setReboot = false;
    formInput.allpyNewErrorLog = false;
    formInput.changeLocation = false;
    formInput.NotValidCellularValue = false;
    formInput.NotValidWifiValue = false;
    formInput.NotValidSampleValue = false;
    // formInput.connectionCheck = false;
    formInput.connectionDeviceCheck = "";
    this.setState({ formInput });
  };

  render() {
    const { formInput } = this.state;
    formInput.connectionDeviceCheck = this.props.settingsConnectionGateway;
    const billyEntries = this.props.settingsSelfTestGateway?.data?.payload
      ? Object.entries(this.props.settingsSelfTestGateway?.data?.payload)
      : "";
    return (
      <div className="p-4">
        {formInput.changeLocation && (
          <Dialog
            open={formInput.connectionCheck === true ? true : false}
            onClose={this.handleToConnectionClose}
          >
            <div onClick={this.handleToConnectionClose}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleToConnectionClose}
              />
            </div>
            <DialogTitle>
              <p className="wififailedMessage">
                Do you really want to change the location?
              </p>
              <div className="col pb-3">
                <div className="d-flex justify-content-end">
                  <button
                    className={
                      formInput.setApplyConfirm
                        ? "btn-cancel mx-1 cancelTemplate"
                        : "btn-cancel mx-1"
                    }
                    onClick={this.handleToConnectionClose}
                    disabled={formInput.setApplyConfirm ? true : false}
                  >
                    Cancel
                  </button>{" "}
                  <button
                    className={
                      formInput.setApplyConfirm
                        ? "btn-cancel submitDisableButton"
                        : "btn-cancel submitButton"
                    }
                    onClick={() => this.handleToChangeLocation()}
                    disabled={formInput.setApplyConfirm ? true : false}
                  >
                    <Fragment>Confirm</Fragment>
                    {/* )} */}
                  </button>
                </div>
              </div>
            </DialogTitle>
          </Dialog>
        )}
        {this.props.WifiListSetting === undefined &&
          this.props.failWifiOption === undefined &&
          formInput.setApplyWifi && (
            <div className="template-popup">
              <div className="template-popup-wifi-container">
                <div className="loaderView">
                  <LoaderRoller />
                </div>
              </div>
            </div>
          )}
        {this.props.WifiListSetting === null &&
          this.props.failWifiOption === null &&
          formInput.setApplyWifi && (
            <div className="template-popup">
              <div className="template-popup-wifi-container">
                <div className="loaderView">
                  <LoaderRoller />
                </div>
              </div>
            </div>
          )}
        {this.props.WifiListSetting?.status === 200 &&
          formInput.setApplyWifi && (
            <SetWifiPopup
              templateListOptions={
                this.props.WifiListSetting.payload.wifiAccessPoints
              }
              failedWifi={this.props.failWifiOption}
              physicalDeviceId={this.props.deviceListInfo.id_physical_device}
              //  handleToCancel={this.handleToCancel}
              // templateId={this.state.formInput.templateId}
              closePopup={this.handleToConnectionClose}
              // popupScreen={this.popupScreen}
            />
          )}
        {this.props.failWifiOption === "failed" && (
          <Dialog
            open={formInput.connectionCheck === true ? true : false}
            onClose={this.handleToConnectionClose}
          >
            <div onClick={this.handleToConnectionClose}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleToConnectionClose}
              />
            </div>
            <DialogTitle>
              <div>
                <FontAwesomeIcon
                  className="mb-4 fa-3x errorWifi"
                  icon={faTriangleExclamation}
                />
              </div>
              <p className="wififailedMessage">Wifi Scan failed!</p>
              <p className="wifiMessage">Please try it again later.</p>
            </DialogTitle>
          </Dialog>
        )}
        {formInput.applyNewTemplate && (
          <ApplyNewTemplatePopup
            templateListOptions={this.props.settingslistTemplateOption}
            physicalDeviceId={this.props.deviceListInfo.id_physical_device}
            handleToClose={this.handleToCancel}
            handleToCancelTemplate={this.handleToCancelTemplate}
            projectId={this.props.projectId}
            handleToOpenCreateTemplate={this.handleToOpenCreateTemplate}
            // handleToApplyTemplate={this.handleApplyTemplate}
            // templateId={this.state.formInput.templateId}
            //   createPopUpApply={this.createPopUpApply}
            // popupScreen={this.popupScreen}
          />
        )}

        {formInput.createTemplateOpen && (
          <div>
            <CreateTemplatePopup
              popupScreen={this.popupScreen}
              handleToClose={this.handleToCloseCreateTemplate}
              handleToSubmitCreateTemplate={this.handleToCloseCreateTemplate}
              
            />
          </div>
        )}

        {(formInput.NotValidCellularValue ||
          formInput.NotValidWifiValue ||
          formInput.NotValidSampleValue) && (
          <Dialog
            open={
              formInput.NotValidCellularValue === true ||
              formInput.NotValidWifiValue === true ||
              formInput.NotValidSampleValue === true
                ? true
                : false
            }
            onClose={this.handleToConnectionClose}
          >
            <div onClick={this.handleToConnectionClose}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleToConnectionClose}
              />
            </div>

            <DialogTitle>
              <div>
                {" "}
                <h4
                  style={{ textAlign: "center" }}
                  className="settingsHeaderTextIcon"
                >
                  {" "}
                  value range 1 - 86400 (seconds)
                </h4>
              </div>
            </DialogTitle>
          </Dialog>
        )}

        {formInput.connectionstatus && (
          <Dialog
            open={formInput.connectionCheck === true ? true : false}
            onClose={this.handleToConnectionClose}
          >
            {(formInput.connectionDeviceCheck === "success" ||
              this.props.failConnectionOption === "failed") && (
              <div onClick={this.handleToConnectionClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToConnectionClose}
                />
              </div>
            )}

            <DialogTitle>
              {formInput.connectionDeviceCheck === "success" ? (
                <div>
                  {" "}
                  <FontAwesomeIcon
                    className="settingsHeaderIcon"
                    icon={faCircleCheck}
                  />
                  <h4
                    style={{ textAlign: "center" }}
                    className="settingsHeaderTextIcon"
                  >
                    {" "}
                    Connection Successful
                  </h4>
                </div>
              ) : this.props.failConnectionOption === "failed" ? (
                <div>
                  {" "}
                  <FontAwesomeIcon
                    className="mb-4 fa-3x errorWifi"
                    icon={faTriangleExclamation}
                  />
                  <p style={{ textAlign: "center" }}>
                    Sorry, we couldn't access to your gateway.Please check the
                    connection and try again.
                  </p>
                </div>
              ) : (
                <div>
                  <LoaderRoller />
                  <p style={{ textAlign: "center" }}>Checking Connection</p>
                </div>
              )}
            </DialogTitle>
          </Dialog>
        )}
        {formInput.selftestConnect && (
          <Dialog
            open={formInput.connectionCheck === true ? true : false}
            onClose={this.handleToConnectionClose}
          >
            {(this.props.settingsSelfTestGateway?.status === 200 ||
              this.props.failSelfTestOption === "failed") && (
              <div onClick={this.handleToConnectionClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToConnectionClose}
                />
              </div>
            )}
            <DialogTitle>
              {this.props.settingsSelfTestGateway?.status === 200 ? (
                <div>
                  {" "}
                  <FontAwesomeIcon
                    className="settingsHeaderIcon"
                    icon={faCircleCheck}
                  />
                  <h3 className="settingsHeaderTextIcon">
                    {" "}
                    Success! This is the result of self test.
                  </h3>
                  <p>
                    {billyEntries.map(([event, time]) => {
                      return (
                        <div
                          key={event}
                          style={{ display: "flex", fontSize: "12px" }}
                        >
                          <div>{event} </div>
                          <div>: {time === true ? "true" : time}</div>
                        </div>
                      );
                    })}
                  </p>
                </div>
              ) : this.props.failSelfTestOption === "failed" ? (
                <div>
                  {" "}
                  <FontAwesomeIcon
                    className="mb-4 fa-3x errorWifi"
                    icon={faTriangleExclamation}
                  />
                  <p style={{ textAlign: "center" }}>
                    Sorry,Self test failed.Please check the connection and try
                    again.
                  </p>
                </div>
              ) : (
                <div>
                  <LoaderRoller />
                  <p style={{ textAlign: "center" }}>Performing Self Test</p>
                </div>
              )}
            </DialogTitle>

            <DialogActions>
              {this.props.settingsSelfTestGateway === "success" && (
                <button onClick={this.handleToClose} color="primary" autoFocus>
                  Close
                </button>
              )}
            </DialogActions>
          </Dialog>
        )}
        {formInput.setSettingValue && (
          <Dialog
            open={formInput.setSettingValue === true ? true : false}
            onClose={this.handleToConnectionClose}
          >
            <div onClick={this.handleToConnectionClose}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleToConnectionClose}
              />
            </div>
            <DialogTitle>
              {this.props.confirmCellularData?.status === 200 ? (
                <div>
                  {" "}
                  <FontAwesomeIcon
                    className="settingsHeaderIcon"
                    icon={faCircleCheck}
                  />
                  <h3
                    style={{ textAlign: "center" }}
                    className="settingsHeaderTextIcon"
                  >
                    {" "}
                    Update Successful.It takes few seconds to update in
                    Settings.
                  </h3>
                </div>
              ) : this.props.failRebootOption === "failed" ? (
                <div>
                  {" "}
                  <FontAwesomeIcon
                    className="mb-4 fa-3x errorWifi"
                    icon={faTriangleExclamation}
                  />
                  <p style={{ textAlign: "center" }}>
                    Sorry,Please check the connection and try again.
                  </p>
                </div>
              ) : (
                <div>
                  <LoaderRoller />
                  <p style={{ textAlign: "center" }}>Updating Value</p>
                </div>
              )}
            </DialogTitle>

            <DialogActions>
              {this.props.confirmCellularData === "success" && (
                <button onClick={this.handleToClose} color="primary" autoFocus>
                  Close
                </button>
              )}
            </DialogActions>
          </Dialog>
        )}

        {formInput.setReboot && (
          <Dialog
            open={formInput.connectionCheck === true ? true : false}
            onClose={this.handleToConnectionClose}
          >
            {(this.props.settingsRebootGateway?.status === 200 ||
              this.props.failRebootOption === "failed") && (
              <div onClick={this.handleToConnectionClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToConnectionClose}
                />
              </div>
            )}
            <DialogTitle>
              {this.props.settingsRebootGateway?.status === 200 ? (
                <div>
                  {" "}
                  <FontAwesomeIcon
                    className="settingsHeaderIcon"
                    icon={faCircleCheck}
                  />
                  <h4
                    style={{ textAlign: "center" }}
                    className="settingsHeaderTextIcon"
                  >
                    {" "}
                    Sent the reboot command to the gateway successfully.It takes
                    around 2 min to reboot.
                  </h4>
                </div>
              ) : this.props.failRebootOption === "failed" ? (
                <div>
                  {" "}
                  <FontAwesomeIcon
                    className="mb-4 fa-3x errorWifi"
                    icon={faTriangleExclamation}
                  />
                  <p style={{ textAlign: "center" }}>
                    Sorry,Rebooting failed.Please check the connection and try
                    again.
                  </p>
                </div>
              ) : (
                <div>
                  <LoaderRoller />
                  <p style={{ textAlign: "center" }}>Rebooting</p>
                </div>
              )}
            </DialogTitle>

            <DialogActions>
              {this.props.settingsSelfTestGateway === "success" && (
                <button onClick={this.handleToClose} color="primary" autoFocus>
                  Close
                </button>
              )}
            </DialogActions>
          </Dialog>
        )}

        <div className="settingModal">
          <div className="topFrame">
            <div className="settingTemplate">Configuration Template</div>
            <div className="" style={{ marginLeft: "9px" }}>
              {" "}
              {this.props.deviceListInfo.config_template_label}
            </div>
            <button
              onClick={this.handleTemplateChange}
              // to="/"
              className="applyTemplate"
            >
              <div style={{ display: "flex" }} class="applyTemplateBorder">
                {" "}
                {/* <FontAwesomeIcon className="fa-sm applyIcon" icon={faListUl} /> */}
                Apply Another Template
              </div>
            </button>
          </div>
          <div class="waveSecondFrame">
            <table className="settingConnection ">
              <tbody>
                <tr>
                  <th>
                    <FontAwesomeIcon className="settingsIcon" icon={faCloud} />
                  </th>
                  <th>
                    <table>
                      <tr>
                        <td>{"      "}</td>
                        <td className="routeWay">
                          {" "}
                          {this.props.deviceListInfo.connection}
                        </td>
                        <td>{"     "}</td>
                      </tr>
                      <tr>
                        <td>{"     "}</td>
                        <td>---------------------------------------------</td>
                        <td>{"     "}</td>
                      </tr>
                      <tr>
                        <td>{"     "}</td>
                        <td>
                          <div
                            className={
                              this.props.deviceListInfo.is_connected === 1
                                ? "activeSetting"
                                : "inactiveSetting"
                            }
                          >
                            {this.props.deviceListInfo.is_connected === 1
                              ? "Online"
                              : "Offline"}
                          </div>
                        </td>
                        <td>{"     "}</td>
                      </tr>
                    </table>
                  </th>
                  <th>
                    <FontAwesomeIcon
                      className="settingsIcon"
                      icon={faHardDrive}
                    />
                  </th>
                  <th></th>
                  <th>---------------------------------------------</th>
                  <th> </th>

                  <th>
                    {" "}
                    <img
                      className="settingsIcon"
                      src={ModbusIconBlue}
                      title="modBus device"
                    />
                  </th>
                </tr>
                <tr>
                  <td>
                    <span> Cloud</span>
                  </td>
                  <td></td>

                  <td>
                    <span> Gateway</span>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <span> Devices</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="settingViewupdated connectingSettingFrame">
            <div className="connectionSetting">
              <div className="card-key text-capitalize text-wrap settingsFirstConnectionInfo">
                Connection Settings{" "}
                <div className="tooltipSetting">
                  <FontAwesomeIcon
                    className="fa-1x questionIcon"
                    icon={faCircleQuestion}
                  />
                  <span className="tooltipsSettingText tooltipsTemplate">
                    <div>
                      WiFi connection is only possible when Bluetooth is not
                      being used. Intervals determine the transmission frequency
                      of data from gateway to cloud.
                    </div>
                  </span>
                </div>
              </div>

              <div>
                <table className="settingsGrid">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="card-key text-capitalize text-wrap">
                        Wifi
                      </th>
                      <th className="card-key text-capitalize text-wrap">
                        <button
                          className="setValue"
                          onClick={this.handleSetWifi}
                        >
                          Set
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {" "}
                      <td>&nbsp;&nbsp;</td>
                      <td>
                        <hr
                          style={{
                            color: "black",
                            backgroundColor: "black",
                            width: "100%",
                            opacity: "0.10",
                          }}
                        />
                      </td>{" "}
                      <td>
                        <hr
                          style={{
                            color: "black",
                            backgroundColor: "black",
                            width: "80%",
                            marginLeft: "-2px",
                            opacity: "0.10",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>Interval Cellular</td>
                      <td className="settingsView">
                        {formInput.setCellularValue ? (
                          <div className="settingsView">
                            <input
                              className="inputCellularValue"
                              value={formInput.setCellulardata}
                              name="label"
                              type="number"
                              onChange={this.handleCellularValue}
                            />
                            <FontAwesomeIcon
                              className="svg-icons-sm wifiView confirmCellular"
                              data-toggle="tooltip"
                              data-placement="top"
                              icon={faCheck}
                              onClick={this.handleConfirmCellaurValue}
                            />{" "}
                            <FontAwesomeIcon
                              className="svg-icons-sm wifiView cancelCellular"
                              data-toggle="tooltip"
                              data-placement="top"
                              icon={faTimes}
                              onClick={this.handleCancelCellaurValue}
                            />
                          </div>
                        ) : (
                          <div className="settingsView">
                            {console.log(
                              this.props.deviceListInfo,
                              this.props.deviceListInfo.currentConfig[
                                "properties.reported.settings.pushToCloudWithCellularEveryXms"
                              ]?.current_value,
                              "IV"
                            )}
                            <p className="configViewSet">
                              {this.props.deviceListInfo.currentConfig[
                                "properties.reported.settings.pushToCloudWithCellularEveryXms"
                              ]?.current_value / 1000}{" "}
                            </p>
                            <p className="setSecond">Sec</p>
                            <FontAwesomeIcon
                              className="svg-icons-sm"
                              icon={faEdit}
                              onClick={this.handleEditCellular}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>Interval Wifi/Ethernet</td>
                      <td className="settingsView">
                        {formInput.setWifiValue ? (
                          <div className="settingsView">
                            <input
                              className="inputCellularValue"
                              value={formInput.setWifiData}
                              name="label"
                              type="number"
                              onChange={this.handleWifiValue}
                            />
                            <FontAwesomeIcon
                              className="svg-icons-sm wifiView confirmCellular"
                              data-toggle="tooltip"
                              data-placement="top"
                              icon={faCheck}
                              onClick={this.handleConfirmWifiValue}
                            />{" "}
                            <FontAwesomeIcon
                              className="svg-icons-sm wifiView cancelCellular"
                              data-toggle="tooltip"
                              data-placement="top"
                              icon={faTimes}
                              onClick={this.handleCancelWifiValue}
                            />
                          </div>
                        ) : (
                          <div className="settingsView">
                            <p className="configViewSet">
                              {" "}
                              {this.props.deviceListInfo.currentConfig[
                                "properties.reported.settings.pushToCloudWithWiFiEveryXms"
                              ]?.current_value / 1000}{" "}
                            </p>{" "}
                            <p className="setSecond">Sec</p>
                            <FontAwesomeIcon
                              className="svg-icons-sm"
                              icon={faEdit}
                              onClick={this.handleEditWifi}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="consent">
              <div className="card-key text-capitalize text-wrap settingsSecondInfo">
                Consent
                <div className="tooltipSetting">
                  <FontAwesomeIcon
                    className="fa-1x questionIcon"
                    icon={faCircleQuestion}
                  />
                  <span className="tooltipsSettingText tooltipsTemplate">
                    <div>
                      Enabling location will allow device to sends GPS
                      coordinates to you as a measurement.
                    </div>
                  </span>
                </div>
              </div>

              <div className="settingSampleGrid">
                <div className="settingsLocationGrid">Location</div>
                <div className="settingsLocationGrid">: &nbsp;&nbsp;</div>
                <div className="form-check form-switch">
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckChecked"
                  >
                    {this.props.deviceListInfo.currentConfig[
                      "properties.reported.settings.locationConsentGiven"
                    ]?.current_value === "1"
                      ? "On"
                      : "Off"}
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      this.props.deviceListInfo.currentConfig[
                        "properties.reported.settings.locationConsentGiven"
                      ]?.current_value === "1"
                        ? true
                        : false
                    }
                    onChange={this.handleChangeLocation}
                  />
                </div>
              </div>
            </div>
            <div className="sampleRateSetting">
              <div className="card-key text-capitalize text-wrap settingsThirdInfo">
                Sample Settings
                <div className="tooltipSetting">
                  <FontAwesomeIcon
                    className="fa-1x questionIcon"
                    icon={faCircleQuestion}
                  />
                  <span className="tooltipsSettingText tooltipsTemplate">
                    <div>
                      Sample rate determines how often Gateway will poll
                      information your assets.
                    </div>
                  </span>
                </div>
              </div>
              <div className="settingSampleGrid">
                <div className="settingsMapView">Sample Rate &nbsp;</div>
                <div>
                  <div
                    className="settingsViewRate"
                    htmlFor="flexSwitchCheckChecked"
                  >
                    {formInput.setHeartRate ? (
                      <div className="settingsView">
                        <input
                          className="inputCellularValue"
                          value={formInput.setSampleData}
                          name="label"
                          type="number"
                          onChange={this.handleSampleValue}
                        />
                        <FontAwesomeIcon
                          className="svg-icons-sm wifiView confirmCellular"
                          data-toggle="tooltip"
                          data-placement="top"
                          icon={faCheck}
                          onClick={this.handleConfirmHeartRateValue}
                        />
                        <FontAwesomeIcon
                          className="svg-icons-sm wifiView cancelCellular"
                          data-toggle="tooltip"
                          data-placement="top"
                          icon={faTimes}
                          onClick={this.handleCancelHeartRateValue}
                        />
                      </div>
                    ) : (
                      <div className="settingsView">
                        <p className="settingsMapViewData">
                          {" "}
                          {this.props.deviceListInfo.currentConfig[
                            "properties.reported.settings.sampleEveryXms"
                          ]?.current_value / 1000}{" "}
                        </p>
                        <p className="setSecond">Sec</p>

                        <FontAwesomeIcon
                          className="svg-icons-sm settingsMapView"
                          icon={faEdit}
                          onClick={this.handleEditHeartRate}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="card-key text-capitalize text-wrap settingsFirstInfonew gatewayCommand"
            style={{ marginLeft: "57px" }}
          >
            <p className="gatewayeCommand"> Gateway Commands</p>
            <div className="settingsView">
              <div className="settingsViewArea">
                <button
                  className="settingsViewAreaCheck1 buttonSettingsCommand btncheckconnection"
                  onClick={this.handleConnectionChange}
                >
                  <span className="btninsidetextcheckconnection">
                    Check Connection
                  </span>
                </button>
              </div>
              <div className="tooltipSetting" style={{ top: "8px" }}>
                <FontAwesomeIcon
                  className="fa-1x questionIcon buttonPerform"
                  style={{ fontSize: "20px" }}
                  icon={faCircleQuestion}
                />
                <span className="tooltipsSettingText tooltipsTemplate">
                  <div>
                    Pings your gateway to check if it is connected to cloud.
                  </div>
                </span>
              </div>
              <div className="settingsViewArea" style={{ marginLeft: "85px" }}>
                <button
                  className="buttonSettingsCommand btncheckconnection"
                  onClick={this.handleSelfTestChange}
                >
                  <span class="btninsidetext">Perform Self Test</span>
                </button>
              </div>
              <div className="tooltipSetting" style={{ top: "8px" }}>
                <FontAwesomeIcon
                  className="fa-1x questionIcon buttonPerform"
                  style={{ fontSize: "20px" }}
                  icon={faCircleQuestion}
                />
                <span className="tooltipsSettingText tooltipsTemplate">
                  <div>
                    Check if certain functionalities are working as expected.
                  </div>
                </span>
              </div>
              <div className="settingsViewArea">
                <button
                  className="settingsViewRebootnew buttonSettingsCommand btncheckconnection "
                  onClick={this.handleRebootChange}
                >
                  <span className="btninsidetextReboot"> Reboot</span>
                </button>
              </div>
              <div className="tooltipSetting" style={{ top: "8px" }}>
                <FontAwesomeIcon
                  className="fa-1x questionIcon buttonPerform"
                  style={{ fontSize: "20px" }}
                  icon={faCircleQuestion}
                />
                <span className="tooltipsSettingText tooltipsTemplate">
                  <div>
                    Restarts the BACE gateway, it might take up to 2 minutes to
                    finish reboot.
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <PaginationNew />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listViewData: state.listViewReducer.listViewData,
    settingsConnectionGateway: state.listViewReducer.settingsConnectionGateway,
    settingsSelfTestGateway: state.listViewReducer.settingsSelfTestGateway,
    settingsRebootGateway: state.listViewReducer.settingsRebootGateway,
    settingslistTemplateOption:
      state.listViewReducer.settingslistTemplateOption,
    WifiListSetting: state.listViewReducer.WifiListSetting,
    failWifiOption: state.listViewReducer.failWifiOption,
    confirmCellularData: state.listViewReducer.confirmCellularData,
    failConnectionOption: state.listViewReducer.failConnectionOption,
    failSelfTestOption: state.listViewReducer.failSelfTestOption,
    failRebootOption: state.listViewReducer.failRebootOption,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getSettingsConnections,
    getWifiSettings,
    getTemplateList,
    getSettingsPerformSelfTest,
    getSettingsReboot,
    confirmCellularValue,
    clearDeviceData,
    clearSettingData,
  })(BaceEpSettingsCard)
);
