/* eslint-disable no-unused-expressions */
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import moment from "moment";
import OneModbusGatewaySubmenu from "../../components/menus/sub-menu/subtypes/OneModbusGatewaySubmenu";
import MeasurementDataChart from "../../components/device-cards/MeasurementDataChart";
import Chart from "react-apexcharts";
import {
  getGroupData,
  clearGroupData,
  getGroupDatatypes,
  getChartDataSampled,
  getMultipleChartDataSampled,
  clearChartData,
} from "../../actions/chart-view";
import { getDeviceData, clearDeviceData } from "../../actions/list-view";
import NoDataCard from "../../components/device-cards/NoDataCard";
import LoaderRoller from "../../components/loader/LoaderRoller";
import {
  getModbusDeviceInfo,
  getAllModbusDevices,
} from "../../actions/modbus-view";
import "./style.css";
import ExportComponent from "../../components/global/ExportComponent";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faFilter } from "@fortawesome/free-solid-svg-icons";
class DeviceChartView extends Component {
  state = {
    responseData: null,
    datatypeList: [],
    projectId: "",
    optionSelected: null,
    selectedOption: [{ datatype: "", label: "" }],
    devices: [],
    menuItem: false,
    zoomout: false,
    reset: false,
    zoomIn: false,
    zoomChart: false,
    formInput: {
      startDate: "",
      pushChart: false,
      endDate: "",
      datatypeListName: [],
      uniqueDatatypeList: [],
      chartName: "",
      dataTypeName: "",
      dataTypeValue: "",
      multipleChart: [],
      options: [],
      series: [],
      customValue: false,
      customValueData: "",
      resoltionValueData: "",
      loaderOn: false,
    },
  };

  handleChange = (devices) => {
    this.setState({ devices: devices });
  };

  componentDidMount = () => {
    const formInput = { ...this.state.formInput };
    const { id } = this.props.match.params;
    if (!id) {
      return this.props.history.push("/");
    }
    const physicalDeviceId = this.props.match.params;
    const templateRegisterDataList = this.props.allModbusDevices?.items.filter(
      (i) => i.id_physical_device === this.props.allModbusDeviceInfo?.id_parent
    )[0]?.currentModbusTemplate?.registers;
    if (formInput.datatypeListName.length === 0) {
      this.props.allModbusDeviceInfo?.latestData.filter((item, i) =>
        templateRegisterDataList?.find((elem) => {
          if (elem.value_id === item.datatype) {
            formInput.datatypeListName.push({
              datatype: elem.value_id,
              label: elem.label,
            });
          }
        })
      );
    }
    formInput.startDate = formInput.startDate
      ? new Date(formInput.startDate).getTime()
      : new Date(new Date().getTime() - 86400 * 1000).getTime();

    formInput.endDate = formInput.endDate
      ? new Date(formInput.endDate).getTime()
      : new Date().getTime();
    formInput.chartName = formInput.datatypeListName[0]?.label;
    this.setState({ formInput });
    let fl = {
      "filter[timestamp_seconds][gte]": formInput.startDate / 1000,
      "filter[timestamp_seconds][lte]": formInput.endDate / 1000,
      "filter[datatype]": formInput.datatypeListName[0]?.datatype,
    };

    let params = Object.entries(fl)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");
    this.props.clearChartData();
    this.props.getAllModbusDevices(this.props.match.params.projectId);
    this.props.getModbusDeviceInfo(physicalDeviceId);
    setTimeout(() => {
      this.props.getChartDataSampled(this.props.match.params.id, 1, params);
    }, 2000);
  };

  componentWillUnmount = () => {
    if (!window.location.pathname.startsWith("/modbus-gateways")) {
      this.props.clearGroupData();
      this.props.clearDeviceData();
    }
  };
  handleChangeDate = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput.customValue = false;
    formInput[input.name] = new Date(input.value).getTime();
    this.setState({ formInput });
  };

  handleToSearch = () => {
    this.handleFilterChange();
  };

  handleToSearchOne = () => {
    this.handleFilterOneChange();
  };

  handleDatatypeChange = (e) => {
    const formInput = { ...this.state.formInput };
    formInput.datatypeListName.map((m) => {
      if (m.datatype.toString() === e.target.value) {
        formInput.dataTypeName = m.label;
        formInput.dataTypeValue = m.datatype;
      }
    });
    this.setState({ formInput });
    setTimeout(() => {}, 2000);
  };

  handleFilterChange = () => {
    const formInput = { ...this.state.formInput };
    const dataTypeData = this.state.devices;
    dataTypeData.map((m, index) => {
      setTimeout(() => {
        this.props.getMultipleChartDataSampled(
          this.props.match.params.id,
          1,
          this.getDataFilters(m.datatype)
        );
      }, 8000);
      formInput.multipleChart.push(this.props.modbuschartListData);
      this.setState({ formInput });
    });
  };

  handleFilterOneChange = () => {
    const formInput = { ...this.state.formInput };
    formInput.customValue = true;
    formInput.loaderOn = true;
    this.setState({ formInput });
    setTimeout(() => {
      this.props.getChartDataSampled(
        this.props.match.params.id,
        1,
        this.getFilters()
      );
    }, 2000);

    setTimeout(() => {
      this.handleLoaderCall();
    }, 2500);
  };

  handleLoaderCall = () => {
    const formInput = { ...this.state.formInput };
    formInput.loaderOn = false;
    this.setState({ formInput });
  };
  getSelectedData = (xaxis) => {
    const formInput = { ...this.state.formInput };
    formInput.customValue = true;
    formInput.loaderOn = true;
    formInput.customValueData = "";
    this.setState({ formInput });
    this.props.getChartDataSampled(
      this.props.match.params.id,
      1,
      this.getZoomFilters(xaxis)
    );
    setTimeout(() => {
      this.handleLoaderCall();
    }, 2500);
  };

  getDataFilters = (datatype) => {
    const { formInput } = this.state;
    let fl = {
      "filter[timestamp_seconds][gte]": formInput.startDate / 1000,
      "filter[timestamp_seconds][lte]": formInput.endDate / 1000,
      "filter[datatype]": datatype,
    };
    let params = Object.entries(fl)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");
    this.setState({ formInput });
    return params;
  };

  getFilters = () => {
    const { formInput } = this.state;
    formInput.datatypeListName.map((m) => {
      if (m.label.toString() === formInput.dataTypeName) {
        formInput.dataTypeValue = m.datatype;
        formInput.chartName = m.label;
      }
    });
    this.setState({ formInput });
    let fl = {
      "filter[timestamp_seconds][gte]": formInput.startDate / 1000,
      "filter[timestamp_seconds][lte]": formInput.endDate / 1000,
      "filter[datatype]":
        formInput.dataTypeValue === ""
          ? formInput.datatypeListName[0]?.datatype
          : formInput.dataTypeValue,
    };

    let params = Object.entries(fl)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");

    return params;
  };

  getZoomFilters = (xaxis) => {
    const formInput = { ...this.state.formInput };
    formInput.startDate = Math.trunc(xaxis.min);
    formInput.endDate = Math.trunc(xaxis.max);
    formInput.datatypeListName.map((m) => {
      if (m.label.toString() === formInput.dataTypeName) {
        formInput.dataTypeValue = m.datatype;
        formInput.chartName = m.label;
      }
    });

    let fl = {
      "filter[timestamp_seconds][gte]": xaxis.min / 1000,
      "filter[timestamp_seconds][lte]": xaxis.max / 1000,
      "filter[datatype]":
        formInput.dataTypeValue === ""
          ? formInput.datatypeListName[0]?.datatype
          : formInput.dataTypeValue,
    };

    this.setState({ formInput });

    let params = Object.entries(fl)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");
    return params;
  };

  disableZoomIn = () => {
    this.setState({
      zoomIn: true,
      zoomout: true,
    });
  };

  enableZoomIn = () => {
    this.setState({
      zoomIn: false,
      zoomChart: true,
    });
  };

  handleTimeChange = (e) => {
    const formInput = { ...this.state.formInput };
    formInput.customValue = false;
    formInput.customValueData = e.target.value;
    if (e.target.value === "1hour") {
      formInput.startDate = new Date().getTime() - 3600000;
      formInput.endDate = new Date().getTime();
    } else if (e.target.value === "24hour") {
      formInput.startDate = new Date().getTime() - 86400000;
      formInput.endDate = new Date().getTime();
    } else if (e.target.value === "1week") {
      formInput.startDate = new Date().getTime() - 604800000;
      formInput.endDate = new Date().getTime();
    } else if (e.target.value === "1month") {
      formInput.startDate = new Date().getTime() - 2629746000;
      formInput.endDate = new Date().getTime();
    } else if (e.target.value === "3month") {
      formInput.startDate = new Date().getTime() - 7889238000;
      formInput.endDate = new Date().getTime();
    } else if (e.target.value === "1Year") {
      formInput.startDate = new Date().getTime() - 31556952000;
      formInput.endDate = new Date().getTime();
    }
    formInput.loaderOn = true;
    this.setState({ formInput });
    setTimeout(() => {
      this.props.getChartDataSampled(
        this.props.match.params.id,
        1,
        this.getFilters()
      );
    }, 2000);
    setTimeout(() => {
      this.handleLoaderCall();
    }, 2500);
  };

  render() {
    const formInput = { ...this.state.formInput };
    const today = new Date().toISOString().replace("Z", "");
    const { zoomout, zoomIn, zoomChart } = this.state;
    var timeRange = formInput.endDate - formInput.startDate;
    var resoltionValue = timeRange / (60 * 60 * 24 * 1000);
    const resol = resoltionValue.toString().includes("0.0");
    console.log(this.props.modbuschartData, "rtt");
    formInput.resoltionValueData =
      this.props.modbuschartData?.items[0].resolution;
    const templateRegisterDataList = this.props.allModbusDevices?.items.filter(
      (i) => i.id_physical_device === this.props.allModbusDeviceInfo?.id_parent
    )[0]?.currentModbusTemplate?.registers;

    if (formInput.datatypeListName.length === 0) {
      this.props.allModbusDeviceInfo?.latestData.filter((item, i) =>
        templateRegisterDataList?.find((elem) => {
          if (elem.value_id === item.datatype) {
            formInput.datatypeListName.push({
              datatype: elem.value_id,
              label: elem.label,
              value: elem.value_id,
            });

            // }
          }
        })
      );
    }
    const uniqueTags = [];

    formInput.datatypeListName.map((item) => {
      var findItem = formInput.uniqueDatatypeList.find(
        (x) => x.tag === item.datatype
      );
      if (!findItem) formInput.uniqueDatatypeList.push(item);
    });

    if (this.props.modbuschartData) {
      const dataReceived = this.props.modbuschartData;
      const dataToRender = [
        ...dataReceived?.items?.map((dataPoint) => dataPoint.avg_val),
      ];
      const dataToRenderMinValue = [
        ...dataReceived?.items?.map((dataPoint) => dataPoint.min_val),
      ];
      const dataToRenderMaxValue = [
        ...dataReceived?.items?.map((dataPoint) => dataPoint.max_val),
      ];
      const labels = [
        ...dataReceived?.items?.map(
          (dataPoint) => dataPoint.timestamp_seconds * 1000
        ),
      ];
      const getTimestamp = this.props.events?.data?.items.map(
        (dataTimestamp) => dataTimestamp?.timestamp_seconds * 1000
      );

      const annotation = Array.from(
        { length: getTimestamp?.length },
        (_, index) => ({
          x: getTimestamp[index],
          borderColor: "gray",
          strokeDashArray: 0,
          width: "100%",
          label: {
            borderColor: "transparent",
            position: "bootom",
            style: {
              color: "red",
              fontSize: 20,
              background: "transparent",
            },
            orientation: "horizontal",
            text: "\u25B2",
          },
        })
      );
      // Convert timestamps to date objects
      formInput.series =
        formInput.customValueData === "1month" ||
        formInput.customValueData === "3month" ||
        formInput.customValueData === "1Year"
          ? [
              {
                name: "avg val",
                data: dataToRender,
                type: "line",
              },
              {
                name: "min val",
                data: dataToRenderMinValue,
                type: "line",
              },
              {
                name: "max val",
                data: dataToRenderMaxValue,
                type: "line",
              },
            ]
          : [
              {
                name: "avg val",
                data: dataToRender,
                type: "line",
              },
            ];
      formInput.options = {
        annotations: {
          xaxis: dataToRender?.length > 0 ? annotation : [],
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy HH:mm",
          },
        },
        chart: {
          height: 750,
          type: "line",
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },

          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              zoom: true,
              zoomin: !zoomIn ? true : false,
              zoomout: zoomout ? true : false,
              pan: false,
            },
          },
          events: {
            beforeZoom: (chartContext, { xaxis }) => {
              const startDate = moment(xaxis.max);
              const timeEnd = moment(xaxis.min);
              const diff = startDate.diff(timeEnd);
              const diffDuration = moment.duration(diff);
              if (diffDuration?._data.minutes > 5) {
                this.getSelectedData(xaxis);
                this.setState({
                  zoomChart: true,
                  //   zoomout: true,
                });
                this.enableZoomIn();
              } else {
                this.disableZoomIn();
              }
            },
            beforeResetZoom: (chartContext, opts) => {
              this.refresh();
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 3,
        },
        fill: {
          type: "solid",
          opacity: 0.6,
        },
        legend: {
          show: true,
          position: "bottom",
          layout: "vertical",
          align: "right",
          verticalAlign: "right",
          showForSecondSeries: false,
          labels: {
            //   colors: "gray",
          },
        },
        //    colors: ["#003dff", "red"],
        colors: ["#d4526e", "#33b2df", "#2c42b8"],
        title: {
          text: formInput.chartName,
          align: "left",
          style: {
            fontSize: "32px",
            fontWeight: "500",
            lineHeight: 1.2,
            fontFamily: "Montserrat",
            color: "black",
          },
        },
        xaxis: {
          tooltip: {
            enabled: false,
          },
          categories: labels,
          type: "datetime",
          tickPlacement: "on",
          labels: {
            format: "dd MMM yyyy HH:mm",
            datetimeUTC: false,
            showDuplicates: false,
            // datetimeFormatter: {
            //   year: "yyyy",
            //   month: "MMM 'yy",
            //   day: "dd MMM",
            //   hour: "HH:mm",
            // },
          },
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return Number.isInteger(value)
                ? value?.toFixed(0)
                : value?.toFixed(2);
            },
          },
        },
        noData: {
          text: "No data to display",
          align: "center",
          verticalAlign: "middle",
          style: {
            color: "black",
            fontSize: "36px",
          },
        },
      };
    }
    const Option = (props) => {
      return (
        <div>
          <components.Option {...props}>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />

            <label style={{ marginLeft: "10px" }}>{props.label}</label>
          </components.Option>
        </div>
      );
    };
    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon
            className="icon-default-color"
            icon={faFilter}
          ></FontAwesomeIcon>
        </components.DropdownIndicator>
      );
    };
    const startdateView = new Date(formInput.startDate * 1000);
    return (
      <div>
        <OneModbusGatewaySubmenu
          allModbusDeviceInfo={this.props.allModbusDeviceInfo}
          y
        />
        <table className="tableView">
          <tbody>
            {this.props.modbuschartData ? (
              <div>
                {" "}
                <tr>
                  <td style={{ display: "flex" }}>
                    <p className="toView">From</p>
                    <input
                      className="form-control-start formInput chartfilter"
                      // value={formInput.startDate}
                      value={new Date(
                        !!formInput.startDate
                          ? zoomChart
                            ? formInput.startDate + 7200000
                            : formInput.startDate + 7200000
                          : Date.now() + 7200000
                      )
                        .toISOString()
                        .replace("Z", "")
                        .slice(0, 16)}
                      name="startDate"
                      type="datetime-local"
                      onChange={this.handleChangeDate}
                      placeholder="starting time"
                      max={today}
                    />
                    <p className="toView">To</p>{" "}
                    <input
                      className="form-control-start formInput chartfilter"
                      // value={formInput.endDate}
                      value={new Date(
                        !!formInput.endDate
                          ? zoomChart
                            ? formInput.endDate + 7200000
                            : formInput.endDate + 7200000
                          : Date.now() + 7200000
                      )
                        .toISOString()
                        .replace("Z", "")
                        .slice(0, 16)}
                      name="endDate"
                      type="datetime-local"
                      onChange={this.handleChangeDate}
                      placeholder="Ending time"
                      max={today}
                    />
                    <select
                      className="form-control-start formInput inputBox"
                      aria-label="Default select example"
                      label="status"
                      onChange={(e) => this.handleTimeChange(e)}
                      style={{ marginLeft: "15px" }}
                    >
                      <option
                        key="custom"
                        value="custom"
                        selected={formInput.customValue === true ? true : false}
                      >
                        Custom
                      </option>
                      <option key="1hour" value="1hour">
                        Last 1 hour
                      </option>
                      <option key="24hour" value="24hour" selected>
                        Last 24 hour
                      </option>
                      <option key="1week" value="1week">
                        Last 1 week
                      </option>
                      <option key="1month" value="1month">
                        Last 1 month
                      </option>
                      <option key="3month" value="3month">
                        Last 3 month
                      </option>
                      <option key="1Year" value="1Year">
                        Last 1 Year
                      </option>
                    </select>
                    <select
                      className="form-control-start formInput inputBox"
                      aria-label="Default select example"
                      //    value={p.reg_type}
                      label="status"
                      onChange={(e) => this.handleDatatypeChange(e)}
                      style={{ marginLeft: "15px" }}
                    >
                      {formInput.datatypeListName.map((currentOption) => (
                        <option
                          key={currentOption.datatype}
                          value={currentOption.datatype}
                        >
                          {currentOption.label}
                        </option>
                      ))}
                    </select>
                    {/* <span
                    className="form-control-start formInput inputBox"
                    data-toggle="popover"
                    data-trigger="focus"
                    data-content="Please selecet account(s)"
                  >
                    <Select
                      defaultValue={this.state.devices}
                      onChange={this.handleChange.bind(this)}
                      options={formInput.datatypeListName}
                      name="devices"
                      style={{ width: "200px" }}
                      placeholder="Select"
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      isSearchable={true}
                      isClearable={true}
                      isMulti
                      //     menuIsOpen={this.state.menuItem}
                    />
                  </span> */}
                    <button
                      className="btn btn-primary shadow-none resetButton"
                      onClick={this.handleToSearchOne}
                    >
                      Search
                    </button>
                    {/* <button
                    className="btn btn-primary shadow-none resetButton"
                    onClick={this.handleToSearch}
                  >
                    Search all
                  </button>{" "} */}
                    {/* <FontAwesomeIcon icon={faSearch} /> */}
                    {/* <button
                    className="btn-secondary btn shadow-none resetButton"
                    onClick={this.handleToReset}
                  >
                    Clear
                  </button>{" "} */}
                  </td>
                </tr>
                <tr>
                  <div style={{ fontWeight: "600", marginLeft: "8px" }}>
                    Resolution :{" "}
                    {formInput.resoltionValueData === "day"
                      ? "Day"
                      : formInput.resoltionValueData === "hour"
                      ? "Hour"
                      : formInput.resoltionValueData === "raw"
                      ? "Raw"
                      : "Minute"}
                  </div>
                </tr>
              </div>
            ) : (
              <div
                className="bacecard p-4"
                style={{ minHeight: "70vh", minWidth: "50vw" }}
              >
                <LoaderRoller />
              </div>
            )}
          </tbody>
        </table>

        {formInput.loaderOn && (
          <div class="loaderChartView">
            <div class="loader"></div>
          </div>
        )}
        {this.props.modbuschartData ? (
          <div className="bacecard-graph-chart p-4">
            <Chart
              options={formInput.options}
              series={formInput.series}
              //  type="line"
              height={400}
              marginLeft={12}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    //  datatypes: state.chartViewReducer?.groupDatatypes,
    modbuschartData: state.chartViewReducer?.modbuschartData,
    modbuschartListData: state.chartViewReducer?.modbuschartListData,
    allModbusDeviceInfo: state.modbusViewReducer.allModbusDeviceInfo,
    allModbusDevices: state.modbusViewReducer.allModbusDevices,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getGroupData,
    clearGroupData,
    getGroupDatatypes,
    getDeviceData,
    clearDeviceData,
    getChartDataSampled,
    getModbusDeviceInfo,
    getAllModbusDevices,
    getMultipleChartDataSampled,
    clearChartData,
  })(DeviceChartView)
);
