import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { baceEpViewChange } from "../../../../actions/view-history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListUl,
  faSliders,
  faHardDrive as faHardDriveRegular,
  faBrowser as faBrowserRegular,
} from "@fortawesome/pro-regular-svg-icons";
import TopMenu from "../../../../components/menus/top-menu/TopMenu";
import { faHardDrive, faBrowser } from "@fortawesome/pro-solid-svg-icons";
class BaceEpSubmenu extends Component {
  state = {
    formInput: {
      projectId: "",
    },
  };
  componentDidMount = () => {
    const { currentPageNr, projectId } = this.props.match.params;
  };

  render() {
    const formInput = this.state.formInput;
    console.log(this.props.projectId, this.props.match.params, "88888");
    formInput.projectId = this.props.projectId;
    return (
      <div className="col pl-4">
        <div className="d-flex">
          <h3 className="p-3 color-ocean-blue color-ocean-blue-font">Gateways</h3>
          <TopMenu />
        </div>
        <div className="mb-3 switch-btn-line"></div>
        <div className="divModal">
          <Link
            onClick={() => {
              this.props.baceEpViewChange("CARD_VIEW");
            }}
            style={{ marginRight: "10px", padding: "10px" }}
            to={`/bace-ep/card-view/1/${formInput.projectId}`}
            className={`${
              this.props.location.pathname.includes("card-view") ||
              this.props.location.pathname.includes("list-view")
                ? "bacelink-active"
                : "bacelink-inactive"
            }`}
          >
            <FontAwesomeIcon
              style={{ marginRight: "10px" }}
              className="fa-lg"
              icon={
                this.props.location.pathname.includes("card-view") ||
                this.props.location.pathname.includes("list-view")
                  ? faHardDrive
                  : faHardDriveRegular
              }
            />
            Gateway overview
          </Link>

          <Link
            onClick={() => {
              this.props.baceEpViewChange("CONFIG_VIEW");
            }}
            to={`/bace-ep/config-view/1/${formInput.projectId}`}
            className={` ${
              this.props.location.pathname.includes("config-view")
                ? "bacelink-active"
                : "bacelink-inactive"
            }`}
          >
            {" "}
            <FontAwesomeIcon
              style={{ marginRight: "10px" }}
              className="fa-lg"
              icon={faSliders}
            />
            Configuration template
          </Link>
        </div>
        {!this.props.location.pathname.includes("config-view") && (
          <div
            className="p-2 d-flex justify-content-end"
            style={{ marginRight: "4rem",marginTop: "10px"
          }}
          >
            <div>
              <span
                className="text-alert submenu-text-bold"
                style={{ fontSize: "12px" }}
              >
                {this.props.nrResults}
              </span>
              <span
                className="text-alert submenu-text-bold"
                style={{ fontSize: "12px", marginRight: "10px" }}
              >
                Result{this.props.nrResults === 1 ? "" : "s"}
              </span>
            </div>
            <Link
              onClick={() => {
                this.props.baceEpViewChange("CARD_VIEW");
              }}
              to={`/bace-ep/card-view/1/${formInput.projectId}`}
              className={` ${
                this.props.location.pathname.includes("card-view")
                  ? "bacelink-active"
                  : "bacelink-inactive"
              }`}
              style={{ borderBottom: "none" }}
            >
              <FontAwesomeIcon
                className="fa-lg"
                icon={
                  this.props.location.pathname.includes("card-view")
                    ? faBrowser
                    : faBrowserRegular
                }
              />
            </Link>
            <Link
              onClick={() => {
                this.props.baceEpViewChange("LIST_VIEW");
              }}
              to={`/bace-ep/list-view/1/${formInput.projectId}`}
              style={{ borderBottom: "none" }}
              className={` ${
                this.props.location.pathname.includes("list-view")
                  ? "bacelink-active"
                  : "bacelink-inactive"
              }`}
            >
              <FontAwesomeIcon className="fa-lg" icon={faListUl} />
            </Link>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    //latestData: state.groupReducer?.latestData,
  };
}

export default withRouter(
  connect(mapStateToProps, { baceEpViewChange })(BaceEpSubmenu)
);
