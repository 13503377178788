import React, { Component, createRef, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Moment from "react-moment";
import LoaderRoller from "../loader/LoaderRoller";
import { faEthernet as eRegular } from "@fortawesome/pro-regular-svg-icons";
import { faEthernet as eLight } from "@fortawesome/pro-light-svg-icons";
import { faEthernet as eThin } from "@fortawesome/pro-thin-svg-icons";
import Pagination from "@material-ui/lab/Pagination";
import "./sensor.css";
import TimeConverter from "./TimeConverter"; // adjust the import path accordingly
import ModbusTCP from "../../assets/Icons/Modbus_TCP.png";
import ModbusRTU from "../../assets/Icons/Modbus_RTU.png";
import BluetoothIcon from "../../assets/Icons/Bluetooth.png";
import ZwaveIcon from "../../assets/Icons/zwave.svg";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import {
  getDeviceEventInfoData,
  getEventTypeData,
} from "../../actions/list-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-regular-svg-icons";
import {
  faSearch,
  faFilter,
  faEthernet,
} from "@fortawesome/free-solid-svg-icons";
import {
  faSignalBarsFair,
  faSignalBarsGood,
  faSignalBarsStrong,
  faSignalBarsWeak,
  faWifiWeak,
  faWifiFair,
  faWifiStrong,
  faWifiSlash,
  faSignalBarsSlash,
  faBluetooth,
  faEthernet as faEthernetSlash,
} from "@fortawesome/pro-duotone-svg-icons";
import CONFIG from "../../config";
import {
  createTheme,
  FormControlLabel,
  styled,
  Switch,
} from "@material-ui/core";
const { baseUrl } = CONFIG;
var d = new Date();

const theme = createTheme({
  palette: {
    primary: {
      main: "#11cf19", // Thumb color when the switch is on
    },
    background: {
      default: "#39393D", // Background color when the switch is off
    },
  },
});
class BaceEpInfoCard extends Component {
  state = {
    formInput: {
      session: "",
      sessionId: "",
      startDate: "",
      endDate: "",
      eventLength: "",
      value: "",
      eventValue: "",
      name: "",
      searchName: "",
    },
  };
  componentDidMount = () => {
    const qoo = "";
    this.props.getEventTypeData(qoo);
  };

  handlePageChange = (event, value) => {
    const idGroup = this.props.deviceListInfo.id_physical_device;
    setTimeout(() => {
      this.props.getDeviceEventInfoData(
        this.props.match.params.id,
        value,
        this.getFilters()
      );
    }, 2000);
  };

  filterDataList = (value, label) => {
    const formInput = { ...this.state.formInput };

    formInput.eventValue = value;
    formInput.name = "";
    formInput.searchName = label;
    this.setState({ formInput });
    // setTimeout(() => {
    //   this.setState({ formInput });
    //   this.props.getDeviceEventInfoData(
    //     this.props.match.params.id,
    //     1,
    //     this.getFilters()
    //   );
    // }, 2000);
  };

  handleToSearch = () => {
    this.handleFilterChange();
  };

  handleToReset = () => {
    const { id } = this.props.match.params;
    if (!id) {
      return this.props.history.push("/");
    }

    this.props.getDeviceEventInfoData(id, 1);

    if (this.props.deviceEventData.length !== 0) {
      const formInput = { ...this.state.formInput };
      formInput.startDate = "";
      formInput.endDate = "";
      formInput.searchName = "";
      this.setState({ formInput });
    }
  };
  handleChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput[input.name] = input.value;
    this.setState({ formInput });
    // this.handleFilterChange();
  };
  handleChangeDate = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput[input.name] = input.value;
    this.setState({ formInput });
    // this.handleFilterChange();
  };
  handleFilterChange = () => {
    setTimeout(() => {
      this.props.getDeviceEventInfoData(
        this.props.match.params.id,
        1,
        this.getFilters()
      );
    }, 2000);
  };
  getFilters = () => {
    const { formInput } = this.state;

    const startImagineDate = formInput.endDate.split("T")[0] + "T00:00";

    let fl = {
      "filter[occurred_at][gte]": formInput.startDate
        ? new Date(formInput.startDate).getTime() / 1000
        : // ? new Date(formInput.startDate).getTime() / 1000
        formInput.endDate
        ? new Date(startImagineDate).getTime() / 1000
        : undefined,
      "filter[occurred_at][lte]": formInput.endDate
        ? new Date(formInput.endDate).getTime() / 1000
        : //? new Date(formInput.endDate).getTime() / 1000 + 86399
        formInput.startDate
        ? Date.now()
        : undefined,
      "filter[event_type][like]": formInput.eventValue,
    };

    let params = Object.entries(fl)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");
    return params;
  };
  isConnected = this.props.deviceListInfo?.is_connected === 1;
  IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 32,
    height: 18,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: this.isConnected ? "#33cf4d" : "#ba1313",
        "& + .MuiSwitch-track": {
          backgroundColor: "white",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        border: "6px solid #fff",
        color: this.isConnected ? "#33cf4d" : "#ba1313",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 14,
      height: 14,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  handleListData = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };

    formInput.searchName = input.value;
    formInput.name = input.value;

    if (formInput.searchName.length > 2) {
      this.props.getEventTypeData(formInput.searchName);
    }
    if (formInput.searchName.length === 0) {
      formInput.eventValue = "";
    }
    this.setState({ formInput });
  };
  render() {
    const { formInput } = this.state;
    const today = new Date().toISOString().split("T")[0] + "T00:00";
    const isConnected = this.props.deviceListInfo?.is_connected === 1;
    return (
      <div className="tableView" style={{ height: "18rem" }}>
        <table>
          <tbody>
            <tr>
              {/* <td style={{ width: "1%" }}></td> */}
              <td style={{ width: "30%" }}>
                <div style={{ height: "500px" }}>
                  <table
                    className={
                      this.props.deviceListInfo?.is_connected === 1
                        ? `cardInfo bacecard ${"connected-device-info"}`
                        : `cardDisconnectedInfo bacecard ${"connected-device-info"}`
                    }
                  >
                    <tr
                      className={
                        this.props.deviceListInfo?.is_connected === 1
                          ? "headRow"
                          : "headDisconnectedRow"
                      }
                    >
                      <div className="headRowView d-flex align-items-center text-white justify-content-between">
                        <span
                          style={{ fontWeight: "400", fontSize: "18px" }}
                          className="card-key"
                        >
                          Status
                        </span>

                        <div className="d-flex">
                          <FormControlLabel
                            control={
                              <this.IOSSwitch
                                sx={{ m: 1 }}
                                defaultChecked={isConnected}
                                disabled={true }
                                style={{color: "rgb(51, 207, 77)"}}
                              />
                            }
                          />
                          <div
                            style={{
                              fontSize: "18px",
                              fontWeight: isConnected ? "600" : "500",
                            }}
                          >
                            {isConnected ? "Online" : "Offline"}
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </tr>
                    <tr style={{ height: "15px" }}></tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "50%" }}>
                        <p className="card-value rowStart">Label</p>
                      </td>
                      <td style={{ width: "50%" }}>
                        <p className="card-key  text-capitalize text-wrap rowStart">
                          {/*{console.log(this.props.deviceListInfo, "89989")} */}

                          {this.props.deviceListInfo?.label === null &&
                          this.props.deviceListInfo?.label === undefined
                            ? this.props.deviceListInfo?.id_iot_device ===
                              undefined
                              ? "-"
                              : this.props.deviceListInfo?.id_iot_device
                            : this.props.deviceListInfo?.label}
                        </p>
                      </td>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "50%" }}>
                        <p className="card-value rowStart">Device ID</p>
                      </td>
                      <td style={{ width: "50%" }}>
                        <p className="card-key  text-capitalize text-wrap rowStart">
                          {this.props.deviceListInfo?.iotDevice !== null
                            ? this.props.deviceListInfo?.iotDevice?.iot_id
                            : "d"}
                        </p>
                      </td>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "50%" }}>
                        <p className="card-value rowStart">Connectivity</p>
                      </td>
                      <td style={{ width: "50%" }}>
                        <div className="card-key text-capitalize text-wrap rowStart">
                          <div className="d-flex align-items-center">
                            <span>{this.props.deviceListInfo?.connection}</span>
                            {this.props.deviceListInfo.connection ===
                              "ethernet" &&
                              (this.props.deviceListInfo.signal >= 0 &&
                              this.props.deviceListInfo.signal <= 25 ? (
                                <FontAwesomeIcon
                                  className="svg-icons-sm fa-lg"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Weak"
                                  icon={eThin}
                                />
                              ) : this.props.deviceListInfo.signal >= 26 &&
                                this.props.deviceListInfo.signal <= 50 ? (
                                <FontAwesomeIcon
                                  className="svg-icons-sm fa-lg"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Good"
                                  icon={eLight}
                                />
                              ) : this.props.deviceListInfo.signal >= 51 &&
                                this.props.deviceListInfo.signal <= 75 ? (
                                <FontAwesomeIcon
                                  className="svg-icons-sm fa-lg"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Fair"
                                  icon={eRegular}
                                />
                              ) : this.props.deviceListInfo.signal >= 76 &&
                                this.props.deviceListInfo.signal <= 100 ? (
                                <FontAwesomeIcon
                                  className="svg-icons-sm fa-lg"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Strong"
                                  icon={faEthernet}
                                />
                              ) : this.props.deviceListInfo.signal === 0 ? (
                                <FontAwesomeIcon
                                  className="svg-icons-sm fa-lg"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="No Connection"
                                  icon={faEthernetSlash}
                                />
                              ) : (
                                ""
                              ))}

                            {this.props.deviceListInfo.connection === "wifi" &&
                              (this.props.deviceListInfo.signal >= 0 &&
                              this.props.deviceListInfo.signal <= 33 ? (
                                <FontAwesomeIcon
                                  className="svg-icons-sm"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Weak"
                                  icon={faWifiWeak}
                                />
                              ) : this.props.deviceListInfo.signal >= 34 &&
                                this.props.deviceListInfo.signal <= 68 ? (
                                <FontAwesomeIcon
                                  className="svg-icons-sm"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Fair"
                                  icon={faWifiFair}
                                />
                              ) : this.props.deviceListInfo.signal >= 69 &&
                                this.props.deviceListInfo.signal <= 100 ? (
                                <FontAwesomeIcon
                                  className="svg-icons-sm"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Strong"
                                  icon={faWifiStrong}
                                />
                              ) : this.props.deviceListInfo.signal === 0 ? (
                                <FontAwesomeIcon
                                  className="svg-icons-sm"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="No Connection"
                                  icon={faWifiSlash}
                                />
                              ) : (
                                ""
                              ))}
                            {this.props.deviceListInfo.connection ===
                              "blutooth" && (
                              <FontAwesomeIcon
                                className="svg-icons-sm"
                                icon={faBluetooth}
                              />
                            )}
                            {this.props.deviceListInfo.connection ===
                              "cellular" &&
                              (this.props.deviceListInfo.signal > 0 &&
                              this.props.deviceListInfo.signal <= 25 ? (
                                <FontAwesomeIcon
                                  className="svg-icons-sm"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Weak"
                                  icon={faSignalBarsWeak}
                                />
                              ) : this.props.deviceListInfo.signal >= 26 &&
                                this.props.deviceListInfo.signal <= 50 ? (
                                <FontAwesomeIcon
                                  className="svg-icons-sm"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Good"
                                  icon={faSignalBarsGood}
                                />
                              ) : this.props.deviceListInfo.signal >= 51 &&
                                this.props.deviceListInfo.signal <= 75 ? (
                                <FontAwesomeIcon
                                  className="svg-icons-sm"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Fair"
                                  icon={faSignalBarsFair}
                                />
                              ) : this.props.deviceListInfo.signal >= 76 &&
                                this.props.deviceListInfo.signal <= 100 ? (
                                <FontAwesomeIcon
                                  className="svg-icons-sm"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Strong"
                                  icon={faSignalBarsStrong}
                                />
                              ) : this.props.deviceListInfo.signal === 0 ? (
                                <FontAwesomeIcon
                                  className="svg-icons-sm"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="No Connection"
                                  icon={faSignalBarsSlash}
                                />
                              ) : (
                                <div className="mb-3"></div>
                              ))}
                            {this.props.deviceListInfo.connection ===
                              "LTE-M" && (
                              <FontAwesomeIcon
                                className="svg-icons-sm"
                                icon={faSignalBarsSlash}
                              />
                            )}

                            {this.props.deviceListInfo.connection === null && (
                              <span>-</span>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "50%" }}>
                        <p className="card-value rowStart">Protocol</p>
                      </td>
                      <td style={{ width: "50%" }}>
                        <div style={{ marginLeft: "12px" }}>
                          <img
                            src={
                              this.props.deviceListInfo?.protocol_received !==
                                null &&
                              this.props.deviceListInfo?.protocol_received.includes(
                                "modbus-rtu"
                              ) &&
                              ModbusRTU
                            }
                            className={
                              this.props.deviceListInfo?.protocol_received !==
                                null &&
                              this.props.deviceListInfo?.protocol_received.includes(
                                "modbus-rtu"
                              )
                                ? "imageWithoutValue"
                                : "imageValue"
                            }
                            title={
                              this.props.deviceListInfo?.protocol_received !==
                                null &&
                              this.props.deviceListInfo?.protocol_received.includes(
                                "modbus-rtu"
                              ) &&
                              "Modbus RTU"
                            }
                          />
                          <img
                            src={
                              this.props.deviceListInfo?.protocol_received !==
                                null &&
                              this.props.deviceListInfo?.protocol_received.includes(
                                "modbus-tcp"
                              ) &&
                              ModbusTCP
                            }
                            className={
                              this.props.deviceListInfo?.protocol_received !==
                                null &&
                              this.props.deviceListInfo?.protocol_received.includes(
                                "modbus-tcp"
                              )
                                ? "imageWithoutValue"
                                : "imageValue"
                            }
                            title={
                              this.props.deviceListInfo?.protocol_received !==
                                null &&
                              this.props.deviceListInfo?.protocol_received.includes(
                                "modbus-tcp"
                              ) &&
                              "Modbus TCP"
                            }
                          />
                          <img
                            src={
                              this.props.deviceListInfo?.protocol_received !==
                                null &&
                              this.props.deviceListInfo?.protocol_received.includes(
                                "ble"
                              ) &&
                              BluetoothIcon
                            }
                            className={
                              this.props.deviceListInfo?.protocol_received !==
                                null &&
                              this.props.deviceListInfo?.protocol_received.includes(
                                "ble"
                              )
                                ? "imageBluetoothValue"
                                : "imageValue"
                            }
                            title={
                              this.props.deviceListInfo?.protocol_received !==
                                null &&
                              this.props.deviceListInfo?.protocol_received.includes(
                                "ble"
                              ) &&
                              "Bluetooth"
                            }
                          />
                          {/*<img
                            src={
                              this.props.deviceListInfo?.protocol_received !==
                                null &&
                              this.props.deviceListInfo?.protocol_received.includes(
                                "zwave"
                              ) &&
                              ZwaveIcon
                            }
                            className={
                              this.props.deviceListInfo?.protocol_received !==
                                null &&
                              this.props.deviceListInfo?.protocol_received.includes(
                                "zwave"
                              )
                                ? "imageWithoutValue"
                                : "imageValue"
                            }
                            title={
                              this.props.deviceListInfo?.protocol_received !==
                                null &&
                              this.props.deviceListInfo?.protocol_received.includes(
                                "zwave"
                              ) &&
                              "zwave"
                            }
                          />*/}
                        </div>
                      </td>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "50%" }}>
                        <p className="card-value rowStart">Last Seen</p>
                      </td>
                      <td style={{ width: "50%" }}>
                        <p className="card-key  text-capitalize text-wrap rowStart">
                          {this.props.deviceListInfo?.last_data_at !== null ? (
                            <Moment format="DD MMM YYYY HH:mm">
                              {this.props.deviceListInfo?.last_data_at * 1000}
                            </Moment>
                            
                          ) : (
                            <div></div>
                          )}
                        </p>
                      </td>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "50%" }}>
                        <p className="card-value rowStart">Connected Devices</p>
                      </td>
                      <td style={{ width: "50%" }}>
                        <p className="card-key  text-capitalize text-wrap rowStart">
                          {this.props.deviceListInfo?.subDevices.length}
                        </p>
                      </td>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "50%" }}>
                        <p className="card-value rowStart">Hardware Type:</p>
                      </td>
                      <td style={{ width: "50%" }}>
                        <p className="card-key  text-capitalize text-wrap rowStart">
                          {this.props.deviceListInfo.gateway_type === null ? (
                            <span>{"NA"}</span>
                          ) : (
                            <span>
                              {this.props.deviceListInfo.gateway_type ===
                              "bace-plus-io" ? (
                                "BACE Plus IO"
                              ) : this.props.deviceListInfo.gateway_type ===
                                "bace-plus-zwave-p1" ? (
                                <span>{"BACE Plus Zwave/P1"}</span>
                              ) : this.props.deviceListInfo.gateway_type ===
                                "bace-plus" ? (
                                <span>{"BACE Plus"}</span>
                              ) : this.props.deviceListInfo.gateway_type ===
                                "bace-plus-rs232" ? (
                                <span>{"BACE Plus RS232"}</span>
                              ) : this.props.deviceListInfo.gateway_type ===
                                "bace-go:" ? (
                                <span>{"BACE Go"}</span>
                              ) : this.props.deviceListInfo.gateway_type ===
                                "bace-core" ? (
                                <span>{"BACE Core"}</span>
                              ) : this.props.deviceListInfo.gateway_type ===
                                "bace-plus-pulse" ? (
                                <span>{"BACE Plus Pulse"}</span>
                              ) : (
                                <span>{"NA"}</span>
                              )}
                            </span>
                          )}
                        </p>
                      </td>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "50%" }}>
                        <p className="card-value rowStart">Uptime</p>
                      </td>
                      <td style={{ width: "50%" }}>
                        <p className="card-key  text-capitalize text-wrap rowStart">
                          {this.props.deviceListInfo?.group.latestData.filter(
                            (data) => data.datatype === 5502
                          ).length > 0 ? (
                            this.props.deviceListInfo.group.latestData.map(
                              (m) =>
                                m.datatype === 5502 ? (
                                  <span key={m.datatype}>
                                    <TimeConverter seconds={m.value} />
                                  </span>
                                ) : null
                            )
                          ) : (
                            <div>NA</div>
                          )}
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: "15px" }}></tr>
                    <tr>
                      <td>
                        <p className="card-key  text-capitalize text-wrap rowStart">
                          Device Info
                        </p>
                      </td>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "50%" }}>
                        <p className="card-value rowStart">
                          Connection options:
                        </p>
                      </td>
                      <td style={{ width: "50%" }}>
                        <p className="card-key  text-capitalize text-wrap rowStart">
                          {
                            this.props.deviceListInfo?.deviceInfo
                              ?.connectionOptions
                          }
                        </p>
                      </td>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "50%" }}>
                        <p className="card-value rowStart">MAC Address:</p>
                      </td>
                      <td style={{ width: "50%" }}>
                        <p className="card-key  text-capitalize text-wrap rowStart">
                          {this.props.deviceListInfo?.deviceInfo?.MAC}
                        </p>
                      </td>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "50%" }}>
                        <p className="card-value rowStart">IMEI:</p>
                      </td>
                      <td style={{ width: "50%" }}>
                        <p className="card-key  text-capitalize text-wrap rowStart">
                          {this.props.deviceListInfo?.deviceInfo?.IMEI}
                        </p>
                      </td>
                    </tr>
                    {/*<tr style={{ display: "flex" }}>
                      <td style={{ width: "50%" }}>
                        <p className="card-value rowStart">IMSI:</p>
                      </td>
                      <td style={{ width: "50%" }}>
                        <p className="card-key  text-capitalize text-wrap rowStart">
                          {this.props.deviceListInfo?.deviceInfo?.IMSI}
                        </p>
                      </td>
                    </tr>*/}
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "50%" }}>
                        <p className="card-value rowStart">ICCID:</p>
                      </td>
                      <td style={{ width: "50%" }}>
                        <p className="card-key  text-capitalize text-wrap rowStart">
                          {this.props.deviceListInfo?.deviceInfo?.ICCID}
                        </p>
                      </td>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "50%" }}>
                        <p className="card-value rowStart">IP Address:</p>
                      </td>
                      <td style={{ width: "50%" }}>
                        <p className="card-key  text-capitalize text-wrap rowStart">
                          {!this.props.deviceListInfo.currentConfig[
                            "properties.reported.deviceInfo.IPAddress"
                          ]?.current_value ? (
                            <span style={{ textAlign: "center" }}>NA</span>
                          ) : (
                            <span>
                              {
                                this.props.deviceListInfo.currentConfig[
                                  "properties.reported.deviceInfo.IPAddress"
                                ]?.current_value
                              }
                            </span>
                          )}
                        </p>
                      </td>
                    </tr>
                    <tr style={{ display: "flex" }}>
                      <td style={{ width: "50%", height: "15px" }}>
                        <p className="card-value rowStart">&nbsp;</p>
                      </td>
                      <td style={{ width: "50%", height: "15px" }}>
                        <p className="card-key  text-capitalize text-wrap rowStart">
                          &nbsp;
                        </p>
                      </td>
                    </tr>
                  </table>{" "}
                </div>
              </td>
              <td style={{ width: "2%" }}></td>
              <td className="latestEvent">
                <table>
                  <tbody>
                    <tr className="tableBorder">
                      <td>
                        <p className="latestEventLabel"> Latest Event</p>
                        <div className="divSide">
                          <table>
                            <tbody>
                              <tr style={{ display: "flex" }}>
                                <td style={{ display: "flex" }}>
                                  {/* <p className="toView">From</p>{" "} */}
                                  <input
                                    className="form-control-start formInput inputBox inputPadding"
                                    value={formInput.startDate}
                                    name="startDate"
                                    type="datetime-local"
                                    onChange={this.handleChangeDate}
                                    placeholder="starting time"
                                    max={today}
                                  />{" "}
                                  <p className="toView"> __</p>{" "}
                                  <input
                                    className="form-control-start formInput inputBox inputPadding"
                                    value={formInput.endDate}
                                    name="endDate"
                                    type="datetime-local"
                                    onChange={this.handleChangeDate}
                                    placeholder="Ending time"
                                    max={today}
                                  />
                                  <input
                                    className="form-control-search formInput inputBox searchBar inputPadding"
                                    value={formInput.searchName}
                                    name="name"
                                    type="text"
                                    onChange={this.handleListData}
                                    placeholder={"Search events"}
                                    autocomplete="off"
                                  />
                                  <div className="listViewEvent">
                                    {formInput.name !== "" &&
                                      formInput.name.length > 2 && (
                                        <div>
                                          <div class="container boxList">
                                            <div class="list-group">
                                              {this.props
                                                .deviceInfoEventTypeViewData
                                                .length > 0 ? (
                                                this.props.deviceInfoEventTypeViewData.map(
                                                  (data) => (
                                                    <div
                                                      class="list-group-item"
                                                      onClick={() => {
                                                        this.filterDataList(
                                                          data.event_type,
                                                          data?.default_label
                                                        );
                                                      }}
                                                    >
                                                      {data?.default_label}
                                                    </div>
                                                  )
                                                )
                                              ) : (
                                                <div class="list-group-item">
                                                  No data found
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                  <button
                                    class="btn btn-primary shadow-none resetButton"
                                    onClick={this.handleToSearch}
                                  >
                                    Search
                                  </button>{" "}
                                  {/* <FontAwesomeIcon icon={faSearch} /> */}
                                  <button
                                    style={{ marginRight: "6px" }}
                                    class="btn-secondary btn shadow-none resetButton"
                                    onClick={this.handleToReset}
                                  >
                                    Clear
                                  </button>{" "}
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="dataViewEventScroll">
                            {this.props.deviceEventList?.data?.items.length >
                              0 && formInput.name !== null ? (
                              this.props.deviceEventList?.data?.items.map(
                                (j) => (
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <table>
                                            <tr>
                                              <td style={{ width: "35%" }}>
                                                <Moment format="DD MMM YYYY HH:mm">
                                                  {j?.occurred_at * 1000}
                                                </Moment>
                                              </td>
                                              <td style={{ width: "60%" }}>
                                                <div>
                                                  {j?.event_type_label} -
                                                  {j?.event_value_label}
                                                  <div className="tooltips">
                                                    <FontAwesomeIcon
                                                      style={{
                                                        marginLeft: "7px",
                                                        width: "50%",
                                                      }}
                                                      className="fa-lg tooltips"
                                                      icon={faCircleInfo}
                                                    />
                                                    <span className="tooltipstextInfo tooltipsTemplate">
                                                      <div>
                                                        <table>
                                                          <tr>
                                                            <td>
                                                              {j?.event_value_description !==
                                                              null
                                                                ? j?.event_value_description
                                                                : "No description"}
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </div>
                                                    </span>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          </table>
                                          {/* <div></div>
                                          <div className="eventLabel"></div>
                                          <div className="tooltips"></div> */}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                )
                              )
                            ) : this.props.deviceEventList?.data?.items
                                .length === 0 ? (
                              <div style={{ textAlign: "center" }}>
                                No event found
                              </div>
                            ) : (
                              <LoaderRoller />
                            )}
                          </div>
                          <div className="pageStyle mt-1 mb-1">
                            <Pagination
                              count={
                                this.props.deviceEventList?.data._meta
                                  ?.pageCount
                              }
                              page={
                                this.props.deviceEventList?.data?._meta
                                  ?.currentPage
                              }
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ height: "30px" }}></td>
                    </tr>
                    <tr
                      className="tableBorder"
                      style={{ background: "var(--10-sea, #EAEDF8)" }}
                    >
                      <td>
                        <div className="latestEventHeader">
                          Connected devices
                        </div>
                        <table className="tableViewModbus">
                          <tbody>
                            <tr>
                              <th className="tableViewFirstHeaderColumn">
                                Status
                              </th>
                              <th className="tableViewHeaderColumnLastSeen">
                                Device name
                              </th>
                              <th className="tableViewHeaderColumnLastSeen">
                                Protocol
                              </th>
                              <th className="tableViewHeaderColumnLastSeen">
                                Last seen
                              </th>
                            </tr>
                          </tbody>
                        </table>
                        <div className="dataViewScroll">
                          {this.props.deviceListInfo.subDevices.length > 0 ? (
                            this.props.deviceListInfo.subDevices.map((l) => (
                              <table>
                                <tbody>
                                  <tr>
                                    <td
                                      className={
                                        l.is_connected === 1
                                          ? "connected-Modbus"
                                          : "disconnected-Modbus"
                                      }
                                    >
                                      {l.is_connected === 0
                                        ? "Offline"
                                        : "Online"}
                                    </td>
                                    <td style={{ width: "40%" }}>
                                      <Link
                                        to={`/modbus-gateways/${this.props.match.params.projectId}/${l.id_physical_device}/device-info`}
                                        className="col-md-auto mt-2"
                                        style={{
                                          textDecoration: "none",
                                          marginLeft: "17%",
                                        }}
                                      >
                                        {l.label === "" ? "" : l.label}
                                      </Link>
                                    </td>
                                    <td style={{ width: "30%" }}>
                                      {l?.id_device_type === "modbus-tcp" ? (
                                        <span style={{ marginLeft: "1%" }}>
                                          {"Modbus TCP"}
                                        </span>
                                      ) : l?.id_device_type === "modbus-rtu" ? (
                                        <span style={{ marginLeft: "1%" }}>
                                          {"Modbus RTU"}
                                        </span>
                                      ) : l?.id_device_type === "zwave" ? (
                                        <span style={{ marginLeft: "12%" }}>
                                          {"Zwave"}
                                        </span>
                                      ) : (
                                        <span style={{ marginLeft: "18%" }}>
                                          {"NA"}
                                        </span>
                                      )}
                                    </td>
                                    <td style={{ width: "40%" }}>
                                      <Moment format="DD MMM YYYY HH:mm">
                                        {l.last_data_at * 1000}
                                      </Moment>
                                    </td>
                                  </tr>
                                </tbody>{" "}
                              </table>
                            ))
                          ) : (
                            <p className="noDataFound">No Data found</p>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              {/* <td style={{ width: "1%" }}></td> */}
            </tr>
          </tbody>
        </table>
      </div>
    );
    // } else {
    //   return <LoaderRoller />;
    // }
  }
}

function mapStateToProps(state) {
  return {
    // listViewData: state.listViewReducer.listViewData,
    userToken: state.userReducer?.token?.token,
    deviceViewData: state.listViewReducer.deviceViewData,
    deviceInfoEventTypeViewData:
      state.listViewReducer.deviceInfoEventTypeViewData,
    deviceEventData: state.listViewReducer.deviceEventData,
  };
}

export default withRouter(
  connect(mapStateToProps, { getDeviceEventInfoData, getEventTypeData })(
    BaceEpInfoCard
  )
);
